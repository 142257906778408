import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import AddaStepNode from '../Nodes/AddaStepNode';
import { v4 as uuidv4 } from 'uuid';
import WriteEmailNode from '../Nodes/WriteEmailNode';
import TimeDelayNode from '../Nodes/TimeDelayNode';
import SendEmailNode from '../Nodes/SendEmailNode';
import SequenceSidebar from './SequenceSidebar';
import CheerioButton from '../../../Components/CheerioButton';
import colors from '../../../Utils/colors';
import { toast } from 'react-toastify';
import ConditionNode from '../Nodes/ConditionNode';

const getId = () => uuidv4();

const SequenceFlow = ({
  setActiveStep,
  nodes,
  setNodes,
  onNodesChange,
  edges,
  setEdges,
  onEdgesChange,
  review = false,
  selectedLabel,
  onclickruntestaction,
  onclickstartsequenceaction, 
  onclicksavedraftaction,
  activeStep,
}) => {
  const [editNodeData, setEditNodeData] = useState();
  const [showSidebar, setShowSidebar] = useState(false);
  const [previousNodeData, setPreviousNodeData] = useState();

  const nodeTypes = useMemo(
    () => ({
      addStepNode: AddaStepNode,
      writeEmailNode: WriteEmailNode,
      sendEmailNode: SendEmailNode,
      timeDelayNode: TimeDelayNode,
      conditionNode: ConditionNode,
    }),
    []
  );

  const onloadNodeData = (node) => {
    setEditNodeData(node);
  };

  const onNodeClick = useCallback((event, node) => {
    setEditNodeData(node);
    const incomingEdge = edges.find((edge)=>edge.target === node.id);
    if(incomingEdge){
      const previousNode = nodes.find((node)=>node.id === incomingEdge.source)
      setPreviousNodeData(previousNode);
    }else{
      setPreviousNodeData(null);
    }
    setShowSidebar(true);
  });

  const isRootNode = (nodeId, nodes, edges) => {
    // Check if there are any edges coming into this node
    return !edges.some((edge) => edge.target === nodeId);
  };

  const createInitialNode = () => ({
    id: getId(),
    type: 'addStepNode',
    position: { x: 0, y: 0 },
  });

  const getDescendantNodes = (nodeId, nodes, edges) => {
    const descendants = new Set();
    const queue = [nodeId];

    while (queue.length > 0) {
      const currentId = queue.shift();
      descendants.add(currentId);

      // Find all direct children of the current node
      edges.forEach((edge) => {
        if (edge.source === currentId && !descendants.has(edge.target)) {
          queue.push(edge.target);
        }
      });
    }

    return Array.from(descendants);
  };

  const onNodesDelete = useCallback(
    (deleted) => {
      const hasRootNode = deleted.some((node) => isRootNode(node.id, nodes, edges));

      if (hasRootNode) {
        const initialNode = createInitialNode();
        setNodes([initialNode]);
        setEdges([]);
        setEditNodeData(initialNode);
        return;
      }

      const nodesToDelete = new Set();

      // Collect all descendants of deleted nodes
      deleted.forEach((node) => {
        const descendants = getDescendantNodes(node.id, nodes, edges);
        descendants.forEach((id) => nodesToDelete.add(id));
      });

      // For each deleted node, create a new "Add a Step" node
      const newNodesAndEdges = deleted
        .map((deletedNode) => {
          // Find the parent edge of the deleted node
          const parentEdge = edges.find((edge) => edge.target === deletedNode.id);

          if (!parentEdge) return null;

          const newAddNode = {
            id: getId(),
            type: 'addStepNode',
            position: deletedNode.position,
          };

          const newEdge = {
            id: `${parentEdge.source}->${newAddNode.id}`,
            source: parentEdge.source,
            target: newAddNode.id,
            label: parentEdge?.label,
            conditonCriteriaLabel: parentEdge?.conditonCriteriaLabel,
            deletable: false,
          };

          return { node: newAddNode, edge: newEdge };
        })
        .filter(Boolean);

      // Update nodes and edges
      setNodes((currentNodes) => {
        const remainingNodes = currentNodes.filter((node) => !nodesToDelete.has(node.id));
        return [...remainingNodes, ...newNodesAndEdges.map((item) => item.node)];
      });

      setEdges((currentEdges) => {
        const remainingEdges = currentEdges.filter(
          (edge) => !nodesToDelete.has(edge.source) && !nodesToDelete.has(edge.target)
        );
        return [...remainingEdges, ...newNodesAndEdges.map((item) => item.edge)];
      });

      if (editNodeData && nodesToDelete.has(editNodeData.id)) {
        setEditNodeData(null);
      }
    },
    [nodes, edges, editNodeData]
  );

  const onNodeDeleteClick = useCallback(
    (nodeToDelete) => {
      if (!nodeToDelete) return;

      if (isRootNode(nodeToDelete.id, nodes, edges)) {
        const initialNode = createInitialNode();
        setNodes([initialNode]);
        setEdges([]);
        setEditNodeData(initialNode);
        return;
      }

      const nodesToDelete = getDescendantNodes(nodeToDelete.id, nodes, edges);

      // Find the parent edge of the deleted node
      const parentEdge = edges.find((edge) => edge.target === nodeToDelete.id);

      if (parentEdge) {
        // Create new "Add a Step" node at the position of deleted node
        const newAddNode = {
          id: getId(),
          type: 'addStepNode',
          position: nodeToDelete.position,
        };

        // Update nodes
        setNodes((nds) => {
          const remainingNodes = nds.filter((node) => !nodesToDelete.includes(node.id));
          return [...remainingNodes, newAddNode];
        });

        // Update edges
        setEdges((eds) => {
          const remainingEdges = eds.filter(
            (edge) => !nodesToDelete.includes(edge.source) && !nodesToDelete.includes(edge.target)
          );

          // Add new edge connecting previous node to new "Add a Step" node
          const newEdge = {
            id: `${parentEdge.source}->${newAddNode.id}`,
            source: parentEdge.source,
            target: newAddNode.id,
            label: parentEdge?.label,
            conditonCriteriaLabel: parentEdge?.conditonCriteriaLabel,
            deletable: false,
          };

          return [...remainingEdges, newEdge];
        });
      }

      if (editNodeData && nodesToDelete.includes(editNodeData.id)) {
        setEditNodeData(null);
      }

      setShowSidebar(false);
    },
    [nodes, edges, editNodeData]
  );

  const AddNewNodeAbove = (type) => {
    console.log('1111111111 ', type);
    const currentEdge = edges.find((edge) => edge?.target === editNodeData?.id);
    const previousNode = nodes.find((node) => node?.id === currentEdge?.source);
    const currentAddNode = nodes.find((node) => node?.id === editNodeData?.id);
    // const previousNode = nodes?.length >= 2 ? nodes[nodes?.length - 2] : null;
    if (!currentAddNode) return;
    const offset = 100;
    const nodeWidths = {
      timeDelayNode: 250,
      sendEmailNode: 280,
      writeEmailNode: 250,
      conditionNode: 160,
      // Add more node types as needed
    };

    const newNodeWidth = nodeWidths[type] || 150;

    const newNode = {
      id: getId(),
      type: type,
      position: {
        x: currentAddNode.position.x + currentAddNode.width / 2 - newNodeWidth / 2, // Same x-coordinate
        y: currentAddNode.position.y, // Above the "Add a New Node"
      },
    };

    const updatedAddNode = {
      ...currentAddNode,
      position: {
        x: currentAddNode.position.x,
        y: currentAddNode.position.y + offset,
      },
    };

    const conditionsAddNodeF = {
      ...currentAddNode,
      position: {
        x: currentAddNode.position.x - 200,
        y: currentAddNode.position.y + offset * 1.5,
      },
    };

    const conditionsAddNodeT = {
      id: getId(),
      type: 'addStepNode',
      position: {
        x: currentAddNode.position.x + 200,
        y: currentAddNode.position.y + offset * 1.5,
      },
    };

    if(type === 'conditionNode'){
      setEditNodeData({
        ...newNode,
          data: {
            ...newNode.data,
            condition: {
              criteria: 'Delivered',
            }
          }
      })
    }else{
      setEditNodeData(newNode);
    }

    if (type === 'conditionNode') {
      setNodes((nds) => [
        ...nds.filter((node) => node.id !== currentAddNode.id),
        {
          ...newNode,
          data: {
            ...newNode.data,
            condition: {
              criteria: 'Delivered',
            }
          }
        },
        // newNode,
        conditionsAddNodeF,
        conditionsAddNodeT,
      ]);
      setEdges((eds) => {
        const parentId = currentEdge?.source;
        const newEdges = [
          ...(parentId
            ? [
                {
                  id: `${parentId}->${newNode.id}`,
                  source: parentId,
                  target: newNode.id,
                  label: currentEdge?.label,
                  conditonCriteriaLabel: currentEdge?.conditonCriteriaLabel,
                  deletable: false,
                },
              ]
            : []),
          {
            id: `${newNode.id}->${conditionsAddNodeF.id}`,
            source: newNode.id,
            target: conditionsAddNodeF.id,
            label: 'not_delivered', 
            conditonCriteriaLabel: 'not_delivered',
            deletable: false,
          },
          {
            id: `${newNode.id}->${conditionsAddNodeT.id}`,
            source: newNode.id,
            target: conditionsAddNodeT.id,
            label: 'delivered', 
            conditonCriteriaLabel: 'delivered',
            deletable: false,
          },
        ];
        const existingEdges = eds.filter((edge) => edge.target !== currentAddNode.id);
        return [...existingEdges, ...newEdges];
      });
    } else {
      setNodes((nds) => [
        ...nds.filter((node) => node.id !== currentAddNode.id),
        newNode,
        updatedAddNode,
      ]);

      setEdges((eds) => {
        const parentId = currentEdge?.source;
        const newEdges = [
          ...(parentId
            ? [
                {
                  id: `${parentId}->${newNode.id}`,
                  source: parentId,
                  target: newNode.id,
                  label: currentEdge?.label, 
                  conditonCriteriaLabel: currentEdge?.conditonCriteriaLabel,
                  deletable: false,
                },
              ]
            : []),
          {
            id: `${newNode.id}->${updatedAddNode.id}`,
            source: newNode.id,
            target: updatedAddNode.id,
            deletable: false,
          },
        ];

        const existingEdges = eds.filter((edge) => edge.target !== currentAddNode.id);
        return [...existingEdges, ...newEdges];
      });
    }
  };

  const saveDataAction = () => {
    setNodes((nds) =>
      nds.map((item) =>
        item?.id === editNodeData?.id ? { ...item, data: editNodeData?.data } : item
      )
    );
    if(editNodeData?.type === 'conditionNode'){
      console.log("11111111111111111 ",editNodeData)
      const FalseEdge = edges.find((edg) => edg.source === editNodeData.id && (edg.label === 'not_delivered' || edg.label === 'not_opened' || edg.label === 'not_clicked'))
      const TrueEdge = edges.find((edg) => edg.source === editNodeData.id && (edg.label === 'delivered' || edg.label === 'opened' || edg.label === 'clicked')) 
      console.log("111111111111111 ",FalseEdge)
      console.log("111111111111111 ",TrueEdge)
      setEdges((eds)=>{
        const existingEdges = eds.filter((edge) => edge.source !== editNodeData.id);
        return [
          ...existingEdges,
          {
            ...FalseEdge,
            label: editNodeData?.data?.condition?.criteria === 'Delivered' ? 'not_delivered' : editNodeData?.data?.condition?.criteria === 'Opened' ? 'not_opened' : editNodeData?.data?.condition?.criteria === 'Clicked' ? 'not_clicked' : '',
            conditonCriteriaLabel: editNodeData?.data?.criteria === 'Delivered' ? 'not_delivered' : editNodeData?.data?.criteria === 'Opened' ? 'not_opened' : editNodeData?.data?.criteria === 'Clicked' ? 'not_clicked' : '',
          },
          {
            ...TrueEdge,
            label: editNodeData?.data?.condition?.criteria === 'Delivered' ? 'delivered' : editNodeData?.data?.condition?.criteria === 'Opened' ? 'opened' : editNodeData?.data?.condition?.criteria === 'Clicked' ? 'clicked' : '',
            conditonCriteriaLabel: editNodeData?.data?.criteria === 'Delivered' ? 'delivered' : editNodeData?.data?.criteria === 'Opened' ? 'opened' : editNodeData?.data?.criteria === 'Clicked' ? 'clicked' : '',
          },
        ]
      })
    }
    toast.success('Node data saved successfully', { position: 'top-right', autoClose: 1000 });
    setTimeout(() => {
      setShowSidebar(false);
    }, 1000);
  };

  return (
    <>
      {(showSidebar || review) && (
        <SequenceSidebar
          editNodeData={editNodeData}
          addNewNodeFunction={AddNewNodeAbove}
          setEditNodeData={setEditNodeData}
          deleteNodeAction={onNodeDeleteClick}
          saveDataAction={saveDataAction}
          selectedLabel={selectedLabel}
          review={review}
          nodes={nodes}
          previousNodeData={previousNodeData} 
          activeStep={activeStep}
        />
      )}
      <div
        id="SequenceFlowContainer"
        className="Column justify-content-between"
        style={{
          height: '100%',
          flex: 1,
        }}
      >
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            onNodesChange={onNodesChange}
            nodeTypes={nodeTypes}
            nodesDraggable={true}
            onNodeClick={onNodeClick}
            onNodesDelete={onNodesDelete}
            edges={edges}
            onEdgesChange={onEdgesChange}
            fitView
            maxZoom={1}
            minZoom={1}
            onInit={() => {
              onloadNodeData(nodes[0]);
            }}
            onEdgesDelete={() => {}}
          >
            <Background variant="dots" gap={12} size={1} />
            <Controls />
          </ReactFlow>
        </ReactFlowProvider>

        <div
          className="Row justify-content-end w-100"
          style={{
            paddingBlock: 20,
            paddingInline: 32,
            borderTop: `1px solid ${colors.borderwhite}`,
            backgroundColor: colors.white01,
          }}
        >
          {review ? (
            <>
              <CheerioButton
                btnText={'Save Draft'}
                textStyle={{ color: colors.black }}
                backColor={colors.white01}
                borderStyle={{ borderColor: colors.black}}
                onclick={() => {
                  onclicksavedraftaction();
                }}
              />
              <CheerioButton
                btnText={'Run a test'}
                textStyle={{ color: colors.black }}
                backColor={colors.white01}
                borderStyle={{ borderColor: colors.black, marginInline: 24 }}
                onclick={() => {
                  onclickruntestaction();
                }}
              />
              <CheerioButton
                btnText={'Start sequence'}
                textStyle={{ color: colors.white01 }}
                backColor={colors.primary03}
                borderStyle={{ borderColor: colors.primary03 }}
                onclick={() => {
                  onclickstartsequenceaction();
                }}
              />
            </>
          ) : (
            <>
              <CheerioButton
                btnText={'Save & Next'}
                textStyle={{ color: colors.white01 }}
                backColor={colors.primary03}
                borderStyle={{ borderColor: colors.primary03 }}
                onclick={() => {
                  setActiveStep(2);
                  console.log('nodes --> ', nodes);
                  console.log('edges ---> ', edges);
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SequenceFlow;
