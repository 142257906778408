import React, { useEffect, useRef, useState } from 'react';
import CheerioButton from '../../Components/CheerioButton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { V2EmailBuilderList } from '../../Services';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import SetupShopify from '../ShopifyStore/Components/Setup';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Kylas from './KylasIntegration';
import { SetupKylas } from './KylasIntegration/SetupKylas';
import { SetupOpenAi } from './OpenAiIntegration/SetupOpenAi';
import Setup from '../WooCommerce/Components/Setup';
import { trackPages } from '../../Utils/brevo';
import { eventsend } from '../../Config/analyticsFunctions';
import GPTContactSalesModal from '../../Components/Modal/GPTContactSalesModal';
import { AmazonSES } from './AmazonSESIntegreation/AmazonSES';
import { Pixel } from './PixelIntegration/Pixel';
import { Linkedin } from './LinkedinIntegration/Linkedin';
import PremiumFeatureModal from '../CreatorNew/Components/UpdatePremiumModal';
const servicesData = [
  {
    serviceName: 'Shopify',
    serviceDesc: 'Sync your customers, orders & product data to automate and target customers',
    image: images.ShopifyService,
    industry: 'Ecommerce',
    type: 'MARKETING',
    url: 'setupShopify',
  },
  {
    serviceName: 'WooCommerce',
    serviceDesc: 'Sync your customers, orders & product data to automate and target customers',
    image: images.WooCommService,
    industry: 'Ecommerce',
    type: 'MARKETING',
    url: 'setupWooCommerce',
  },
  {
    serviceName: 'Kylas',
    serviceDesc: 'Streamline your Kylas CRM data for automations & marketing.',
    image: images.KylasService,
    industry: 'Ecommerce',
    type: 'MARKETING',
    url: 'setupKylas',
  },
  {
    serviceName: 'Chat GPT',
    serviceDesc: 'Connect your Open AI account to use ChatGPT for automations & marketing.',
    image: images.GPTService,
    industry: 'Ecommerce',
    type: 'MARKETING',
    url: 'setupChatGpt',
  },
  {
    serviceName: 'Amazon SES',
    serviceDesc: 'Streamline your email marketing process with amazon’s simple emailing services',
    image: images.AmazonSES,
    industry: 'Ecommerce',
    type: 'MARKETING',
    url: 'setupAmazonSES',
  },
  {
    serviceName: 'Facebook',
    serviceDesc: 'Sync your leads, customers from all your Facebook accounts (Ads, Marketing, Business)',
    image: images.Facebook,
    industry: 'Ecommerce',
    type: 'MARKETING',
    url: 'facebookPixel',
  },
  {
    serviceName: 'Google Sheets',
    serviceDesc: 'Connect and Sync your data on Cheerio with Google sheets',
    image: images.GoogleIcon,
    industry: 'Ecommerce',
    type: 'MARKETING',
    url: 'Googlesheet',
  },
  {
    serviceName: 'Linkedin',
    serviceDesc: 'Connect and automate follow-ups and connections with ease',
    image: images.Linkedin,
    industry: 'Ecommerce',
    type: 'MARKETING',
    url: 'setupLinkedin',
  },
  {
    serviceName: 'Google Sheets',
    serviceDesc: 'Connect and Sync your data on Cheerio with Google sheets',
    image: images.GoogleIcon,
    industry: 'Ecommerce',
    type: 'MARKETING',
    url: 'Googlesheet',
  },
];

const MenuItem = ({ title, setSelectedIndustry }) => {
  return (
    <>
      <div
        className="Row justify-content-start w-100"
        style={{
          paddingBlock: 8,
          paddingInlineStart: 15,
          marginBlock: 4,
          backgroundColor: title == 'All' ? '#F5F0FF' : '',
          cursor: 'pointer',
          borderRadius: 8,
        }}
        onClick={() => {
          setSelectedIndustry(title);
        }}
      >
        <p
          style={{
            padding: 0,
            margin: 0,
            marginInline: 5,
            fontSize: 14,
            fontWeight: 600,
            color: title == 'All' ? colors.primary03 : colors.darkgrey,
          }}
        >
          {title}
        </p>
      </div>
    </>
  );
};

const Platforms = ({ showPremiumModal, setShowPremiumModal }) => {
  const [selectedIndustry, setSelectedIndustry] = useState('All');
  const [filteredData, setFilteredData] = useState([]);
  const [designs, setDesigns] = useState();
  const authtoken = useSelector((state) => state.main.auth_token);
  // const [json, setJson] = useState();
  // const [showBuild, setShowBuild] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localdata = useSelector((state) => state.main.localdata);
  const specialID = (localdata?._id === '636be67d42c9a5cb33f60d98' || localdata?._id === '660e878b6d6015c4e49b0434' || localdata?._id === '65f7d39aecb11f98652fffcd' || localdata?._id === "6489675b4f598e055ed74e34" || localdata?._id === '65f7d42aecb11f98652fffd2' || localdata?.integratedApp.some(app => app.includes("Chat GPT")))

  const isBasic = localdata?.premiumPlanName === 'Basic' && localdata?.premiumSubscriptionId;
  const isStartup = localdata?.premiumPlanName === 'Startup' && localdata?.premiumSubscriptionId;

  useEffect(() => {
    trackPages("integrations_discover_platforms_page", localdata)
    getDesignAPIcall();
  }, []);

  const getDesignAPIcall = () => {
    let token = authtoken;
    V2EmailBuilderList(token).then((res) => {
      if (res?.flag) {
        setDesigns(res?.data);
      } else {
        console.log('error response ===> ', res);
      }
    });
  };

  const SidemenuItem = ({ ImageComponent, ItemText }) => {
    return (
      <>
        <div className="w-100 px-2 mt-3">
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100 px-2 py-2"
            style={{
              borderRadius: 10,
              backgroundColor: selectedIndustry === ItemText ? colors.primary01 : '',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedIndustry(ItemText);
            }}
          >
            {ImageComponent}
            <h4
              style={{
                color: selectedIndustry === ItemText ? colors.primary04 : colors.black,
                marginInlineStart: 8,
              }}
            >
              {ItemText}
            </h4>
          </div>
        </div>
      </>
    );
  };

  // const duplicateEmail = (item) => {
  //   let data = {
  //     emailJSON: item.emailJSON,
  //     emailName: `${item.emailName} copy`,
  //   };
  //   let token = authtoken;
  //   V2EmailBuilderAdd(data, token).then((res) => {
  //     if (res?.flag) {
  //       console.log('success response ===> ', res);
  //       dispatch({ type: 'addEmailTemplate', payload: res?.data });
  //       navigate('/creator/design');
  //     } else {
  //       console.log('error response ===> ', res);
  //       toast(`Error occured while saving template. Please try again later`, {
  //         position: 'top-center',
  //       });
  //     }
  //   });
  // };

  const IntegrationItem = ({ item }) => {
    const handleClick = () => {
      if (item.serviceName === 'Shopify' && isBasic) {
        setShowPremiumModal(true);
      } else if (item.serviceName === 'WooCommerce' && isBasic) {
        setShowPremiumModal(true);
      }
      else if (item.serviceName === 'Facebook' && (isBasic || isStartup)) {
        setShowPremiumModal(true);
      }
      else if (item?.serviceName === 'Google Sheets') {
        const url = `https://cheerio.integrations.klamp.io/?userId=${localdata?._id}&app_name=Google%20Sheets&api_key=${process.env.REACT_APP_GOOGLE_SHEET_API_KEY}`;
        window.open(url, '_blank');
      }
      else {
        navigate(item?.url);
      }
    };
    return (
      <>
        <div className="px-2 py-2" style={{ width: '33%' }}>
          <div
            className="d-flex flex-column justify-content-around align-items-start w-100 h-100 shadow_on_hover"
            style={{
              borderRadius: 16,
              border: `1px solid ${colors.borderwhite}`,
              backgroundColor: colors.white01,
              cursor: 'pointer',
              paddingInline: 24,
              paddingBlock: 20,
            }}
            onClick={handleClick}
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ height: 50, width: 50, borderRadius: 8, backgroundColor: '#F8F9FD' }}
            >
              {/* <h4>{item?.templateName}</h4>
              <p
                className="mt-2 w-100"
                style={{
                  color: colors.greys04,
                  whiteSpace: 'pre-line',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item?.body}
              </p> */}
              <img style={{ width: 30, height: 30, objectFit: 'contain' }} src={item?.image}></img>
            </div>
            <p style={{ paddingBlock: 16, fontWeight: '700', fontSize: '20px' }}>
              {item?.serviceName}
            </p>
            <p
              style={{ fontSize: '16px', color: '#666666' }}
            >
              {item?.serviceDesc}
            </p>
          </div>
        </div>

      </>
    );
  };

  useEffect(() => {

    if (selectedIndustry === 'All') {
      setFilteredData(servicesData);
    } else if (selectedIndustry) {
      setFilteredData(servicesData.filter((item) => item.industry === selectedIndustry));
    }
  }, [selectedIndustry]);

  return (
    <>
      {
        <div
          style={{}}
          className="d-flex flex-row justify-content-between align-items-center w-100 h-100"
        >
          <div
            id="WTSidemenu"
            className="d-flex flex-column justify-content-start align-items-center"
            style={{
              width: '20%',
              height: '100%',
              overflowY: 'auto',
              border: `1px solid ${colors.borderwhite}`,
              backgroundColor: 'white',
              padding: '10px',
            }}
          >
            <MenuItem title={'All'} setSelectedIndustry={setSelectedIndustry}></MenuItem>
            {/* <SidemenuItem
            ImageComponent={
              <FiShoppingCart
                size={18}
                color={selectedIndustry === 'Ecommerce' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Ecommerce'}
          />
          <SidemenuItem
            ImageComponent={
              <BsBank
                size={18}
                color={selectedIndustry === 'Banking' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Banking'}
          />
          <SidemenuItem
            ImageComponent={
              <RiGraduationCapLine
                size={18}
                color={selectedIndustry === 'Education' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Education'}
          />
          <SidemenuItem
            ImageComponent={
              <FaRegBuilding
                size={18}
                color={selectedIndustry === 'Real Estate' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Real Estate'}
          />
          <SidemenuItem
            ImageComponent={
              <FaRegPlusSquare
                size={18}
                color={selectedIndustry === 'Healthcare' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Healthcare'}
          /> */}
            {/* <SidemenuItem 
                ImageComponent={<BsPeople size={18} color={selectedIndustry === 'Services' ? colors.primary04 : colors.black}/>}
                ItemText={'Services'}
            /> */}
            {/* <SidemenuItem
            ImageComponent={
              <FiVideo
                size={18}
                color={selectedIndustry === 'Webinar' ? colors.primary04 : colors.black}
              />
            }
            ItemText={'Webinar'}
          /> */}
            {/* <SidemenuItem 
                ImageComponent={<RiCarLine size={18} color={selectedIndustry === 'Automobile' ? colors.primary04 : colors.black}/>}
                ItemText={'Automobile'}
            /> */}
          </div>
          <div className="w-100 h-100" style={{ overflowY: 'auto' }}>
            <Routes>
              <Route
                path="/"
                element={
                  <div
                    id="WTWorkspace"
                    className="h-100 py-2 px-2 d-flex flex-row justify-content-start align-items-stretch"
                    style={{
                      flexWrap: 'wrap',
                      overflowY: 'auto',
                      alignContent: 'flex-start',
                      scrollbarWidth: 'thin',
                    }}
                  >
                    {filteredData &&
                      filteredData.map((item, index) => {
                        return <IntegrationItem item={item} />;
                      })}
                  </div>
                }
              ></Route>
              <Route
                path="setupShopify"
                element={
                  <div
                    style={{
                      height: '100%',
                      scrollbarWidth: 'thin',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        padding: '1%',
                        alignItems: 'center',
                        backgroundColor: 'white',
                      }}
                    >
                      <img
                        onClick={() => {
                          navigate('/creator/discoverPlatforms');
                        }}
                        height={20}
                        src={images.BackArrowBlack}
                        style={{ cursor: 'pointer' }}
                      ></img>
                      <p style={{ fontSize: '16px', fontWeight: 600, marginLeft: '1%' }}>
                        Back to discover
                      </p>
                    </div>
                    <SetupShopify />{' '}
                  </div>
                }
              ></Route>
              <Route
                path="setupWooCommerce"
                element={
                  <div
                    style={{
                      height: '100%',
                      scrollbarWidth: 'thin',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        padding: '1%',
                        alignItems: 'center',
                        backgroundColor: 'white',
                      }}
                    >
                      <img
                        onClick={() => {
                          navigate('/creator/discoverPlatforms');
                        }}
                        height={20}
                        src={images.BackArrowBlack}
                        style={{ cursor: 'pointer' }}
                      ></img>
                      <p style={{ fontSize: '16px', fontWeight: 600, marginLeft: '1%' }}>
                        Back to discover
                      </p>
                    </div>
                    <Setup></Setup>
                  </div>
                }
              ></Route>
              <Route
                path="setupKylas"
                element={
                  <div
                    style={{
                      height: '100%',
                      scrollbarWidth: 'thin',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        padding: '1%',
                        alignItems: 'center',
                        backgroundColor: 'white',
                      }}
                    >
                      <img
                        onClick={() => {
                          navigate('/creator/discoverPlatforms');
                        }}
                        height={20}
                        src={images.BackArrowBlack}
                        style={{ cursor: 'pointer' }}
                      ></img>
                      <p style={{ fontSize: '16px', fontWeight: 600, marginLeft: '1%' }}>
                        Back to discover
                      </p>
                    </div>
                    <SetupKylas></SetupKylas>
                  </div>
                }
              ></Route>
              <Route
                path="setupChatGpt"
                element={
                  <div
                    style={{
                      height: '100%',
                      scrollbarWidth: 'thin',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        padding: '1%',
                        alignItems: 'center',
                        backgroundColor: 'white',
                      }}
                    >
                      <img
                        onClick={() => {
                          navigate('/creator/discoverPlatforms');
                        }}
                        height={20}
                        src={images.BackArrowBlack}
                        style={{ cursor: 'pointer' }}
                      ></img>
                      <p style={{ fontSize: '16px', fontWeight: 600, marginLeft: '1%' }}>
                        Back to discover
                      </p>
                    </div>
                    {!specialID ? (
                      <>
                        <GPTContactSalesModal />
                      </>
                    ) : (
                      <SetupOpenAi />
                    )}
                  </div>
                }
              ></Route>
              <Route
                path="setupAmazonSES"
                element={
                  <div
                    style={{
                      height: '100%',
                      scrollbarWidth: 'thin',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        padding: '1%',
                        alignItems: 'center',
                        backgroundColor: 'white',
                      }}
                    >
                      <img
                        onClick={() => {
                          navigate('/creator/discoverPlatforms');
                        }}
                        height={20}
                        src={images.BackArrowBlack}
                        style={{ cursor: 'pointer' }}
                      ></img>
                      <p style={{ fontSize: '16px', fontWeight: 600, marginLeft: '1%' }}>
                        Back to discover
                      </p>
                    </div>
                    <AmazonSES></AmazonSES>
                  </div>
                }
              ></Route>
              <Route
                path="faceBookPixel"
                element={
                  <div
                    style={{
                      height: '100%',
                      scrollbarWidth: 'thin',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        padding: '1%',
                        alignItems: 'center',
                        backgroundColor: 'white',
                      }}
                    >
                      <img
                        onClick={() => {
                          navigate('/creator/discoverPlatforms');
                        }}
                        height={20}
                        src={images.BackArrowBlack}
                        style={{ cursor: 'pointer' }}
                      ></img>
                      <p style={{ fontSize: '16px', fontWeight: 600, marginLeft: '1%' }}>
                        Back to discover
                      </p>
                    </div>
                    <Pixel></Pixel>
                  </div>
                }
              ></Route>
              <Route
                path="setupLinkedin"
                element={
                  <div
                    style={{
                      height: '100%',
                      scrollbarWidth: 'thin',
                      overflowY: 'auto',
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        padding: '1%',
                        alignItems: 'center',
                        backgroundColor: 'white',
                      }}
                    >
                      <img
                        onClick={() => {
                          navigate('/creator/discoverPlatforms');
                        }}
                        height={20}
                        src={images.BackArrowBlack}
                        style={{ cursor: 'pointer' }}
                      ></img>
                      <p style={{ fontSize: '16px', fontWeight: 600, marginLeft: '1%' }}>
                        Back to discover
                      </p>
                    </div>
                    <Linkedin></Linkedin>
                  </div>
                }
              ></Route>
            </Routes>
          </div>
        </div>
      }
    </>
  );
};

export const DiscoverPlatforms = () => {
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  useEffect(() => {
    eventsend("Discover_platforms_intergrations_Side_bar_clicked");
  }, [])
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ borderBottom: '1px solid #CDCDCD', padding: '1%', backgroundColor: 'white' }}>
        <p style={{ fontWeight: '700', fontSize: '20px' }}>Discover Platforms</p>
        <p style={{ color: '#666666', fontSize: '16px', marginTop: '5px' }}>
          Discover and integrate industry leading platform on Cheerio & grow your business using
          multi-channel marketing
        </p>
      </div>
      <Platforms showPremiumModal={showPremiumModal} setShowPremiumModal={setShowPremiumModal}></Platforms>
      {
        showPremiumModal && (
          <PremiumFeatureModal onClose={() => setShowPremiumModal(false)} />
        )
      }
    </div>

  );
};
