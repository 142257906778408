import React from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import CheerioButton from '../../../Components/CheerioButton'

const VerificationSuccessModal = ({ setIsVSModalOpen, verificationData, activeStep, setActiveStep }) => {
  return (
    <>
    <div className='DarkBg' onClick={()=>{}}>
        <div className='Center' style={{transform:'translate(calc(-50% + 40px),-50%)'}}>
            <div className='Column' style={{
                justifyContent:'space-between',
                width: 600,
                backgroundColor: colors.white01,
                borderRadius:16,
                zIndex:0,
                border:`1px solid ${colors.borderwhite}`,
                paddingInline:40,
                paddingBlock:24,
            }}>
                <div className='Row justify-content-between w-100' style={{
                    paddingBlockEnd:20,
                    borderBottom:`1px solid ${colors.borderwhite}`
                }}>
                    <h4 style={{fontSize:16,fontWeight:600}}>
                        {'Contacts Imported'}
                    </h4>
                    <img 
                        src={images.CMClose} 
                        style={{
                            height:20,width:20,objectFit:'contain',cursor:'pointer'
                        }} 
                        onClick={()=>{setIsVSModalOpen(false)}}
                    />
                </div>
                <div className='Column my-2 w-100'>
                    <div className='Row w-100 justify-content-between my-2'>
                        <p>
                            {'Uploaded contacts'}
                        </p>
                        <p>
                            {Number(verificationData?.statusCounts?.safe)+Number(verificationData?.statusCounts?.invalid)}
                        </p>
                    </div>
                    <div className='Row w-100 justify-content-between my-2'>
                        <p>
                            {'Verified emails'}
                        </p>
                        <p>
                            {verificationData?.statusCounts?.safe}
                        </p>
                    </div>
                    <div className='Row w-100 justify-content-between my-2'>
                        <p>
                            {'Invalid emails'}
                        </p>
                        <p>
                            {verificationData?.statusCounts?.invalid}
                        </p>
                    </div>
                </div>
                <CheerioButton 
                    btnText={'Okay'}
                    textStyle={{color:colors.white01}}
                    backColor={colors.primary03}
                    borderStyle={{borderColor:colors.primary03,width:'98%'}} 
                    onclick={()=>{
                        if(activeStep === 0){
                            setActiveStep(1);
                        }
                        setIsVSModalOpen(false);
                    }}
                />
            </div>
        </div>
    </div>
    </>
  )
}

export default VerificationSuccessModal