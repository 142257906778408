import { Box, Typography, Popover, Menu, IconButton, Skeleton, Tooltip, InputLabel, Select, MenuItem, TextField, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import currencyCodes from 'currency-codes';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import styled from 'styled-components';
import CheerioButton from '../../../Components/CheerioButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { BlockPicker } from "react-color";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import NumbersIcon from '@mui/icons-material/Numbers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';
import PhoneIcon from '@mui/icons-material/Phone';
import PublicIcon from '@mui/icons-material/Public';
import EmailIcon from '@mui/icons-material/Email';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { createCustomAttr, updateCustomAttr } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FiTrash2 } from 'react-icons/fi';


const Container = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;

`;
const attributeTypes = [
    'Short Text',
    'Paragraph',
    'Date',
    'Time',
    'Timestamp',
    'Email',
    'Phone',
    'Dropdown',
    'Number',
    'Link',
    'Website',
    'Currency',
];


const AddAttributeSideBar = ({ setShowSideBar, setEditAttribueShowSideBar, fetchAttr, editingAttribute }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [selectedAttributeType, setSelectedAttributeType] = useState('');
    const [attributeName, setAttributeName] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [defaultCurrency, setDefaultCurrency] = useState('INR');
    const [defaultNumber, setDefaultNumber] = useState(0);
    const [isDefault, setIsDefault] = useState(false);
    const [defaultValue, setDefaultValue] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [error, setError] = useState("");
    const [updateAttributeModal, setUpdateAttributeModal] = useState(false);

    const [options, setOptions] = useState([
        { id: 1, optionName: "Option 1", color: "#FFCCCC", position: 1, isEditable: true },
        { id: 2, optionName: "Option 2", color: "#FFE599", position: 2, isEditable: true },
        { id: 3, optionName: "Option 3", color: "#C2F0C2", position: 3, isEditable: true },
    ]);
    useEffect(() => {
        if (editingAttribute) {
            setIsEditMode(true);
            setAttributeName(editingAttribute.attribute);
            setSelectedAttributeType(editingAttribute.type);
            setIsDefault(editingAttribute.isDefault);
            setDefaultValue(editingAttribute.defaultValue || '');
            if (editingAttribute.type === 'dropdown' && editingAttribute.options) {
                setOptions(editingAttribute.options.map((opt, index) => ({
                    id: index + 1,
                    optionName: opt.optionName,
                    color: opt.color,
                    position: opt.position,
                    isEditable: false
                })));
            }
        }
    }, [editingAttribute]);
    const handleAttributeName = (e) => {
        const input = e.target.value;
        if (/[^a-zA-Z0-9 _]/.test(input)) {
            setError("Attribute name cannot have uppercase, special characters or spaces");
        } else {
            setError("");
        }
        if (input.length > 25) {
            setError("Attribute name cannot be more than 25 characters");
            return
        }
        const sanitized = input
            .replace(/[^a-zA-Z0-9_ ]/g, "")
            .replace(/ /g, "_")
            .toLowerCase();

        setAttributeName(sanitized);
    };

    const handleUpdateAttribute = async () => {
        try {
            const payload = {
                attribute: attributeName,
                type: selectedAttributeType,
                isDefault: editingAttribute?.isDefault || false,
                defaultValue: selectedAttributeType === "currency" ? defaultCurrency : defaultValue,
                options: selectedAttributeType === "dropdown" ? options : [],
                hidden: editingAttribute?.hidden || false,
                position: editingAttribute?.position || 0
            };
            const response = await updateCustomAttr(authtoken, editingAttribute?._id, payload);
            if (response?.flag) {
                toast.success('Attribute updated successfully', { autoClose: true });
                setShowSideBar(false);
                setEditAttribueShowSideBar(false);
                fetchAttr();
                resetForm();
            } else {
                toast.error(response.message, { autoClose: true });
                console.error('Failed to update hidden attribute', response.message);
            }
        } catch (error) {
            console.error('Error updating attribute', error);
            toast.error('Error updating attribute', { autoClose: true });
        }
    };

    const [colorAnchorEl, setColorAnchorEl] = useState(null);
    const [selectedOptionId, setSelectedOptionId] = useState(null);
    const [blockPickerColor, setBlockPickerColor] = useState("#FFFFFF")

    const handleColorClick = (event, id) => {
        setColorAnchorEl(event.currentTarget);
        setSelectedOptionId(id);
        const selectedOption = options.find((option) => option.id === id);
        setBlockPickerColor(selectedOption.color);
    };
    const handleClose = () => {
        setColorAnchorEl(null);
        setSelectedOptionId(null);
    };

    const handleColorChange = (color) => {
        setOptions(
            options.map((option) =>
                option.id === selectedOptionId ? { ...option, color: color.hex } : option
            )
        );
        setBlockPickerColor(color.hex);

        setColorAnchorEl(null);
        setSelectedOptionId(null);
    };

    const handleDelete = (id) => {
        const updatedOptions = options.filter((option) => option.id !== id);

        const reorderedOptions = updatedOptions.map((opt, index) => ({
            ...opt,
            position: index + 1,
        }));

        setOptions(reorderedOptions);
    };

    const handleOptionNameChange = (id, value) => {
        setOptions(
            options.map((option) =>
                option.id === id ? { ...option, optionName: value } : option
            )
        );
    };

    const handleAddOption = () => {
        const newId = options.length > 0 ? options[options.length - 1].id + 1 : 1;
        const newPosition = options.length > 0 ? options[options.length - 1].position + 1 : 1;
        const newOption = {
            id: newId,
            optionName: `Option ${newId}`,
            color: "#FFFFFF",
            position: newPosition,
            isEditable: true,
        };
        setOptions([...options, newOption]);
    };


    const handleCloseModal = () => {
        setShowSideBar(false);
        setEditAttribueShowSideBar(false)
    };
    const attributeTypeOptions = [
        { value: 'text', label: 'Text Field (upto 200 characters)', icon: <TextFieldsIcon fontSize="small" /> },
        { value: 'paragraph', label: 'Paragraph (upto 2000 characters)', icon: <FormatAlignLeftIcon fontSize="small" /> },
        { value: 'number', label: 'Number', icon: <NumbersIcon fontSize="small" /> },
        { value: 'dropdown', label: 'Dropdown', icon: <ArrowDropDownIcon fontSize="small" /> },
        { value: 'date', label: 'Date', icon: <CalendarTodayIcon fontSize="small" /> },
        { value: 'time', label: 'Time', icon: <AccessTimeIcon fontSize="small" /> },
        { value: 'timestamp', label: 'Timestamp', icon: <CalendarTodayIcon fontSize="small" /> },
        { value: 'link', label: 'Link', icon: <LinkIcon fontSize="small" /> },
        { value: 'phone', label: 'Phone', icon: <PhoneIcon fontSize="small" /> },
        { value: 'website', label: 'Website', icon: <PublicIcon fontSize="small" /> },
        { value: 'email', label: 'Email', icon: <EmailIcon fontSize="small" /> },
        { value: 'currency', label: 'Currency', icon: <CurrencyRupeeIcon fontSize="small" /> },
    ];


    const handleAddAttribute = async () => {
        try {
            const payload = {
                attribute: attributeName,
                type: selectedAttributeType,
                isDefault: isDefault || false,
                defaultValue: selectedAttributeType === "currency" ? defaultCurrency : defaultValue,
                options: selectedAttributeType === "dropdown" ? options : []
            };
            if (attributeName === "name" || attributeName === "email" || attributeName === "phone" || attributeName === "createdat" || attributeName === "updatedat") {
                toast.error('Attribute name already exists');
                return;
            }
            const response = await createCustomAttr(authtoken, payload);
            console.log('Response:', response.flag);
            if (response.flag) {
                toast.success('Attribute added successfully', { autoClose: true });
                setShowSideBar(false);
                fetchAttr();
                resetForm();
            } else {
                toast.error(response.message, { autoClose: true });
            }
        } catch (error) {
            console.error('Error adding attribute:', error);
            toast.error('Error adding attribute', { autoClose: true });
        }
    };

    const resetForm = () => {
        setAttributeName('');
        setSelectedAttributeType('');
        setIsDefault(false);
        setDefaultValue('');
        setOptions([]);
        setIsEditMode(false);
    };

    return (
        <>
            <Container>
                <Header>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            gap: '10px',
                            overflow: 'hidden',

                        }}
                    >
                        <p style={{ fontWeight: 'bold', marginLeft: '20px' }}>{isEditMode ? 'Edit Attribute' : 'Add new Attributes'}</p>

                        <Tooltip title="Close Details">
                            <img
                                src={images.CMClose}
                                height={25}
                                width={25}
                                style={{ cursor: 'pointer' }}
                                onClick={handleCloseModal}
                            />
                        </Tooltip>
                    </div>
                </Header>
                <hr style={{ color: "gray", width: "100%" }} />


                <div style={{
                    marginLeft: '30px',
                    width: '100%',
                    marginRight: '30px',
                    height: '90vh',
                    paddingBottom: '1rem',
                    overflowY: 'auto'
                }}>
                    {/* Attribute Name */}
                    <div>
                        <InputGroup className="mb-3" style={{ width: '100%', marginTop: 10, padding: "0 5px" }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                                    {'Attribute Name*'}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    className="w-100 btncustom dropdown"
                                    style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01, width: "100%", border: error ? '1px solid red' : `1px solid ${colors.darkPurple}` }}
                                    value={attributeName}
                                    onChange={handleAttributeName}
                                    min={5}
                                    max={25}
                                ></Form.Control>
                                {error && <small style={{ color: "red" }}>{error}</small>}
                            </div>
                        </InputGroup>
                    </div>

                    {/* Attribute Type */}
                    <div>
                        <InputGroup className="mb-3" style={{ width: '100%', marginTop: 10, borderRadius: '8px', padding: "0 5px" }}>
                            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: 'black' }}>
                                {'Attribute Type*'}
                            </Form.Label>
                            <Select
                                value={selectedAttributeType}
                                onChange={(e) => setSelectedAttributeType(e.target.value)}
                                displayEmpty
                                fullWidth
                                disabled={isEditMode && editingAttribute}
                                sx={{
                                    borderRadius: '8px',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: '#666',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '6px',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderRadius: '8px',
                                        border: '1px solid #ced4da',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #868e96',
                                    },
                                    '.MuiSelect-select': {
                                        padding: '12px 14px'
                                    }
                                }}
                            >
                                {attributeTypeOptions.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        {option.icon}
                                        <span style={{
                                            marginLeft: 8, fontFamily: 'Segoe UI',
                                            fontSize: '16px',
                                            fontWeight: 400
                                        }}>{option.label}</span>
                                    </MenuItem>
                                ))}
                            </Select>
                        </InputGroup>
                    </div>

                    {selectedAttributeType === 'number' && (
                        <div>
                            <InputGroup className="mb-3" style={{ width: '100%', padding: "0 5px" }}>
                                <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                                    {'Default Value'}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Empty"
                                    className="w-100 btncustom dropdown"
                                    style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
                                    value={defaultNumber}
                                    onChange={(e) => setDefaultNumber(e.target.value)}
                                    min={5}
                                    max={60}
                                ></Form.Control>
                            </InputGroup>
                        </div>
                    )}

                    {selectedAttributeType === 'dropdown' && (
                        <Box sx={{ p: 2, maxWidth: 500, borderRadius: 2, bgcolor: "#F9F9F9", overflowY: 'auto' }}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Dropdown Options
                            </Typography>
                            {options.map((option) => (
                                <Box
                                    key={option.id}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{
                                        my: 1,
                                        p: 1,
                                        borderRadius: 2,
                                        bgcolor: "#FFFFFF",
                                        boxShadow: 1,
                                    }}
                                >
                                    {/* Color Picker */}
                                    <Box
                                        sx={{
                                            width: 20,
                                            height: 20,
                                            borderRadius: "50%",
                                            bgcolor: option.color,
                                            cursor: option.isEditable ? "pointer" : "not-allowed",
                                            border: "1px solid #ddd",
                                        }}
                                        onClick={(e) => option.isEditable && handleColorClick(e, option.id)}
                                    />

                                    {/* Editable Option Name */}
                                    <TextField
                                        variant="standard"
                                        value={option.optionName}
                                        onChange={(e) => {
                                            if (option.isEditable) handleOptionNameChange(option.id, e.target.value);
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            readOnly: !option.isEditable,
                                        }}
                                        sx={{ flexGrow: 1, mx: 1 }}
                                    />

                                    {/* Delete Option */}
                                    <Tooltip title="Delete option" arrow>
                                        <div
                                            style={{
                                                cursor: option.isEditable ? "pointer" : "not-allowed",
                                                color: option.isEditable ? 'black' : "#ccc"
                                            }}
                                            onClick={() => {
                                                if (option.isEditable) handleDelete(option.id);
                                            }}
                                        >
                                            <FiTrash2 size={20} />
                                        </div>
                                    </Tooltip>
                                </Box>
                            ))}

                            {/* Add Option Button */}
                            <CheerioButton
                                textStyle={{ color: 'black' }}
                                btnText={"Add Option"}
                                onclick={handleAddOption}
                            />
                            {/* Color Picker Popover */}
                            <Popover
                                open={Boolean(colorAnchorEl)}
                                anchorEl={colorAnchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                            >
                                <BlockPicker color={blockPickerColor} onChange={handleColorChange} />
                            </Popover>
                        </Box>
                    )}


                    {selectedAttributeType === 'date' && (
                        <div className='flex flex-col'>
                            <InputGroup style={{ width: "100%", padding: "0 5px" }}>
                                <Form.Label
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: colors.black,
                                        display: "block",
                                        marginBottom: 5,
                                    }}
                                >
                                    {"Date Format"}
                                </Form.Label>
                            </InputGroup>
                            <Form.Control
                                value="DD/MM/YYYY"
                                readOnly
                                className="w-100 btncustom dropdown"
                                style={{
                                    borderRadius: '8px',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: colors.greys01,
                                    border: '1px solid #ced4da',
                                    padding: '10px 14px',
                                    cursor: 'default',
                                    backgroundColor: '#f8f9fa',
                                }}
                            />

                            <div>
                                <InputGroup className="mb-3 mt-3" style={{ width: '100%', padding: "0 5px" }}>
                                    <Form.Label
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: colors.black
                                        }}
                                    >
                                        {'Time Format'}
                                    </Form.Label>
                                </InputGroup>
                                <Form.Control
                                    value="HH:MM AM/PM"
                                    readOnly
                                    className="w-100 btncustom dropdown"
                                    style={{
                                        borderRadius: '8px',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        color: colors.greys01,
                                        border: '1px solid #ced4da',
                                        padding: '10px 14px',
                                        cursor: 'default',
                                        backgroundColor: '#f8f9fa',
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {selectedAttributeType === 'timestamp' && (
                        <div className='flex flex-col'>
                            <InputGroup style={{ width: "100%", padding: "0 5px" }}>
                                <Form.Label
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: colors.black,
                                        display: "block",
                                        marginBottom: 5,
                                    }}
                                >
                                    {"Timestamp Format"}
                                </Form.Label>
                            </InputGroup>
                            <Form.Control
                                value="DD/MM/YYYY, 12:30 PM"
                                readOnly
                                className="w-100 btncustom dropdown"
                                style={{
                                    borderRadius: '8px',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: colors.greys01,
                                    border: '1px solid #ced4da',
                                    padding: '10px 14px',
                                    cursor: 'default',
                                    backgroundColor: '#f8f9fa',
                                }}
                            />
                        </div>
                    )}

                    {selectedAttributeType === 'currency' && (
                        <CurrencyDropdown defaultCurrency={defaultCurrency} setDefaultCurrency={setDefaultCurrency} />
                    )}

                    <div style={{ marginTop: '24px', padding: "0 10px" }}>
                        <CheerioButton
                            borderStyle={{ border: "8px" }}
                            textStyle={{ color: colors.white }}
                            btnText={isEditMode ? 'Save Changes' : 'Create attribute'}
                            backColor={colors.darkPurple}
                            onclick={() => {
                                if (isEditMode) {
                                    setUpdateAttributeModal(true);

                                } else {
                                    handleAddAttribute();
                                    // setShowSideBar(false)
                                }
                            }
                            }
                        />
                    </div>
                    <div style={{ marginTop: '18px', padding: "0 10px" }}>
                        <CheerioButton
                            textStyle={{ color: colors.black, }}
                            btnText={'Cancel'}
                            onclick={() => {
                                setShowSideBar(false);
                                setEditAttribueShowSideBar(false);
                                resetForm();
                            }}
                        />
                    </div>
                </div>

                {updateAttributeModal && (
                    <UpdateAttributeModal

                        setUpdateAttributeModal={setUpdateAttributeModal}
                        setShowSideBar={setShowSideBar}
                        handleUpdateAttribute={handleUpdateAttribute}
                    />
                )}
            </Container >
        </>
    );
};
export default AddAttributeSideBar;
export const CurrencyDropdown = ({ defaultCurrency, setDefaultCurrency }) => {

    return (
        <div>
            <InputGroup className="mb-3" style={{ width: '100%', padding: "0 5px" }}>
                <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                    {'Default Currency'}
                </Form.Label>
                <Form.Select
                    className="w-100 btncustom dropdown"
                    style={{
                        borderRadius: 8,
                        fontSize: 16,
                        fontWeight: 400,
                        color: colors.greys01,
                        cursor: "pointer",
                        maxHeight: '200px',
                        overflowY: 'auto',
                    }}
                    value={defaultCurrency}
                    onChange={(e) => setDefaultCurrency(e.target.value)}
                >
                    {currencyCodes.data.map((currency) => (
                        <option
                            key={currency.code}
                            value={currency.code}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '5px',
                            }}
                        >
                            {currency.code} - {currency.currency} ({currency.countries.slice(0, 2).join(', ')})
                        </option>
                    ))}
                </Form.Select>
            </InputGroup>
        </div>
    );
};


const UpdateAttributeModal = ({ setUpdateAttributeModal, setShowSideBar, handleUpdateAttribute }) => {
    return (
        <>
            <div style={{
                width: '100%',
                height: "100%",
                zIndex: 1000,
                top: 0,
                left: 0,
                position: 'fixed',
                background: 'rgba(0,0,0,0.5)',
            }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                    }}
                >
                    <div
                        className="d-flex flex-column justify-content-start align-items-center"
                        style={{
                            width: 450,
                            backgroundColor: colors.white01,
                            borderRadius: 16,
                            zIndex: 0,
                            border: `1px solid ${colors.borderwhite}`,
                            paddingBlock: 20,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <div
                            className="d-flex flex-row justify-content-between align-items-center w-100"
                            style={{
                                paddingBlockEnd: 8,
                                borderBottom: `1px solid ${colors.borderwhite}`,
                                paddingInline: 16,
                            }}
                        >

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '4px',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {/* <img src={images.CDelete} style={{ height: 24, width: 'auto' }} /> */}
                                <p
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        fontSize: 18,
                                        fontWeight: 700,
                                        color: colors.black,
                                    }}
                                >
                                    {'Update Attribute'}
                                </p>
                            </div>
                            <img
                                src={images.CMClose}
                                style={{
                                    height: 24,
                                    width: 24,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setUpdateAttributeModal(false);
                                }}
                            ></img>
                        </div>
                        <div
                         style={{
                          marginLeft:'-70px'
                        }}
                        >
                        <p 
                            style={{
                                margin: 12 , 
                                fontSize: 16,
                                fontWeight: 500,
                                color: colors.black,
                                marginInline: 16,
                            }}
                        >
                            {'Are you sure you want to update this attribute?'}
                        </p>
                        </div>
                        <div
                            className="d-flex flex-row justify-content-center align-items-center w-100"
                            style={{ marginTop: 12, gap: '10px' }}
                        >
                            <CheerioButton
                                borderStyle={{
                                    width: '40%',
                                    marginInlineStart: 8,
                                    border: `1px solid ${colors.primary03}`,
                                }}
                                textStyle={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: colors.primary03,
                                }}
                                btnText={'Cancel'}
                                backColor={colors.white}
                                onclick={() => {
                                    console.log('cancel clicked . . .');
                                    setUpdateAttributeModal(false);
                                }}
                            />
                            <CheerioButton
                                borderStyle={{
                                    borderColor: colors.darkPurple,
                                    width: '40%',
                                    marginInlineEnd: 8,
                                }}
                                textStyle={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: colors.white,
                                }}
                                btnText={'Update'}
                                backColor={colors.darkPurple}
                                onclick={() => {
                                    handleUpdateAttribute();
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}