import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { createEmbeddedFormResponse, getEmbeddedFormsById } from '../../Services';
import FormInput from './Components/formInput';
import colors from '../../Utils/colors';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import SpinnerLoader from '../../Components/SpinnerLoader';
import { toast } from 'react-toastify';

const FormURL = () => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const { id } = useParams();
    const [submitting, setSubmitting] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState();
    const [successScreen, setSuccessScreen] = useState(false);

    const [userResponse, setUserResponse] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const [error, setError] = useState(false);

    const [closeTimer, setCloseTimer] = useState(3);
    useEffect(() => {
        let timer;
        if (successScreen) {
            timer = setInterval(() => {
                setCloseTimer((prev) => prev - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [successScreen]);

    useEffect(() => {
        if (id) {
            getFormByID(id);
        }
    }, [id])

    useEffect(() => {
        if (formData?.formFields) {
            setUserResponse(formData?.formFields.map(field => ({ title: field.fieldName, response: '', attributeName: field.attributeName })));
        }
    }, [formData])

    useEffect(() => {
        checkMandatoryFields();
    }, [userResponse]);

    const handleInputChange = (index, value) => {
        const updatedResponses = [...userResponse];
        updatedResponses[index].response = value;
        setUserResponse(updatedResponses);
    };

    const getFormByID = (_id) => {
        let token = authtoken;
        getEmbeddedFormsById(token, _id).then((res) => {
            if (res?.flag) {
                setFormData(res?.data);
                console.log("success ---> ", res);
            } else {
                console.log("error res ---> ", res);
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        })
    }

    const submitFormAction = () => {
        setSubmitting(true);
        let data = {
            _id: id,
            formResponses: userResponse,
        }
        createEmbeddedFormResponse(data).then((res) => {
            if (res?.flag) {
                setSuccessScreen(true);
                console.log("1111111111 ", res);
            } else {
                setSubmitting(false);
                toast.error('The form you are trying to submit is inactive and not taking any more responses', { position: 'top-right' })
                console.log("222222222 ", res);
            }
        })
    }

    const checkMandatoryFields = () => {
        const allMandatoryFilled = formData?.formFields.every((field, index) => {
            if (field.mandatory) {
                return userResponse[index]?.response.trim() !== '';
            }
            return true;
        });
        setDisableButton(!allMandatoryFilled);
    };

    return (
        <>
            <div className='form_url_page' style={{
                fontFamily: formData?.formProperties?.font ? formData?.formProperties?.font : 'Segoe UI',
            }}>
                {isLoading ? (
                    <>
                        <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
                            <SpinnerLoader />
                        </div>
                    </>
                ) : (
                    <>
                        {formData ? (
                            <>
                                {successScreen ? (
                                    <>
                                        <div className='form_url_container' id="success-screen" style={{
                                            backgroundColor: formData?.formProperties?.bodyColor ? formData?.formProperties?.bodyColor : colors.white01,
                                        }}>
                                            {formData?.logo?.url.length > 0 &&
                                                <img src={formData?.logo?.url} style={{
                                                    height: 'auto',
                                                    width: '25%',
                                                    objectFit: 'contain',
                                                    marginBlockEnd: 16,
                                                }}></img>
                                            }
                                            <img src={images.CircleCheckGreen}
                                                style={{
                                                    height: 100,
                                                    width: 100,
                                                    objectFit: 'contain',
                                                    marginBlock: 24,
                                                }}
                                            >
                                            </img>
                                            <div className='w-100' style={{
                                                marginBlockStart: 16,
                                                textAlign: 'center',
                                            }}>
                                                <p style={{
                                                    width: '100%',
                                                    fontSize: 20,
                                                    fontWeight: 700,
                                                }}>
                                                    {'Response Submitted'}
                                                </p>
                                            </div>
                                            {formData?.formButton?.message &&
                                                <div className='w-100' style={{
                                                    marginBlockEnd: 16,
                                                }}>
                                                    <p style={{
                                                        width: '100%',
                                                        fontSize: 16,
                                                        textAlign: 'center',
                                                    }}>
                                                        {formData?.formButton?.message}
                                                    </p>
                                                </div>
                                            }
                                            <div style={{
                                                marginTop: 16,
                                            }}>
                                                <p>{`The form will auto-close in ${closeTimer} second${closeTimer !== 1 ? 's' : ''}.`}</p>
                                            </div>
                                            {/* <div className='w-100' style={{
                                                marginBlock: 16,
                                            }}>
                                                <p style={{
                                                    width: '100%',
                                                    fontSize: 20
                                                }}>
                                                    {'Your response is submitted'}
                                                </p>
                                            </div> */}
                                            {formData?.watermark?.watermark &&
                                                <>
                                                    <div className='d-flex flex-row justify-content-center align-items-center w-100' style={{ paddingBlock: 16 }}>
                                                        <img src={images.BoltYellow} style={{
                                                            height: 16,
                                                            width: 16,
                                                            objectFit: 'contain'
                                                        }}></img>
                                                        <p style={{ marginInlineStart: 8 }}>
                                                            {'Powered by CheerioAI'}
                                                        </p>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {formData.active ? (
                                            <>
                                                <div className='form_url_container' style={{
                                                    backgroundColor: formData?.formProperties?.bodyColor ? formData?.formProperties?.bodyColor : colors.white01,
                                                }}>
                                                    {formData?.logo?.url.length > 0 &&
                                                        <>
                                                            <div className='form_url_container__logo_container' style={{
                                                                justifyContent: formData?.logo?.alignment ? formData?.logo?.alignment : 'center',
                                                                paddingBlock: formData?.logo?.padding ? Number(formData?.logo?.padding) : 16,
                                                                paddingInline: formData?.logo?.padding ? Number(formData?.logo?.padding) : 16,
                                                                borderRadius: formData?.logo?.cornerRadius ? Number(formData?.logo?.cornerRadius) : '',
                                                            }}>
                                                                <img src={formData?.logo?.url} style={{
                                                                    height: 'auto',
                                                                    width: formData?.logo?.headerWidth ? `${formData?.logo?.headerWidth}%` : '30%',
                                                                    objectFit: 'contain'
                                                                }}></img>
                                                            </div>
                                                        </>
                                                    }
                                                    {formData?.headerImage?.url.length > 0 &&
                                                        <>
                                                            <div className='form_url_container__headerImage_container' style={{
                                                                justifyContent: formData?.headerImage?.alignment ? formData?.headerImage?.alignment : 'center',
                                                                paddingBlock: formData?.headerImage?.padding ? Number(formData?.headerImage?.padding) : 16,
                                                                borderRadius: formData?.headerImage?.cornerRadius ? Number(formData?.headerImage?.cornerRadius) : '',
                                                            }}>
                                                                <img src={formData?.headerImage?.url} style={{
                                                                    height: 'auto',
                                                                    width: formData?.headerImage?.headerWidth ? `${formData?.headerImage?.headerWidth}%` : '100%',
                                                                    objectFit: 'contain'
                                                                }}></img>
                                                            </div>
                                                        </>
                                                    }
                                                    {formData?.formTitle?.title.length > 0 &&
                                                        <>
                                                            <div className='form_url_container__formTitle_container' style={{
                                                                justifyContent: formData?.formTitle?.alignment ? formData?.formTitle?.alignment : 'center',
                                                                paddingBlock: 16, color: formData?.formProperties?.textColor ? formData?.formProperties?.textColor : colors.black
                                                            }}>
                                                                <h4 style={{
                                                                    fontFamily: formData?.formTitle?.font ? formData?.formTitle?.font : 'Segoe UI',
                                                                    fontWeight: formData?.formTitle?.fontWeigth == 'Bold' ? 600 : formData?.formTitle?.fontWeigth == 'Light' ? 200 : 400,
                                                                    fontSize: formData?.formTitle?.fontSize ? Number(formData?.formTitle?.fontSize) : 24
                                                                }}>
                                                                    {formData?.formTitle?.title}
                                                                </h4>
                                                            </div>
                                                        </>
                                                    }
                                                    {formData?.formDescription?.description.length > 0 &&
                                                        <>
                                                            <div className='form_url_container__formDescription_container' style={{
                                                                justifyContent: formData?.formDescription?.alignment ? formData?.formDescription?.alignment : 'center',
                                                                paddingBlockEnd: 16, color: formData?.formProperties?.textColor ? formData?.formProperties?.textColor : colors.black
                                                            }}>
                                                                <p style={{
                                                                    fontFamily: formData?.formDescription?.font ? formData?.formDescription?.font : 'Segoe UI',
                                                                    fontWeight: formData?.formDescription?.fontWeigth == 'Bold' ? 600 : formData?.formDescription?.fontWeigth == 'Light' ? 200 : 400,
                                                                    fontSize: formData?.formDescription?.fontSize ? Number(formData?.formDescription?.fontSize) : 16
                                                                }}>
                                                                    {formData?.formDescription?.description}
                                                                </p>
                                                            </div>
                                                        </>
                                                    }
                                                    {formData?.formFields?.map((item, index) => {
                                                        return (
                                                            <>
                                                                <FormInput
                                                                    field={item}
                                                                    general={formData?.formProperties}
                                                                    index={index}
                                                                    userResponse={userResponse[index]}
                                                                    handleInputChange={(value) => {
                                                                        handleInputChange(index, value);
                                                                    }}
                                                                    error={error}
                                                                    setError={setError}
                                                                />
                                                            </>
                                                        )
                                                    })}

                                                    {formData?.formButton &&
                                                        <>
                                                            <div className='form_url_container__formButton_container' style={{
                                                                justifyContent: formData?.formButton?.alignment ? formData?.formButton?.alignment : 'center',
                                                                paddingBlock: 16,
                                                            }}>
                                                                <CheerioButton
                                                                    borderStyle={{
                                                                        borderColor: formData?.formButton?.buttonColor ? formData?.formButton?.buttonColor : '#835EF7',
                                                                        paddingBlock: 10,
                                                                        paddingInline: 30,
                                                                    }}
                                                                    backColor={formData?.formButton?.buttonColor ? formData?.formButton?.buttonColor : '#835EF7'}
                                                                    textStyle={{ color: colors.white, fontSize: 16, formWeight: 500 }}
                                                                    btnText={submitting ? "Submitting" : (formData?.formButton?.buttonText ? formData?.formButton?.buttonText : 'Submit')}
                                                                    disabled={(disableButton || submitting || error) ? true : false}
                                                                    onclick={() => {
                                                                        submitFormAction();
                                                                        console.log("submit clicked ... ")
                                                                        !submitting && window.parent.postMessage({ success: true }, "*");
                                                                        // navigate('/creator/embeddableForms/create');
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    }

                                                    {formData?.watermark?.watermark &&
                                                        <>
                                                            <div className='d-flex flex-row justify-content-center align-items-center w-100' style={{ paddingBlock: 16 }}>
                                                                <img src={images.BoltYellow} style={{
                                                                    height: 16,
                                                                    width: 16,
                                                                    objectFit: 'contain'
                                                                }}></img>
                                                                <p style={{ marginInlineStart: 8 }}>
                                                                    {'Powered by CheerioAI'}
                                                                </p>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className='form_url_container' style={{
                                                    backgroundColor: formData?.formProperties?.bodyColor ? formData?.formProperties?.bodyColor : colors.white01,
                                                }}>
                                                    {formData?.formName &&
                                                        <div className='w-100' style={{
                                                            marginBlock: 16,
                                                        }}>
                                                            <p style={{
                                                                width: '100%',
                                                                formWeight: 600,
                                                                fontSize: 30
                                                            }}>
                                                                {formData?.formName}
                                                            </p>
                                                        </div>
                                                    }
                                                    <div className='w-100' style={{
                                                        marginBlock: 16,
                                                    }}>
                                                        <p style={{
                                                            width: '100%',
                                                            fontSize: 20
                                                        }}>
                                                            {'This form is no-longer accepting responses. Try contacting form owner'}
                                                        </p>
                                                    </div>
                                                    {formData?.watermark?.watermark &&
                                                        <>
                                                            <div className='d-flex flex-row justify-content-center align-items-center w-100' style={{ paddingBlock: 16 }}>
                                                                <img src={images.BoltYellow} style={{
                                                                    height: 16,
                                                                    width: 16,
                                                                    objectFit: 'contain'
                                                                }}></img>
                                                                <p style={{ marginInlineStart: 8 }}>
                                                                    {'Powered by CheerioAI'}
                                                                </p>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        )}

                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
                                    <h3>
                                        {'No such form exists, please check the url entered'}
                                    </h3>
                                </div>
                            </>
                        )}
                    </>
                )}

            </div>
        </>
    )
}

export default FormURL