import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styledSx } from '../ContactModule/Tags/variableDependencies';
import { useQuery } from '@tanstack/react-query';
import { workFlowCols } from './variableDependencies';
import { fetchAllWorkflows, getShopifyDetailsApi, getUserApi, ExportWorkflowFile, ImportWorkflowfile } from '../../Services';
import { useDispatch, useSelector } from 'react-redux';
import { timeStampToDated } from '../ContactModule/Labels/variableDependencies';
import { useNavigate } from 'react-router-dom';
import { toggleWorkflowService } from '../../Services/index';
import { ToastContainer, toast } from 'react-toastify';
import BetaFeatureBanner from '../../Components/BetaFeatureBanner';
import colors from '../../Utils/colors';
import CheerioButton from '../../Components/CheerioButton';
import { eventsend } from '../../Config/analyticsFunctions';
import Helper from '../../Utils/Helper';
import { trackPages } from '../../Utils/brevo';
import SpinnerLoader from '../../Components/SpinnerLoader';
import images from '../../Utils/images';
import styles from '../CreatorNew/styles.module.css';
import ImportWorkflow from './ImportWorkFlow'
import SyncWorkflow from './SyncWorkflow';
const WorkflowTabels = () => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const isChecked = useSelector((state) => state.main?.localdata?.whatsapp_credentials?.workflow);
  console.log('isChecked ---> ', isChecked);
  const [isCheck, setIsCheck] = useState(isChecked);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [existingShopifyFlow, setExistingShopifyFlow] = useState(false);
  const isPremiumUser = localdata?.premium;
  const [openModal, setOpenModal] = useState(false);
  const trigger = useSelector((state) => state.labelRedux.triggerState);
  const agentdata = useSelector((state) => state.main.agentdata);
  const [OpenExportWorkflowModal, setOpenExportWorkflowModal] = useState(false);
  const [openMailCSVModal, setOpenMailCSVModal] = useState(false);
  const [openSyncModal, setOpenSyncModal] = useState(false)
  const { data, isLoading, isError } = useQuery(['workflowsGet', trigger], () =>
    fetchAllWorkflows(authtoken)
  );

  const specialID =
    localdata?._id === '6672c6a1a0f90ae9bdeac259' ||
    localdata?._id === '6631f75cfc273ab3fc8251f6' ||
    localdata?._id === '662faa06fc273ab3fc6c2e78' ||
    localdata?._id === '65ee7dd6362208d14232e2da' ||
    localdata?._id === '6489675b4f598e055ed74e34' ||
    localdata?._id ===  '660e878b6d6015c4e49b0434';
  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        console.log('error get user api ===> ', res);
      }
    });
  };

  const getShopifyDetailsAPIcall = () => {
    let token = authtoken;
    getShopifyDetailsApi(token).then((res) => {
      if (res?.flag) {
        setExistingShopifyFlow(res?.data?.result);
      } else {
        // console.log("no shopify details exist ===> ",res)
      }
    });
  };
  useEffect(() => {
    // eventsend('workflow_tables_pageview_web');
    trackPages('automation_workflow_page', localdata);
    if (agentdata && !agentdata?.permission?.workflow) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
    getuserapicall();
    getShopifyDetailsAPIcall();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <SpinnerLoader />
      </div>
    );
  }

  const toggleWorkflow = () => {
    eventsend('Global_toggle_Workflows_page_clicked');
    let token = authtoken;
    toggleWorkflowService(token).then((res) => {
      if (res.data) {
        console.log('res.data.workflow', res?.data?.workflow);
        setIsCheck(res?.data?.workflow);
        console.log('success response ===> ', res);
        getuserapicall();
        toast.success(`Succesfully ${res?.data?.workflow ? 'Enabled' : 'Disabled'} Workflow`, {
          position: 'top-center',

          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        console.log('error response ===> ', res);
        toast(`Error occured while saving settings. Please try again later`, {
          position: 'top-center',
        });
      }
    });
  };

  // useEffect(()=>{
  //     if(data){
  //         console.log("fetch workflow ===> ",data)
  //     }
  // },[data])
  let connection_limit =
    localdata?._id === '66c5a2b2b8dfa0be92acd03e'
      ? 1000
      : (localdata?.addOnSubscriptionActive || localdata?._id === '667e97a55b3096422a842159'
        ? 250
        : 50);

  let connection_count = 0;
  data.data.forEach((vls) => {
    connection_count += vls?.edges?.length;
    Helper.ConnectionCounter = connection_count;
  });

  let row_data = [];
  // const defaultData = {
  //   data: [
  //     {
  //       kylasDetails: {
  //         attributeList: [],
  //       },
  //       _id: '656864b2dbd034fe17c5bf8c',
  //       userId: '6489675b4f598e055ed74e34',
  //       nodes: [
  //         {
  //           id: '0d13efac-3c36-4f5b-a92c-c9c475236988',
  //           type: 'incomingWA',
  //           position: {
  //             x: -512.9623323636033,
  //             y: 483.6952094545089,
  //           },
  //           data: {
  //             label: 'incomingWA node',
  //           },
  //           width: 250,
  //           height: 83,
  //           selected: true,
  //           positionAbsolute: {
  //             x: -512.9623323636033,
  //             y: 483.6952094545089,
  //           },
  //           dragging: false,
  //         },
  //         {
  //           id: 'df761914-5ecb-4a7e-a896-7a5ee912abff',
  //           type: 'responseMessage',
  //           position: {
  //             x: -73.59336621839748,
  //             y: 258.04001596315067,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: '',
  //               mediaFile: '',
  //             },
  //             body: {
  //               message: 'Thanks for reaching out to Cheerio. Let us know your query.',
  //             },
  //             options: [
  //               {
  //                 InputValue: 'Whatsapp marketing',
  //               },
  //               {
  //                 InputValue: 'I need SMS marketing',
  //               },
  //               {
  //                 InputValue: 'I need email marketing',
  //               },
  //               {
  //                 InputValue: 'Talk to an agent',
  //               },
  //             ],
  //           },
  //           width: 250,
  //           height: 499,
  //           selected: false,
  //           dragging: false,
  //           positionAbsolute: {
  //             x: -73.59336621839748,
  //             y: 258.04001596315067,
  //           },
  //         },
  //         {
  //           id: '322d0ecf-1748-46b3-b354-7fad6108dfc1',
  //           type: 'responseMessage',
  //           position: {
  //             x: 551.5204422302637,
  //             y: -18.87481462099123,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: 'WhatsApp Image 2023-11-17 at 17.12.55 (2).jpeg',
  //               mediaFile:
  //                 'https://cheerio-prod.s3.ap-south-1.amazonaws.com/5cc995d92a7zz3u6p2e6om.jpeg',
  //             },
  //             body: {
  //               message:
  //                 'We are a Meta certified Whatsapp Business Partner who can help you develop an effective Whatsapp marketing strategy. Want to know more? Book a consultation.',
  //             },
  //             options: [
  //               {
  //                 InputValue: 'Chat on Whatsapp',
  //               },
  //               {
  //                 InputValue: 'Request callback',
  //               },
  //             ],
  //             variable: '@new_var1',
  //           },
  //           width: 250,
  //           height: 534,
  //           selected: false,
  //           dragging: false,
  //           positionAbsolute: {
  //             x: 551.5204422302637,
  //             y: -18.87481462099123,
  //           },
  //         },
  //         {
  //           id: '26078ea0-f16b-41e9-b3f3-35b3c46a9b42',
  //           type: 'responseMessage',
  //           position: {
  //             x: 1034.2665382146288,
  //             y: 378.0057373395159,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: '',
  //               mediaFile: '',
  //             },
  //             body: {
  //               message:
  //                 'We have directly partnered with Airtel to serve you with best in-class bulk SMS service. Would you like to know more about our SMS offerings?',
  //             },
  //             options: [
  //               {
  //                 InputValue: 'Yes, I need expert consultation',
  //               },
  //               {
  //                 InputValue: 'Call me back',
  //               },
  //             ],
  //           },
  //           width: 250,
  //           height: 423,
  //           selected: false,
  //           dragging: false,
  //           positionAbsolute: {
  //             x: 1034.2665382146288,
  //             y: 378.0057373395159,
  //           },
  //         },
  //         {
  //           id: '1893f9c0-049d-4f8c-823e-d3ca5eaa2b13',
  //           type: 'responseMessage',
  //           position: {
  //             x: 628.6714544159913,
  //             y: 627.0280138135495,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: '',
  //               mediaFile: '',
  //             },
  //             body: {
  //               message:
  //                 'Our email service is one of the best in the world and we guarantee you 99.6% deliverability and less than 10% spam. Would you like to know more?',
  //             },
  //             options: [
  //               {
  //                 InputValue: 'Yes, I want a demo',
  //               },
  //               {
  //                 InputValue: 'Give me a call back',
  //               },
  //             ],
  //           },
  //           width: 250,
  //           height: 423,
  //           selected: false,
  //           positionAbsolute: {
  //             x: 628.6714544159913,
  //             y: 627.0280138135495,
  //           },
  //           dragging: false,
  //         },
  //         {
  //           id: '8f5e5122-0195-4d03-abe2-6da67d44a686',
  //           type: 'responseMessage',
  //           position: {
  //             x: 310.4660822973894,
  //             y: 801.7711284013168,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: '',
  //               mediaFile: '',
  //             },
  //             body: {
  //               message:
  //                 'Sure, our expert will call you back within 24 hours on your Whatsapp number',
  //             },
  //             options: [],
  //           },
  //           width: 250,
  //           height: 271,
  //           selected: false,
  //           dragging: false,
  //           positionAbsolute: {
  //             x: 310.4660822973894,
  //             y: 801.7711284013168,
  //           },
  //         },
  //         {
  //           id: '5e01f830-4783-439c-aea1-ed37bf76a237',
  //           type: 'responseMessage',
  //           position: {
  //             x: 1028.2380429588518,
  //             y: -56.7503607070193,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: '',
  //               mediaFile: '',
  //             },
  //             body: {
  //               message: 'Sure, our expert will call you back within 24 hours.',
  //             },
  //             options: [],
  //           },
  //           width: 250,
  //           height: 271,
  //           selected: false,
  //           positionAbsolute: {
  //             x: 1028.2380429588518,
  //             y: -56.7503607070193,
  //           },
  //           dragging: false,
  //         },
  //         {
  //           id: 'b503ae04-0dbc-40f0-a5c4-9254b206fa97',
  //           type: 'responseMessage',
  //           position: {
  //             x: 1300.0669531399851,
  //             y: 183.92887140671235,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: '',
  //               mediaFile: '',
  //             },
  //             body: {
  //               message: 'Sure, our expert will call you within 24 hours.',
  //             },
  //             options: [],
  //           },
  //           width: 250,
  //           height: 271,
  //           selected: false,
  //           positionAbsolute: {
  //             x: 1300.0669531399851,
  //             y: 183.92887140671235,
  //           },
  //           dragging: false,
  //         },
  //         {
  //           id: '96dad6cf-4569-4f8d-87eb-859196c62667',
  //           type: 'responseMessage',
  //           position: {
  //             x: 1463.8437640840607,
  //             y: 477.2428396181281,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: '',
  //               mediaFile: '',
  //             },
  //             body: {
  //               message: 'Sure, our expert will call you back within 24 hours.',
  //             },
  //             options: [],
  //           },
  //           width: 250,
  //           height: 271,
  //           selected: false,
  //           dragging: false,
  //           positionAbsolute: {
  //             x: 1463.8437640840607,
  //             y: 477.2428396181281,
  //           },
  //         },
  //         {
  //           id: 'b7bb6c91-6986-484c-8911-02be4778b12f',
  //           type: 'responseMessage',
  //           position: {
  //             x: 1526.4320010475342,
  //             y: 830.8144921710409,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: '',
  //               mediaFile: '',
  //             },
  //             body: {
  //               message: 'Sure, our expert will call you back within 24 hours.',
  //             },
  //             options: [],
  //           },
  //           width: 250,
  //           height: 271,
  //           selected: false,
  //           dragging: false,
  //           positionAbsolute: {
  //             x: 1526.4320010475342,
  //             y: 830.8144921710409,
  //           },
  //         },
  //         {
  //           id: 'e8e35b2e-ea1f-4267-89b6-7d10ac939af6',
  //           type: 'responseMessage',
  //           position: {
  //             x: 1136.7279393423987,
  //             y: 846.3037926582073,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: '',
  //               mediaFile: '',
  //             },
  //             body: {
  //               message:
  //                 'Sure, our expert will schedule a demo with you within 24 hours. You can use this link to book a demo: calendly.com/avinash.com',
  //             },
  //             options: [],
  //             variable: '',
  //           },
  //           width: 250,
  //           height: 271,
  //           selected: false,
  //           dragging: false,
  //           positionAbsolute: {
  //             x: 1136.7279393423987,
  //             y: 846.3037926582073,
  //           },
  //         },
  //         {
  //           id: '1d20cc19-a3db-48bd-9c18-6b82752257eb',
  //           type: 'responseMessage',
  //           position: {
  //             x: 1031,
  //             y: 1148.8853210449217,
  //           },
  //           data: {
  //             label: 'responseMessage node',
  //             header: {
  //               mediaType: 'Image',
  //               mediaName: '',
  //               mediaFile: '',
  //             },
  //             body: {
  //               message: 'Sure, our expert will call you back within 24 hours.',
  //             },
  //             options: [],
  //           },
  //           width: 250,
  //           height: 271,
  //           selected: false,
  //           positionAbsolute: {
  //             x: 1031,
  //             y: 1148.8853210449217,
  //           },
  //           dragging: false,
  //         },
  //         {
  //           id: 'ab139c27-4225-4b5b-bbde-94c473a6d288',
  //           type: 'timeDelay',
  //           position: {
  //             x: 1588.6776231706112,
  //             y: 1266.7683086719694,
  //           },
  //           data: {
  //             label: 'timeDelay node',
  //             hours: '2',
  //             minutes: '00',
  //             executeNextScheduler: false,
  //           },
  //           width: 230,
  //           height: 107,
  //           selected: false,
  //           positionAbsolute: {
  //             x: 1588.6776231706112,
  //             y: 1266.7683086719694,
  //           },
  //           dragging: false,
  //         },
  //         {
  //           id: 'bf828207-b880-4dd3-bf51-dee6aedb1e07',
  //           type: 'sendEmail',
  //           position: {
  //             x: 2105.4461651463084,
  //             y: 1417.1960232672,
  //           },
  //           data: {
  //             label: 'sendEmail node',
  //             emailMode: 'Email Builder',
  //             selectedTemplate: '64d46346c199fa92bb220b70',
  //             selectedTemplateName: 'Sales demo',
  //             subject: 'Test email',
  //           },
  //           width: 250,
  //           height: 252,
  //           selected: false,
  //           positionAbsolute: {
  //             x: 2105.4461651463084,
  //             y: 1417.1960232672,
  //           },
  //           dragging: false,
  //         },
  //         {
  //           id: '5f4693c7-2b18-4ca3-abc6-6bbbdf5b4a94',
  //           type: 'assignAgent',
  //           position: {
  //             x: 1946.7222582401753,
  //             y: 572.7407202264533,
  //           },
  //           data: {
  //             label: 'assignAgent node',
  //           },
  //           width: 230,
  //           height: 117,
  //           selected: false,
  //           positionAbsolute: {
  //             x: 1946.7222582401753,
  //             y: 572.7407202264533,
  //           },
  //           dragging: false,
  //         },
  //         {
  //           id: '5305fa38-9049-4f86-af52-9e01725d7f76',
  //           type: 'assignChatbot',
  //           position: {
  //             x: 1984.7754551894486,
  //             y: 996.2144094348613,
  //           },
  //           data: {
  //             label: 'assignChatbot node',
  //           },
  //           width: 230,
  //           height: 135,
  //           selected: false,
  //           positionAbsolute: {
  //             x: 1984.7754551894486,
  //             y: 996.2144094348613,
  //           },
  //           dragging: false,
  //         },
  //         {
  //           id: 'f507ab3e-4a01-4036-a820-7fb08a546d61',
  //           type: 'assignAgent',
  //           position: {
  //             x: 1676.169953512234,
  //             y: 341.96114509376696,
  //           },
  //           data: {
  //             label: 'assignAgent node',
  //           },
  //           width: 230,
  //           height: 117,
  //           selected: false,
  //           positionAbsolute: {
  //             x: 1676.169953512234,
  //             y: 341.96114509376696,
  //           },
  //           dragging: false,
  //         },
  //         {
  //           id: '7764caa7-d763-48f8-87c4-9cad83079774',
  //           type: 'assignChatbot',
  //           position: {
  //             x: 1525.222390921313,
  //             y: 4.734106117432347,
  //           },
  //           data: {
  //             label: 'assignChatbot node',
  //           },
  //           width: 230,
  //           height: 135,
  //           selected: false,
  //           positionAbsolute: {
  //             x: 1525.222390921313,
  //             y: 4.734106117432347,
  //           },
  //           dragging: false,
  //         },
  //       ],
  //       edges: [
  //         {
  //           source: 'df761914-5ecb-4a7e-a896-7a5ee912abff',
  //           sourceHandle: 'child_node_1',
  //           target: '26078ea0-f16b-41e9-b3f3-35b3c46a9b42',
  //           targetHandle: null,
  //           id: 'reactflow__edge-df761914-5ecb-4a7e-a896-7a5ee912abffchild_node_1-26078ea0-f16b-41e9-b3f3-35b3c46a9b42',
  //         },
  //         {
  //           source: 'df761914-5ecb-4a7e-a896-7a5ee912abff',
  //           sourceHandle: 'child_node_2',
  //           target: '1893f9c0-049d-4f8c-823e-d3ca5eaa2b13',
  //           targetHandle: null,
  //           id: 'reactflow__edge-df761914-5ecb-4a7e-a896-7a5ee912abffchild_node_2-1893f9c0-049d-4f8c-823e-d3ca5eaa2b13',
  //         },
  //         {
  //           source: 'df761914-5ecb-4a7e-a896-7a5ee912abff',
  //           sourceHandle: 'child_node_3',
  //           target: '8f5e5122-0195-4d03-abe2-6da67d44a686',
  //           targetHandle: null,
  //           id: 'reactflow__edge-df761914-5ecb-4a7e-a896-7a5ee912abffchild_node_3-8f5e5122-0195-4d03-abe2-6da67d44a686',
  //         },
  //         {
  //           source: '322d0ecf-1748-46b3-b354-7fad6108dfc1',
  //           sourceHandle: 'child_node_1',
  //           target: 'b503ae04-0dbc-40f0-a5c4-9254b206fa97',
  //           targetHandle: null,
  //           id: 'reactflow__edge-322d0ecf-1748-46b3-b354-7fad6108dfc1child_node_1-b503ae04-0dbc-40f0-a5c4-9254b206fa97',
  //         },
  //         {
  //           source: '26078ea0-f16b-41e9-b3f3-35b3c46a9b42',
  //           sourceHandle: 'child_node_0',
  //           target: '96dad6cf-4569-4f8d-87eb-859196c62667',
  //           targetHandle: null,
  //           id: 'reactflow__edge-26078ea0-f16b-41e9-b3f3-35b3c46a9b42child_node_0-96dad6cf-4569-4f8d-87eb-859196c62667',
  //         },
  //         {
  //           source: '26078ea0-f16b-41e9-b3f3-35b3c46a9b42',
  //           sourceHandle: 'child_node_1',
  //           target: 'b7bb6c91-6986-484c-8911-02be4778b12f',
  //           targetHandle: null,
  //           id: 'reactflow__edge-26078ea0-f16b-41e9-b3f3-35b3c46a9b42child_node_1-b7bb6c91-6986-484c-8911-02be4778b12f',
  //         },
  //         {
  //           source: '1893f9c0-049d-4f8c-823e-d3ca5eaa2b13',
  //           sourceHandle: 'child_node_0',
  //           target: 'e8e35b2e-ea1f-4267-89b6-7d10ac939af6',
  //           targetHandle: null,
  //           id: 'reactflow__edge-1893f9c0-049d-4f8c-823e-d3ca5eaa2b13child_node_0-e8e35b2e-ea1f-4267-89b6-7d10ac939af6',
  //         },
  //         {
  //           source: '1893f9c0-049d-4f8c-823e-d3ca5eaa2b13',
  //           sourceHandle: 'child_node_1',
  //           target: '1d20cc19-a3db-48bd-9c18-6b82752257eb',
  //           targetHandle: null,
  //           id: 'reactflow__edge-1893f9c0-049d-4f8c-823e-d3ca5eaa2b13child_node_1-1d20cc19-a3db-48bd-9c18-6b82752257eb',
  //         },
  //         {
  //           source: 'ab139c27-4225-4b5b-bbde-94c473a6d288',
  //           sourceHandle: null,
  //           target: 'bf828207-b880-4dd3-bf51-dee6aedb1e07',
  //           targetHandle: null,
  //           id: 'reactflow__edge-ab139c27-4225-4b5b-bbde-94c473a6d288-bf828207-b880-4dd3-bf51-dee6aedb1e07',
  //         },
  //         {
  //           source: '0d13efac-3c36-4f5b-a92c-c9c475236988',
  //           sourceHandle: 'Event_UserInit',
  //           target: 'df761914-5ecb-4a7e-a896-7a5ee912abff',
  //           targetHandle: null,
  //           id: 'reactflow__edge-0d13efac-3c36-4f5b-a92c-c9c475236988Event_UserInit-df761914-5ecb-4a7e-a896-7a5ee912abff',
  //         },
  //         {
  //           source: '96dad6cf-4569-4f8d-87eb-859196c62667',
  //           sourceHandle: null,
  //           target: '5f4693c7-2b18-4ca3-abc6-6bbbdf5b4a94',
  //           targetHandle: null,
  //           id: 'reactflow__edge-96dad6cf-4569-4f8d-87eb-859196c62667-5f4693c7-2b18-4ca3-abc6-6bbbdf5b4a94',
  //         },
  //         {
  //           source: 'b503ae04-0dbc-40f0-a5c4-9254b206fa97',
  //           sourceHandle: null,
  //           target: 'f507ab3e-4a01-4036-a820-7fb08a546d61',
  //           targetHandle: null,
  //           id: 'reactflow__edge-b503ae04-0dbc-40f0-a5c4-9254b206fa97-f507ab3e-4a01-4036-a820-7fb08a546d61',
  //         },
  //         {
  //           source: 'b7bb6c91-6986-484c-8911-02be4778b12f',
  //           sourceHandle: null,
  //           target: '5305fa38-9049-4f86-af52-9e01725d7f76',
  //           targetHandle: null,
  //           id: 'reactflow__edge-b7bb6c91-6986-484c-8911-02be4778b12f-5305fa38-9049-4f86-af52-9e01725d7f76',
  //         },
  //         {
  //           source: '322d0ecf-1748-46b3-b354-7fad6108dfc1',
  //           sourceHandle: 'child_node_0',
  //           target: '5e01f830-4783-439c-aea1-ed37bf76a237',
  //           targetHandle: null,
  //           id: 'reactflow__edge-322d0ecf-1748-46b3-b354-7fad6108dfc1child_node_0-5e01f830-4783-439c-aea1-ed37bf76a237',
  //         },
  //         {
  //           source: 'df761914-5ecb-4a7e-a896-7a5ee912abff',
  //           sourceHandle: 'child_node_0',
  //           target: '322d0ecf-1748-46b3-b354-7fad6108dfc1',
  //           targetHandle: null,
  //           id: 'reactflow__edge-df761914-5ecb-4a7e-a896-7a5ee912abffchild_node_0-322d0ecf-1748-46b3-b354-7fad6108dfc1',
  //         },
  //         {
  //           source: '5e01f830-4783-439c-aea1-ed37bf76a237',
  //           sourceHandle: null,
  //           target: '7764caa7-d763-48f8-87c4-9cad83079774',
  //           targetHandle: null,
  //           id: 'reactflow__edge-5e01f830-4783-439c-aea1-ed37bf76a237-7764caa7-d763-48f8-87c4-9cad83079774',
  //         },
  //         {
  //           source: '1d20cc19-a3db-48bd-9c18-6b82752257eb',
  //           sourceHandle: null,
  //           target: 'ab139c27-4225-4b5b-bbde-94c473a6d288',
  //           targetHandle: null,
  //           id: 'reactflow__edge-1d20cc19-a3db-48bd-9c18-6b82752257eb-ab139c27-4225-4b5b-bbde-94c473a6d288',
  //         },
  //       ],
  //       name: 'Flow 1 : Text response Cheerio (Dont Delete)',
  //       status: 'active',
  //       createdAt: '2023-11-30T10:32:18.961Z',
  //       updatedAt: '2024-06-07T05:13:03.308Z',
  //       __v: 0,
  //       defaultWorkflowNode: '0d13efac-3c36-4f5b-a92c-c9c475236988',
  //       shopify: false,
  //       campaignType: false,
  //     },
  //   ],
  // };

  // if (data?.data.length === 0 && !isPremiumUser) {
  //   defaultData.data.forEach((vls) => {
  //     row_data.push({
  //       id: vls._id,
  //       name: vls.name,
  //       nodeType: vls?.nodes[0]?.type,
  //       existingShopifyFlow: existingShopifyFlow,
  //       fbAdId: null,
  //       last_updated: timeStampToDated(vls.updatedAt),
  //       created_at: timeStampToDated(vls.createdAt),
  //     });
  //   });
  // }
  data.data.forEach((vls) => {
    let nodeType;
    if (vls?.nodes[0]?.type === 'shopify') {
      nodeType = 'Shopify ' + (vls?.nodes[0]?.data?.shopifyEvent || '');
    } else if (vls?.nodes[0]?.type === 'wooWFNode') {
      nodeType = 'Woo ' + (vls?.nodes[0]?.data?.wooEvent || '');
    } else {
      nodeType = vls?.nodes[0]?.type;
    }
    row_data.push({
      id: vls._id,
      name: vls.name,
      nodeType: nodeType,
      existingShopifyFlow: existingShopifyFlow,
      fbAdId: vls?.nodes[0]?.type === 'facebookLead' ? vls?.nodes[0]?.data?.adId : null,
      formId: vls?.nodes[0]?.type === 'formsWFNode' ? vls?.nodes[0]?.data?.formId : null,
      last_updated: timeStampToDated(vls.updatedAt),
      created_at: timeStampToDated(vls.createdAt),
      status: vls.status,
    });
  });
  const handleClick = (param, event) => {
    console.log('param', param);
    param.field === 'actions' && event.stopPropagation();
  };

  const handleQRCodeClick = () => {
    setOpenModal(true);
  };


  // QRModal component
  const QRModal = () => {
    return (
      <div className="ModalDarkBg">
        <div className="Center">
          <div
            className="announcement_modal"
            style={{
              width: '55vh',
              backgroundColor: colors.primary03,
              borderColor: colors.primary03,
            }}
          >
            <div className="flex items-center justify-center w-100 text-white">
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <img
                  src={images.CMCloseWhite}
                  style={{ height: '28px', width: '28px', cursor: 'pointer' }}
                  onClick={() => setOpenModal(false)} // Corrected this line
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-between w-100">
                <div>
                  <img
                    src={images.WFQRCode}
                    style={{
                      margin: '10px',
                      border: '20px solid white',
                      borderRadius: '20px',
                      backgroundColor: colors.borderwhite,
                    }}
                  />
                </div>
                <p style={{ margin: '10px', fontWeight: '600', fontSize: '20px' }}>
                  Scan to test our WhatsApp Chatbot
                </p>
                <p style={{ margin: '10px', fontWeight: '800', fontSize: '24px' }}>"Efyd1"</p>
                <p style={{ fontWeight: '400', fontSize: '20px' }}>Send this as a message</p>
                <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '20px',
                      fontWeight: '400',
                      fontSize: '20px',
                      gap: '8px',
                    }}
                  >
                    {'Powered by '}
                    <img src={images.WFCheerio} style={{ height: '25px', marginTop: '6px' }} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DownloadFile = async () => {
    try {
      let Response = await ExportWorkflowFile(authtoken);
      if (Response.status === 200) {
        const response = Response.data;

        if (!response || !(response instanceof Blob)) {
          console.error('Invalid response received from API.');
          return;
        }
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'workflow_export.json');

        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(url);
        toast.success('File downloaded successfully!');
      } else if (Response.status === 500) {
        toast.error(Response.statusText || 'Failed to download file. Please try again');
      } else {
        console.error(`Unexpected status code: ${Response.status}`);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Failed to download file. Please try again.');
    }
  };



  return (
    <>
      <WorkflowTableDiv>
        {/* Banner */}
        {/* <div
        className={`${styles['Row']} ${styles['BannerContainer']}`}
        style={{ backgroundColor: colors.yellow03, width: '100%' }}
      >
        <img src={images.InfoCircle} style={{ height: 24, width: 24, objectFit: 'contain' }}></img>
        <p
          style={{
            flex: 1,
            margin: 0,
            paddingBlock: 0,
            fontSize: 16,
            fontWeight: 400,
            color: colors.black,
            paddingInline: 16,
            textAlign: 'justify',
          }}
        >
          {
            'Workflows may have been affected for your account. Please recheck and re-enable workflows for your account and cross verify shopify/woocommerce settings. Inconvenience is regretted.'
          }
        </p>
      </div> */}
        <div className="tableHeader">
          <div className="d-flex flex-row justify-content-start align-items-center">
            <h3 style={{ color: colors.black }}>{'Workflows'}</h3>
            <div className="form-check form-switch " style={{ marginLeft: '16px' }}>
              <input
                className="form-check-input shadow-none"
                style={{}}
                type="checkbox"
                role="switch"
                checked={!isPremiumUser ? true : isCheck}
                id="flexSwitchCheckDefault"
                onChange={(e) => (e.target.checked ? toggleWorkflow() : toggleWorkflow())}
              ></input>
            </div>
          </div>
          {localdata?.isAppsumoUser && localdata?._id !== '664cf714f3e3a40203785e71' && (
            <>
              <div
                className="d-flex flex-row justify-content-center align-items-center py-1 px-3 ms-auto me-2"
                style={{ borderRadius: 8, backgroundColor: colors.white04 }}
              >
                <p style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                  {'Connector limit: '}
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: connection_count >= connection_limit ? colors.error03 : colors.black,
                    }}
                  >
                    {connection_count}
                  </span>
                  {`/${connection_limit} used`}
                </p>
                <CheerioButton
                  borderStyle={{
                    border: `1px solid ${colors.primary03}`,
                    marginInlineStart: 8,
                    paddingInline: 8,
                    paddingBlock: 4,
                    borderRadius: 8,
                  }}
                  textStyle={{ color: colors.white, fontSize: 12 }}
                  btnText={'Upgrade'}
                  backColor={colors.primary03}
                  onclick={() => {
                    navigate('/addons');
                    console.log('upgrade clicked');
                  }}
                />
              </div>
            </>
          )}
          <div
            style={{
              display: 'flex',
              gap: '10px',
              marginLeft:
                localdata?.isAppsumoUser && localdata?._id !== '664cf714f3e3a40203785e71'
                  ? '10px'
                  : (specialID ? '580px' : '1010px'),
              marginRight: '8px'
            }}
          >
            {specialID && (
              <CheerioButton
                borderStyle={{
                  borderRadius: '10px'
                }}
                textStyle={{
                  color: '#000000',
                  fontWeight: 600,
                  fontSize: '14px',
                }}
                btnText={'Sync'}
                icon={images.SYNC_ICON}
                iconStyle={{
                  height: 24,
                  width: 24,
                }}
                onclick={() => setOpenSyncModal(true)}
              />
            )}
            <CheerioButton
              borderStyle={{
                borderRadius: '10px'
              }}
              textStyle={{
                color: '#000000',
                fontWeight: 600,
                fontSize: '14px',
              }}
              btnText="Import"
              icon={images.IMPORTICON}
              iconStyle={{
                height: 24,
                width: 24,
              }}
              onclick={() => setOpenMailCSVModal(true)}
            />
            <CheerioButton
            borderStyle={{
              borderRadius: '10px'
            }}
              textStyle={{
                color: '#000000',
                fontSize: '14px',
                fontWeight: 600,
              }}
              btnText={'Export'}
              icon={images.FILEEXPORT}
              iconStyle={{
                height: 24,
                width: 24,
              }}
              onclick={() => {
                DownloadFile();
              }}
            />
          <CheerioButton
            borderStyle={{
              border: `1px solid ${colors.darkPurple}`,
            }}
            textStyle={{ color: colors.white02 }}
            btnText={'Add flow'}
            backColor={colors.darkPurple}
            onclick={() => {
              eventsend('Add_flow_Workflows_page_clicked');
              navigate('/workflow');
            }}
          />
          </div>
        </div>
        <div className="w-100" style={{ flex: 1 }}>
          {/* {!isPremiumUser && (
          <div
            className="d-flex flex-row"
            style={{
              width: '100%',
              paddingTop: '10px',
              paddingBottom: '10px',
              marginTop: '10px',
              marginBottom: '10px',
              backgroundColor: colors.primary01,
              textAlign: 'center',
              justifyContent: 'center',
              borderRadius: 10,
              borderWidth: 0,
              borderStyle: 'solid',
              borderColor: 'none',
              gap: '2px',
            }}
          >
            <img
              src={images.InfoCircle}
              alt="WA Inbox information"
              style={{
                height: 22,
                width: 24,
                objectFit: 'contain',
              }}
            />
            <p style={{ fontWeight: 500 }}>
              {'To test how a WhatsApp chat bot works, Scan this '}
              <span
                style={{ color: colors.blue03, textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleQRCodeClick}
              >
                QR Code
              </span>
              {' or send "Efyd1" to +91 8328678321.'}
            </p>
          </div>
        )}
        {openModal && <QRModal />} */}

          <DataGrid
            columns={workFlowCols}
            sx={styledSx}
            pageSizeOptions={[]}
            disableColumnMenu={true}
            rows={row_data}
            onCellClick={handleClick}
          />
        </div>
      </WorkflowTableDiv>
      {openMailCSVModal && (
        <ImportWorkflow
          show={openMailCSVModal}
          setShow={setOpenMailCSVModal}
          apiAction={(payload, token) => ImportWorkflowfile(payload, token)}
          title="Import Workflows"
        />
      )}

      {openSyncModal && (
        <SyncWorkflow
          show={openSyncModal}
          setShow={setOpenSyncModal}
        />
      )}
    </>
  );
};
export default WorkflowTabels;

const WorkflowTableDiv = styled.div`
  padding-block: 30px;
  padding-inline: 40px;
  width: 100%;
  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    span {
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 700;
    }
    button {
      appearance: none;
      padding: 4px 32px;
      border: #000000 1px solid;
      background: none;
      border-radius: 10px;
    }
  }
`;
