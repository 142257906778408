import { DataGrid } from '@mui/x-data-grid';
// import { ContactsDepsCols } from './variableDependencies';
import { styledSx } from '../Tags/variableDependencies';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { fetchAllContacts, filterContacts, AddEditcustomAttributes, bulkExport, bulkDelete, bulkCopy, bulkUpdate, V2AgentGet, updateContactLead } from '../../../Services';
import { timeStampToDated } from '../Labels/variableDependencies';
import { Route, Routes } from 'react-router-dom';
import EditSidebar from '../EditSidebar';
import { useEffect, useState, useRef } from 'react';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { v4 as uuidv4 } from 'uuid';
import { trackPages } from '../../../Utils/brevo';
import { getLocaltime } from '../../../Utils/localdate'
import { options } from 'linkifyjs';
import Checkbox from '@mui/material/Checkbox';
import CheerioDropDown from '../../../Components/CheerioDropDown';
import { RenderEmailRow, RenderNameRow, RenderPhnoRow, EditableCell } from './variableDependencies';
import { toast } from 'react-toastify';
import { formatDateForInput, formatTimestampForInput, formatTimeForDisplay, formatDateForDisplay, formatTimestampForDisplay, formatTimeForInput } from '../../../Utils/formatConvertor';
import images from '../../../Utils/images';
import { Form, InputGroup } from 'react-bootstrap';
import { Select, MenuItem, Tooltip, Typography } from '@mui/material';


import colors from '../../../Utils/colors';
import CheerioButton from '../../../Components/CheerioButton';
import DateTimeInput from '../../../Components/DateTimeInput';

const ContactsAll = ({ search, filter, setFilter, allData, setAllData, userAttributes, excepted, andCondition }) => {
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const trigger = useSelector((state) => state.labelRedux.triggerState);
  const [userAttributesColumn, setUserAttributesColumn] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalSelectedRows, setTotalSelectedRows] = useState(0);
  const [selectedAll, setSelectedAll] = useState(false);
  const [excludedRows, setExcludedRows] = useState([]);
  const [partialSelectedRows, setPartialSelectedRows] = useState(false);
  const [salesAgent, setSalesAgent] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');

  const GetAgentsV2APICall = () => {
    let token = authtoken;
    V2AgentGet(token).then((res) => {
      if (res) {
        const supportAgents = Object.values(res?.data)?.filter(item => item?.role === 'sales')
        setSalesAgent(supportAgents);
      } else {
      }
    });
  };
  useEffect(() => {
    GetAgentsV2APICall();
  }, []);

  // Handler to select/unselect a specific row
  const handleSelectRow = (id) => {
    if (selectedAll || partialSelectedRows) {
      setSelectedAll(false);
      setPartialSelectedRows(true);
      setExcludedRows((prevExcluded) =>
        prevExcluded.includes(id)
          ? prevExcluded.filter((rowId) => rowId !== id)
          : [...prevExcluded, id]
      );
      setTotalSelectedRows(totalSelectedRows - 1);
    } else {
      setSelectedRows((prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((rowId) => rowId !== id)
          : [...prevSelected, id]
      );
    }
  };

  // Handler to select/unselect all rows
  const handleSelectAll = () => {
    setSelectedAll(!selectedAll);
    setPartialSelectedRows(false);
    setExcludedRows([]);
    setSelectedRows([]);
  };

  useEffect(() => {
    if (selectedAll) {
      setTotalSelectedRows(allData?.data?.totalDocs);
    }
  }, [selectedAll]);

  const updateLeadAssign = async (mobile, salesAgent) => {
    // if (!salesAgent) {
    //   toast.error('Please select a sales agent to assign lead')
    //   return;
    // }
    const data = {
      mobile: mobile,
      salesAgent
    }
    try {
      const res = await updateContactLead(data, authtoken);
      if (res?.flag) {
        fetchData()
        toast.success(res?.message)
      }
      else {
        toast.error(res?.message)
      }
    } catch (error) {
      toast.error('Failed to update lead assignment, please try again later')
      console.log('error:', error);
    }
  }



  // const [allData, setAllData] = useState([]);

  useEffect(() => {
    trackPages('contacts_all_contacts_page', localdata);
  }, []);

  const addAttributesCheck = (attribute, value, contact_id) => {
    const attrType = userAttributes?.find((a) => a.attribute === attribute)?.type;
    if (attrType === 'date') {
      value = formatDateForInput(value);
    } else if (attrType === 'timestamp') {
      value = formatTimestampForInput(value);
    }
    let token = authtoken;
    let change = {};
    change[attribute] = value;
    let data = {
      contact_id: contact_id,
      newChange: change,
    };
    AddEditcustomAttributes(token, data).then((res) => {
      if (res?.status === 200) {
        fetchData();
        toast.success('Attribute successfully updated!', {
          autoClose: 500,
          position: 'top-right',
        });
      } else {
        toast.error('Attribute could not be updated, please try again later', {
          autoClose: 1000,
          position: 'top-right',
        });
      }
    });
  };

  useEffect(() => {
    if (userAttributes?.length > 0) {
      setUserAttributesColumn(() => {
        let temp = [];
        userAttributes
          .filter((item) => item?.attribute)
          .map((item) => {
            temp.push({
              field: item?.attribute,
              headerName: item?.attribute,
              width: 200,
              align: 'center',
              headerAlign: 'center',
              attrtype: item?.type,
              options: item?.options ? item?.options : [],
              renderCell: (params) => {
                const value = params.value ?? '--';

                if (item?.type === 'dropdown') {
                  return (
                    <CheerioDropDown
                      name={item?.attribute}
                      id={params.row.id}
                      options={item?.options || []}
                      initialValue={value}
                      handleUpdate={addAttributesCheck}
                      isIdRequired={true}
                    />
                  );
                }

                return (
                  <Tooltip title={value} arrow>
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {value}
                    </Typography>
                  </Tooltip>
                );
              }
            });
          });

        temp = temp.filter(
          (column) =>
            column.headerName.toLowerCase() !== 'name' &&
            column.headerName.toLowerCase() !== 'email' &&
            column.headerName.toLowerCase() !== 'phno'
        );
        return temp;
      });
    }
  }, [userAttributes]);



  function mergeArrays(array1, array2) {
    let mergedArray = array1.slice();
    let insertIndex = mergedArray.length;
    mergedArray.splice(insertIndex, 0, ...array2);
    return mergedArray;
  }

  useEffect(() => {
    const savedModel = localStorage.getItem('column_visibility');
    if (savedModel) {
      setColumnVisibilityModel(JSON.parse(savedModel))
    }
  }, [])

  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
    localStorage.setItem('column_visibility', JSON.stringify(newModel));
  }

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  let pageData = {
    user_id: localdata?._id ? localdata?._id : _id,
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
    search: search,
    filter: filter,
    andCondition: andCondition
  };

  // const { data, isLoading, isError } = useQuery(
  //   ['contactAll', trigger, paginationModel, search],
  //   () => filterContacts(pageData, authtoken)
  // );
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = await filterContacts(pageData, authtoken);
      if (data?.flag) {
        setAllData(data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error:', error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [search, paginationModel]);


  // useEffect(() => {
  //   setAllData(data);
  // }, [data]);
  if (isLoading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100"
        style={{ flex: 1 }} >
        <SpinnerLoader></SpinnerLoader>
      </div>
    );
  }

  const ContactsDepsCols = [
    {
      field: 'select',
      headerName: (
        <Checkbox
          color="primary"
          checked={selectedAll}
          onChange={(e) =>
            handleSelectAll()
          }
          sx={{
            color: "gray",
            '&.Mui-checked': {
              color: colors.darkPurple,
            },
          }}
        />
      ),
      width: 50,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      description: "Select all rows",
      renderCell: (params) => {
        return (
          <Checkbox
            color="primary"
            checked={selectedRows.includes(params.row.id) || selectedAll || (partialSelectedRows && !excludedRows.includes(params.row.id)) ? true : false}
            onChange={() => handleSelectRow(params.row.id)}
            sx={{
              color: 'gray',
              '&.Mui-checked': {
                color: colors.darkPurple,
              },
            }}
          />
        );
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <EditableCell value={params.value} cnc_id={params.row.id} params={params} />;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <RenderNameRow params={params} />;
      },
    },
    {
      field: 'phno',
      headerName: 'Phone Number',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <RenderPhnoRow params={params} />;
      },
    },
    {
      field: 'email',
      headerName: 'Email ID',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <RenderEmailRow params={params} />;
      },
    },
    {
      field: 'sales_agent',
      headerName: 'Lead Assignment',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const options = salesAgent?.map((item) => ({
          optionName: item.name,
          id: item._id,
          color: '#FEF3B1'
        }));
        return (
          <div>
            {<CheerioDropDown
              name={params.row.phno}
              id={params.row.sales_agent.id}
              options={options ?? []}
              initialValue={params.row.sales_agent.name}
              setValue={setSelectedAgent}
              isAgent={true}
              handleUpdate={updateLeadAssign}
            />}
          </div>
        )
      },
    },
    {
      field: 'addedOn',
      headerName: 'Added On',
      width: 218,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      width: 218,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  let row_data = [];
  allData?.data?.docs?.forEach((vls) => {
    let AttrRowObject =
      userAttributesColumn?.length > 0 &&
      userAttributesColumn.reduce((obj, item) => {
        let varName = String(item?.field).split('AAA')[0];
        let attrType = item?.attrtype;
        let options = item?.options;

        // obj[item?.field] = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';
        // obj[varName] = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';

        const attributeValue = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';

        if (attrType === 'date') {
          obj[item?.field] = attributeValue !== '--' ? formatDateForDisplay(attributeValue) : '--';
        } else if (attrType === 'time') {
          obj[item?.field] = attributeValue !== '--' ? formatTimeForDisplay(attributeValue) : '--';
        } else if (attrType === 'timestamp') {
          obj[item?.field] = attributeValue !== '--' ? formatTimestampForDisplay(attributeValue) : '--';
        } else {
          obj[item?.field] = attributeValue;
        }
        return obj;
      }, {});
    row_data.push(
      Object.keys(AttrRowObject).length > 0
        ? Object.assign(
          {},
          {
            id: vls._id,
            name: vls.name,
            addedOn: getLocaltime(vls.createdAt),
            updatedOn: getLocaltime(vls.updatedAt),
            phno: vls.mobile,
            email: vls.email,
            actions: vls,
            version: vls.version,
            visible: !excepted,
            sales_agent: vls.sales_agent,
          },
          AttrRowObject
        )
        : {
          id: vls._id,
          name: vls.name,
          addedOn: getLocaltime(vls.createdAt),
          updatedOn: getLocaltime(vls.updatedAt),
          phno: vls.mobile,
          email: vls.email,
          actions: vls,
          version: vls.version,
          visible: !excepted,
          sales_agent: vls.sales_agent,
        }
    );
  });
  return (
    <>
      <div>
        {(selectedRows?.length > 0
          || (partialSelectedRows && excludedRows?.length > 0)
          || (selectedAll && totalSelectedRows > 0

          )) &&
          <div>
            <ContactSelectionHeader
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              allData={allData}
              userAttributesColumn={userAttributesColumn}
              totalSelectedRows={totalSelectedRows}
              selectedAll={selectedAll}
              setSelectedAll={setSelectedAll}
              partialSelectedRows={partialSelectedRows}
              setPartialSelectedRows={setPartialSelectedRows}
              filter={filter}
              excludedContacts={excludedRows}
              setExcludedRows={setExcludedRows}
              search={search}
              andCondition={andCondition}
              fetchData={fetchData}
              labelId={""}
              salesAgent={salesAgent}
            />
          </div>}
        <DataGrid
          sx={{
            ...styledSx, height: ((selectedRows?.length > 0
              || partialSelectedRows || selectedAll) || selectedAll) ? '78vh' : "85vh"
          }}
          columns={
            userAttributesColumn?.length > 0
              ? mergeArrays(ContactsDepsCols, userAttributesColumn)
              : ContactsDepsCols
          }
          rows={row_data}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationMode="server"
          rowCount={allData?.data?.totalDocs}
          initialState={{
            pinnedColumns: {
              left: ['name'],
              right: ['action']
            },
            columns: {
              columnVisibilityModel,
            }

          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
        />
      </div>
      <Routes>
        <Route path={'/edit'} element={<EditSidebar />} />
      </Routes>
    </>
  );
};

export default ContactsAll;

export const ContactSelectionHeader = ({
  selectedRows,
  setSelectedRows,
  allData,
  userAttributesColumn,
  totalSelectedRows,
  selectedAll,
  setSelectedAll,
  partialSelectedRows,
  setPartialSelectedRows,
  filter,
  excludedContacts,
  setExcludedRows,
  search,
  andCondition,
  fetchData,
  labelId,
  salesAgent
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const editButtonRef = useRef(null);
  const emailButtonRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const auth_token = useSelector((state) => state.main.auth_token);
  const [sendEmail, setSendEmail] = useState("");
  const [value, setValue] = useState('');
  const [attribute, setAttribute] = useState('');
  const [emailModal, setEmailModal] = useState(false);
  const [agentId, setAgentId] = useState('unassigned');


  const handleBulkExport = async () => {
    const toastId = toast.loading('Exporting data. Please wait!', {
      autoClose: false,
    });
    try {
      let pageData = {
        serach: search,
        andCondition: andCondition,
        email: sendEmail,
        filter: filter,
        selectedAll: selectedAll,
        selectedContacts: selectedRows,
        excludedContacts: excludedContacts,
        labelId: labelId ? labelId : ""
      }
      const res = await bulkExport(pageData, auth_token);
      if (res?.flag) {
        toast.dismiss(toastId);
        toast.success("Exported successfully, Please anticipate a potential delay!", {
          autoClose: 1000,
          position: 'top-right',
        });
        setSelectedAll(false);
        setSelectedRows([]);
        setExcludedRows([]);
        setPartialSelectedRows(false);
      } else {
        toast.dismiss(toastId);
        toast.error('Failed to export, please try again later', {
          autoClose: 1000,
          position: 'top-right',
        });
      }
    } catch (error) {
      toast.dismiss(toastId);
      console.log('error:', error);
      toast.error('Failed to export, please try again later', {
        autoClose: 1000,
        position: 'top-right',
      });
    }
  }

  const handleBulkDelete = async () => {
    try {
      let pageData = {
        serach: search,
        andCondition: andCondition,
        filter: filter,
        selectedAll: selectedAll,
        selectedContacts: selectedRows,
        excludedContacts: excludedContacts,
        labelId: labelId ? labelId : ""
      }

      const res = await bulkDelete(pageData, auth_token);
      if (res?.flag) {
        fetchData();
        toast.success(res?.data?.message, {
          autoClose: 1000,
          position: 'top-right',
        });
        setSelectedAll(false);
        setSelectedRows([]);
        setExcludedRows([]);
        setPartialSelectedRows(false);
      } else {
        toast.error('Failed to delete contacts, please try again later', {
          autoClose: 1000,
          position: 'top-right',
        });
      }
    } catch (error) {
      console.log('error:', error);
      toast.error('Failed to delete contacts, please try again later', {
        autoClose: 1000,
        position: 'top-right',
      });
    }
  }

  const handleBulkCopy = async () => {
    try {
      toast.loading('Generating data. Please wait!', {
        toastId: 2,
        autoClose: 3000,
      });

      let pageData = {
        search: search,
        andCondition: andCondition,
        filter: filter,
        selectedAll: selectedAll,
        selectedContacts: selectedRows,
        excludedContacts: excludedContacts,
        labelId: labelId ? labelId : ""
      };
      const res = await bulkCopy(pageData, auth_token);

      if (res?.flag) {
        const data = res?.data;
        const formattedData = data
          .split("\n")
          .map(row => row.split("\t").join("\t"))
          .join("\n");
        navigator.clipboard.writeText(formattedData).then(
          () => {
            toast.dismiss(2);
            toast.success('Data successfully copied to clipboard and ready for Excel!');
            setSelectedAll(false);
            setSelectedRows([]);
            setExcludedRows([]);
            setPartialSelectedRows(false);
          },
          (err) => {
            toast.dismiss(2);
            toast.error('Failed to copy data to clipboard, please try again later')
            console.log('Clipboard copy failed:', err);
          }
        );
      } else {
        // Handle error: Show failure toast
        toast.dismiss(2);
        toast.error('Failed to copy data to clipboard, please try again later')
      }
    } catch (error) {
      console.log('Error:', error);
      // Error handling
      toast.error('Failed to generate or copy data, please try again later', {
        autoClose: 1000,
        position: 'top-right',
      });
    }
  };

  const handleBulkUpdate = async () => {
    try {
      let change = {};
      change[attribute] = attribute === 'Lead Assignment' ? agentId : value;
      let pageData = {
        serach: search,
        andCondition: andCondition,
        filter: filter,
        selectedAll: selectedAll,
        selectedContacts: selectedRows,
        excludedContacts: excludedContacts,
        data: change,
        labelId: labelId ? labelId : ""
      }
      const res = await bulkUpdate(pageData, auth_token);
      if (res?.flag) {
        fetchData();
        toast.success(res?.data, {
          autoClose: 1000,
          position: 'top-right',
        });
        setSelectedAll(false);
        setSelectedRows([]);
        setExcludedRows([]);
        setPartialSelectedRows(false);
      } else {
        toast.error('Failed to update contacts, please try again later', {
          autoClose: 1000,
          position: 'top-right',
        });
      }
    } catch (error) {
      console.log('error:', error);
      toast.error('Failed to update contacts, please try again later', {
        autoClose: 1000,
        position: 'top-right',
      });
    }
  }



  const handleEditClick = () => {
    if (editButtonRef.current) {
      const rect = editButtonRef.current.getBoundingClientRect();
      setModalPosition({
        top: rect.bottom + window.scrollY + 10,
        left: rect.left + window.scrollX - 150,
      });
      setIsModalOpen(true);
    }
  };

  const handleEmailClick = () => {
    if (emailButtonRef.current) {
      const rect = emailButtonRef.current.getBoundingClientRect();
      setModalPosition({
        top: rect.bottom + window.scrollY + 10,
        left: rect.left + window.scrollX - 150,
      });
      setEmailModal(true);
    }
  };

  const handleOutsideClick = (event) => {
    if (
      !editButtonRef.current.contains(event.target) &&
      !event.target.closest('.modal-content-contact')
    ) {
      setIsModalOpen(false);
      setEmailModal(false);
    }
  };

  const handleEmailOutsideClick = (event) => {
    if (
      !emailButtonRef.current.contains(event.target) &&
      !event.target.closest('.modal-content-contact-email')
    ) {
      setIsModalOpen(false);
      setEmailModal(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (emailModal) {
      document.addEventListener('click', handleEmailOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleEmailOutsideClick);
    };
  }, [emailModal]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '10px',
          borderBottom: '1px solid #e0e0e0',
          backgroundColor: '#F0F2F2',
          gap: '12px',
          paddingLeft: '20px',
          borderRadius: '8px 8px 0 0',
        }}
      >
        <div style={{ fontWeight: 500 }}>{`${(selectedAll || partialSelectedRows) ? totalSelectedRows : selectedRows?.length} Contacts Selected`}</div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <div>
            <Tooltip title="Copy" style={{ cursor: 'pointer' }}>
              <img
                src={images.Copy}
                height={22}
                width={22}
                style={{ cursor: 'pointer' }}
                alt="copy"
                onClick={handleBulkCopy}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete" style={{ cursor: 'pointer' }}>
              <img
                src={images.DeleteBlack}
                height={22}
                width={22}
                style={{ cursor: 'pointer' }}
                alt="delete"
                onClick={() => setDeleteModal(true)}
              />
            </Tooltip>
          </div>
          <div ref={editButtonRef}>
            <Tooltip title="Edit" style={{ cursor: 'pointer' }}>
              <img
                src={images.EditSender}
                height={22}
                width={22}
                style={{ cursor: 'pointer' }}
                alt="edit"
                onClick={handleEditClick}
              />
            </Tooltip>
          </div>
          <div ref={emailButtonRef}>
            <Tooltip title="Export Excel" style={{ cursor: 'pointer' }}>
              <img
                src={images.FILEEXPORT}
                height={22}
                width={22}
                style={{ cursor: 'pointer' }}
                alt="export"
                onClick={handleEmailClick}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ContactUpdateModal
          userAttributesColumn={userAttributesColumn}
          position={modalPosition}
          setValue={setValue}
          value={value}
          attribute={attribute}
          setAttribute={setAttribute}
          handleBulkUpdate={handleBulkUpdate}
          salesAgent={salesAgent}
          setAgentId={setAgentId}
        />
      )}

      {deleteModal &&
        <DeleteBulkContact
          setDeleteModal={setDeleteModal}
          totalContacts={(selectedAll || partialSelectedRows) ? totalSelectedRows : selectedRows?.length}
          handleBulkDelete={handleBulkDelete}
        />}
      {emailModal &&
        <EmailModal
          setEmailModal={setEmailModal}
          position={modalPosition}
          email={sendEmail}
          setEmail={setSendEmail}
          handleBulkExport={handleBulkExport}
        />}
    </>
  );
};

export const ContactUpdateModal = ({ userAttributesColumn, position, setValue, value, setAttribute, attribute, handleBulkUpdate, salesAgent, setAgentId }) => {
  const [filteredCol, setFilteredCol] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [selectedColumnType, setSelectedColumnType] = useState('');
  const [selectedClValue, setSelectedClValue] = useState('');

  // useEffect(() => {
  //   setFilteredCol(
  //     userAttributesColumn?.filter((item) =>
  //       ['dropdown', 'time', 'date', 'timestamp']?.includes(item?.attrtype)
  //     )
  //   );
  // }, [userAttributesColumn]);
  useEffect(() => {
    const filteredColumns = userAttributesColumn?.filter((item) =>
      ['dropdown', 'time', 'date', 'timestamp'].includes(item?.attrtype)
    );

    const leadAssignmentColumn = {
      headerName: "Lead Assignment",
      field: "lead_assignment",
      attrtype: "dropdown",
      align: "center",
      headerAlign: "center",
      options: salesAgent?.map((item) => ({
        optionName: item.name,
        id: item._id,
        color: "#FEF3B1",
      })) || [],
    };

    setFilteredCol([leadAssignmentColumn, ...filteredColumns]);
  }, [userAttributesColumn, salesAgent]);

  useEffect(() => {
    setAttribute(selectedColumn);
  }, [selectedColumn]);

  useEffect(() => {
    if (selectedColumnType === 'date') {
      const formattedVal = formatDateForInput(selectedClValue);
      setValue(formattedVal);
    } else if (selectedColumnType === 'timestamp') {
      const formattedVal = formatTimestampForInput(selectedClValue);
      setValue(formattedVal);
    } else if (selectedColumnType === 'time') {
      const formattedVal = formatTimeForInput(selectedClValue);
      setValue(formattedVal);
    } else {
      setValue(selectedClValue);
    }
  }, [selectedClValue]);

  const handleSelectColumn = (e) => {
    setSelectedColumn(e.target.value);
    const selectedColumn = filteredCol?.find(
      (item) => item.headerName === e.target.value
    );
    setSelectedColumnType(selectedColumn?.attrtype);
  }

  return (
    <div
      className="modal-content-contact"
      style={{
        position: 'absolute',
        top: position.top,
        left: position.left,
        backgroundColor: 'white',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: '16px',
        zIndex: 1000,
        width: '350px',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div>
        <p style={{
          fontWeight: 600,
        }}>Bulk Edit</p>
        <p style={{
          color: colors.darkgrey,
          fontSize: "14px"
        }}>{`You can edit ${selectedColumnType} values of the selected rows`}</p>
        <div>
          <InputGroup className="mb-3" style={{ width: '100%', marginTop: 10 }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600 }}>
              {'Select Column'}
            </Form.Label>
            <Select
              displayEmpty
              fullWidth
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                disablePortal: true,
              }}
              sx={{
                borderRadius: '8px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  minHeight: '40px',
                  fontSize: '14px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #ced4da',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid #868e96',
                },
                '& .MuiSelect-select': {
                  padding: '10px 12px',
                },
              }}
              value={selectedColumn}
              onChange={handleSelectColumn}
            >
              {filteredCol?.map((option) => (
                <MenuItem
                  key={option.headerName}
                  value={option.headerName}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span
                    style={{
                      marginLeft: 8,
                      fontFamily: 'Segoe UI',
                      fontSize: '16px',
                    }}
                  >
                    {option.headerName}
                  </span>
                </MenuItem>
              ))}
            </Select>
          </InputGroup>
        </div>
        {selectedColumn && (
          <>
            <p style={{ fontWeight: 500, fontSize: "14px" }}>Change to</p>
            <div
              style={{
                marginTop: 10,
                width: '100%',
                border: '1px solid #CCCCCC',
                borderRadius: 8,
                backgroundColor: 'white !importanat',
                padding: selectedColumnType === 'dropdown' ? '3px 5px' : 0,
                marginBottom: 15
              }}>

              {selectedColumnType === 'dropdown' ?
                <CheerioDropDown
                  options={filteredCol?.find(
                    (item) => item.headerName === selectedColumn
                  )?.options}
                  initialValue={selectedClValue}
                  setValue={setSelectedClValue}
                  isBulk={true}
                  isAgent={selectedColumn === "Lead Assignment" && true}
                  setAgentId={setAgentId}
                /> :
                selectedColumnType === 'date' ?
                  <DateTimeInput
                    type="date"
                    value={selectedClValue}
                    onChange={(val) => setSelectedClValue(val)}
                    currentEditing={true}
                  /> :
                  selectedColumnType === 'time' ?
                    <DateTimeInput
                      type="time"
                      value={selectedClValue}
                      onChange={(val) => setSelectedClValue(val)}
                      currentEditing={true}
                    /> :
                    <DateTimeInput
                      type="timestamp"
                      value={selectedClValue}
                      onChange={(val) => setSelectedClValue(val)}
                      currentEditing={true}
                    />
              }
            </div>
          </>
        )}
        <CheerioButton
          borderStyle={{
            borderColor: colors.darkPurple,
            width: '100%',
            marginInlineEnd: 8,
          }}
          textStyle={{
            fontSize: 14,
            fontWeight: 600,
            color: colors.white,
          }}
          btnText={'Save Changes'}
          onclick={handleBulkUpdate}
          backColor={colors.darkPurple}
        />
      </div>
    </div>
  );
};

const DeleteBulkContact = ({ setDeleteModal, totalContacts, handleBulkDelete }) => {
  return (
    <>
      <div style={{
        width: '100%',
        height: "100%",
        zIndex: 1000,
        top: 0,
        left: 0,
        position: 'fixed',
        background: 'rgba(0,0,0,0.5)',
      }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <div
            className="d-flex flex-column justify-content-start align-items-start"
            style={{
              width: 450,
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 20,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{
                paddingBlockEnd: 8,
                borderBottom: `1px solid ${colors.borderwhite}`,
                paddingInline: 16,
              }}
            >

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <img src={images.CDelete} style={{ height: 24, width: 'auto' }} />
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 18,
                    fontWeight: 700,
                    color: colors.black,
                  }}
                >
                  {'Delete'}
                </p>
              </div>
              <img
                src={images.CMClose}
                style={{
                  height: 24,
                  width: 24,
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setDeleteModal(false);
                }}
              ></img>
            </div>
            <p
              style={{
                fontSize: 16,
                fontWeight: 500,
                color: colors.black,
                margin: "10px 0",
                paddingLeft: "5%",
              }}
            >
              {`Are you sure you want to delete ${totalContacts} contact${totalContacts > 1 ? "s" : ""}?`}
            </p>
            <div
              className="d-flex flex-row justify-content-center align-items-center w-100"
              style={{ marginTop: 12, gap: '10px' }}
            >
              <CheerioButton
                borderStyle={{
                  width: '40%',
                  marginInlineStart: 8,
                  border: `1px solid ${colors.primary03}`,
                }}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: colors.primary03,
                }}
                btnText={'Cancel'}
                backColor={colors.white}
                onclick={() => {
                  console.log('cancel clicked . . .');
                  setDeleteModal(false);
                }}
              />
              <CheerioButton
                borderStyle={{
                  borderColor: "#EA2727",
                  width: '40%',
                  marginInlineEnd: 8,
                }}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: colors.white,
                }}
                btnText={'Delete'}
                backColor={colors.red49}
                onclick={() => {
                  setDeleteModal(false);
                  handleBulkDelete();
                }}
              />
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export const EmailModal = ({
  setEmailModal,
  position,
  email,
  setEmail,
  handleBulkExport
}) => {
  const userEmail = useSelector((state) => state.main?.email);
  const [error, setError] = useState('');

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleEmailInput = (e) => {
    const input = e.target.value;
    if (!emailRegex.test(input)) {
      setError('Invalid email address');
    } else {
      setError('');
    }
    setEmail(input);
  }

  useEffect(() => {
    setEmail(userEmail);
  }, [userEmail]);

  return (
    <div
      className="modal-content-contact-email"
      style={{
        position: 'absolute',
        top: position.top,
        left: position.left,
        backgroundColor: 'white',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: '16px',
        zIndex: 1000,
        // width: '350px',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div>
        <p style={{
          fontWeight: 600,
        }}>Bulk Export</p>
        <p style={{
          color: colors.darkgrey,
          fontSize: "14px"
        }}>You can export the selected rows to an Excel sheet</p>
        <div>
          <InputGroup className="mb-3" style={{ width: '100%', marginTop: 10, padding: "0 5px" }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                {'Email'}
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                className="w-100 btncustom dropdown"
                style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01, width: "100%", border: error?.length > 0 ? `1px solid red` : `1px solid ${colors.darkPurple}` }}
                value={email}
                onChange={handleEmailInput}
                min={5}
                max={25}
              ></Form.Control>
              {error && <small style={{ color: "red" }}>{error}</small>}
            </div>
          </InputGroup>
        </div>
        <CheerioButton
          borderStyle={{
            borderColor: colors.darkPurple,
            width: '100%',
            marginInlineEnd: 8,
          }}
          textStyle={{
            fontSize: 14,
            fontWeight: 600,
            color: colors.white,
          }}
          disabled={error?.length > 0}
          btnText={'Export'}
          onclick={() => {
            handleBulkExport();
            setEmailModal(false);
          }}
          backColor={colors.darkPurple}
        />
      </div>
    </div>
  );
};
