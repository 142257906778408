import React, { useEffect, useState, useRef } from 'react';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import { V2EmailBuilderWorkflowList } from '../../../Services';
import { Chip } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';


const SequenceSidebar = ({ editNodeData, addNewNodeFunction, deleteNodeAction, setEditNodeData, saveDataAction, nodesInBranch }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const [templateList, setTemplateList] = useState([]);
    const editorRef = useRef(null);
    const isNodeTypeInBranch = (nodeType) => {
        return nodesInBranch?.some(node => node.type === nodeType);
    };
    const [validationError, setValidationError] = useState(false);



    // Function to determine if a node should be disabled
    const isNodeDisabled = (nodeType) => {
        // Don't disable inboxMessageNode
        if (nodeType === 'inboxMessageNode') {
            return false;
        }
        // For all other node types, disable if they already exist in the branch
        return isNodeTypeInBranch(nodeType);
    };

    useEffect(() => {
        if (editNodeData?.type === 'sendEmailNode') {
            getDesignAPIcall();
        }
    }, [editNodeData]);

    const getDesignAPIcall = () => {
        let token = authtoken;
        V2EmailBuilderWorkflowList(token).then((res) => {
            if (res) {
                console.log('success response ===> ', res);
                setTemplateList(res?.data);
            } else {
                console.log('error response ===> ', res);
            }
        });
    };

    const handleSave = () => {
        if (editNodeData?.type === 'inboxMessageNode' && (!editNodeData?.data?.content || editNodeData.data.content.trim() === '')) {
            setValidationError(true);
            toast.error('Message is required!');
            return;
        }
        setValidationError(false);
        saveDataAction();
        toast.success('Data saved successfully!');
    };

    const dontResetCursor = (cursorPosition) => {
        setTimeout(() => {
            editorRef.current.focus();
            editorRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };

    const addBeforeAndAfter = (before, after) => {
        const inputValue = editNodeData?.data?.content;
        const textarea = editorRef.current;
        const { selectionStart, selectionEnd } = textarea;
        const textBeforeCursor = inputValue.substring(0, selectionStart);
        const textAfterCursor = inputValue.substring(selectionEnd);
        const textBetweenCursor = inputValue.substring(selectionStart, selectionEnd);
        const updatedText = `${textBeforeCursor}${before}${textBetweenCursor}${after}${textAfterCursor}`;
        setEditNodeData({
            ...editNodeData,
            data: {
                ...editNodeData.data,
                content: updatedText,
            }
        });
        dontResetCursor(selectionStart + 1);
    };

    const handleItalicClick = (e) => {
        e.preventDefault();
        addBeforeAndAfter('_', '_');
    };

    const handleBoldButtonClick = (e) => {
        e.preventDefault();
        addBeforeAndAfter('*', '*');
    };

    const handleStrikeThroughClick = (e) => {
        e.preventDefault();
        addBeforeAndAfter('~', '~');
    };

    const handleAddVariable = () => {
        const currentContent = editNodeData?.data?.content || '';
        const patternFullVariable = /\{\{+\d+\}\}+/g;
        const variablesDetected = currentContent.match(patternFullVariable);
        const newContent = `${currentContent}{{${!variablesDetected ? 1 : variablesDetected.length + 1}}}`;

        setEditNodeData({
            ...editNodeData,
            data: {
                ...editNodeData.data,
                content: newContent,
                variables: [...(editNodeData?.data?.variables || [])] // Initialize variables array if it doesn't exist
            }
        });
    };

    return (
        <>
            <div
                className="Column justify-content-start"
                style={{
                    width: '35%',
                    minWidth: '500px', // Add this to ensure minimum width
                    height: '100%',
                    border: `1px solid ${colors.borderwhite}`,
                    backgroundColor: colors.white01,
                    paddingBlock: 20,
                    paddingInline: 24,
                    overflow: 'auto' // Add this to handle content overflow
                }}
            >
                <div
                    className="Row w-100 justify-content-between"
                    style={{ paddingBlockEnd: 20, borderBottom: `2px solid ${colors.borderwhite}` }}
                >
                    {(editNodeData?.type === 'sendEmailNode' || editNodeData?.type === 'timeDelayNode' || editNodeData?.type === 'inboxMessageNode' || editNodeData?.type === 'inMailMessageNode' || editNodeData?.type === 'inviteEmailNode') ? (
                        <>
                            <div className='Row justify-content-start'>
                                <img
                                    src={
                                        editNodeData?.type === 'sendEmailNode'
                                            ? images.SendEmail
                                            : editNodeData?.type === 'timeDelayNode'
                                                ? images.TimeDelay
                                                : editNodeData?.type === 'inboxMessageNode'
                                                    ? images.InboxMessage
                                                    : editNodeData?.type === 'inMailMessageNode'
                                                        ? images.InMailMessage
                                                        : editNodeData?.type === 'inviteEmailNode'
                                                            ? images.InviteEmail
                                                            : images.DefaultImage
                                    }
                                    style={{
                                        height: 32,
                                        width: 32,
                                        objectFit: 'contain',
                                    }}
                                />
                                <h4 className='ms-2'>
                                    {editNodeData?.type === 'writeEmailNode' ? `Email follow up` : editNodeData?.type === 'sendEmailNode' ? `Send email template` : editNodeData?.type === 'timeDelayNode' ? `Add time delay` : editNodeData?.type === 'inboxMessageNode' ? `Inbox Message` : editNodeData?.type === 'inMailMessageNode' ? `InMail Message` : editNodeData?.type === 'inviteEmailNode' ? `Invite Email` : editNodeData?.type === 'sendInviteNode' && `Send an Invite`}
                                </h4>
                            </div>
                        </>
                    ) : (
                        <>
                            <h4>
                                {'Add a step'}
                            </h4>
                        </>
                    )}

                    {editNodeData?.type !== 'addStepNode' && (
                        <>
                            <img
                                src={images.DeleteBlack}
                                style={{ height: 20, width: 20, objectFit: 'contain', cursor: 'pointer' }}
                                onClick={() => {
                                    deleteNodeAction(editNodeData);
                                }}
                            />
                        </>
                    )}
                </div>
                <div
                    className="Column justify-content-start w-100 px-1"
                    style={{
                        paddingBlock: 8,
                        flex: 1,
                        overflowY: 'auto',
                    }}
                >
                    {editNodeData?.type === 'addStepNode' ? (
                        <>
                            <div style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: colors.black,
                                marginTop: 20,
                                marginBottom: 10
                            }}>
                                Actions
                            </div>
                            <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    if (!isNodeDisabled('sendInviteNode')) {
                                        addNewNodeFunction('sendInviteNode');
                                    }
                                }}
                                style={{
                                    opacity: isNodeDisabled('sendInviteNode') ? 0.5 : 1,
                                    cursor: isNodeDisabled('sendInviteNode') ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <img
                                    src={images.SendanInviteIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'Send an Invite Node'}</p>
                            </div>
                            {/* <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    addNewNodeFunction('sendEmailNode');
                                }}
                            >
                                <img
                                    src={images.SeqSendEmail}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'Send email template'}</p>
                            </div> */}
                            <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    addNewNodeFunction('inboxMessageNode');
                                }}
                            >
                                <img
                                    src={images.InboxMessageIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'Inbox Message'}</p>
                            </div>
                            {/* <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    if (!isNodeDisabled('inMailMessageNode')) {
                                        addNewNodeFunction('inMailMessageNode');
                                    }
                                }}
                                style={{
                                    opacity: isNodeDisabled('inMailMessageNode') ? 0.5 : 1,
                                    cursor: isNodeDisabled('inMailMessageNode') ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <img
                                    src={images.InMailIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'InMail Message'}</p>
                            </div> */}
                            <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    if (!isNodeDisabled('viewProfileNode')) {
                                        addNewNodeFunction('viewProfileNode');
                                    }
                                }}
                                style={{
                                    opacity: isNodeDisabled('viewProfileNode') ? 0.5 : 1,
                                    cursor: isNodeDisabled('viewProfileNode') ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <img
                                    src={images.ViewProfileIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'View Profile'}</p>
                            </div>
                            {/* <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    if (!isNodeDisabled('endroseSkillsNode')) {
                                        addNewNodeFunction('endroseSkillsNode');
                                    }
                                }}
                                style={{
                                    opacity: isNodeDisabled('endroseSkillsNode') ? 0.5 : 1,
                                    cursor: isNodeDisabled('endroseSkillsNode') ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <img
                                    src={images.EndorseSkillsIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'Endrose Skills'}</p>
                            </div> */}
                            {/* <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    if (!isNodeDisabled('followNode')) {
                                        addNewNodeFunction('followNode');
                                    }
                                }}
                                style={{
                                    opacity: isNodeDisabled('followNode') ? 0.5 : 1,
                                    cursor: isNodeDisabled('followNode') ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <img
                                    src={images.FollowIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'Follow'}</p>
                            </div> */}
                            {/* <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    if (!isNodeDisabled('likeaPostNode')) {
                                        addNewNodeFunction('likeaPostNode');
                                    }
                                }}
                                style={{
                                    opacity: isNodeDisabled('likeaPostNode') ? 0.5 : 1,
                                    cursor: isNodeDisabled('likeaPostNode') ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <img
                                    src={images.LikeaPostIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'Like a Post Node'}</p>
                            </div> */}
                            <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    if (!isNodeDisabled('checkMailNode')) {
                                        addNewNodeFunction('checkMailNode');
                                    }
                                }}
                                style={{
                                    opacity: isNodeDisabled('checkMailNode') ? 0.5 : 1,
                                    cursor: isNodeDisabled('checkMailNode') ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <img
                                    src={images.SendEmailIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'Send Email'}</p>
                            </div>
                            <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    addNewNodeFunction('timeDelayNode');
                                }}
                            >
                                <img
                                    src={images.TimeDelay}
                                    style={{ height: 22, width: 22, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'Time delay'}</p>
                            </div>
                            <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    addNewNodeFunction('endFlowNode');
                                }}
                            >
                                <img
                                    src={images.EndFlowIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                ></img>
                                <p>{'End Flow'}</p>
                            </div>
                            <div style={{
                                fontSize: 16,
                                fontWeight: 600,
                                color: colors.black,
                                marginTop: 20,
                                marginBottom: 10
                            }}>
                                Conditions
                            </div>

                            <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    if (!isNodeDisabled('ifConnectedNode')) {
                                        addNewNodeFunction('ifConnectedNode');
                                    }
                                }}
                                style={{
                                    opacity: isNodeDisabled('ifConnectedNode') ? 0.5 : 1,
                                    cursor: isNodeDisabled('ifConnectedNode') ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <img
                                    src={images.IfConnectedIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                />
                                <p>If Connected</p>
                            </div>
                            <div
                                className="Row w-100 justify-content-start my-1 py-3 px-2 rounded hoverPrimary"
                                onClick={() => {
                                    if (!isNodeDisabled('ifMessageRepliedNode')) {
                                        addNewNodeFunction('ifMessageRepliedNode');
                                    }
                                }}
                                style={{
                                    opacity: isNodeDisabled('ifMessageRepliedNode') ? 0.5 : 1,
                                    cursor: isNodeDisabled('ifMessageRepliedNode') ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <img
                                    src={images.IfMessageRepliedIcon}
                                    style={{ height: 24, width: 24, objectFit: 'contain', marginInlineEnd: 10 }}
                                />
                                <p>If Message Replied</p>
                            </div>

                        </>
                    ) : editNodeData?.type === 'writeEmailNode' ? (
                        <>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Subject'}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Subject line'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.subject ?? ''}
                                    onChange={(e) => {
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                subject: e.target.value,
                                            }
                                        })
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Body'}
                                </Form.Label>
                                <Form.Control
                                    as={'textarea'}
                                    rows={10}
                                    type="text"
                                    placeholder='Message'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.content ?? ''}
                                    onChange={(e) => {
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                content: e.target.value,
                                            }
                                        })
                                    }}
                                />
                            </InputGroup>
                        </>
                    ) : editNodeData?.type === 'sendEmailNode' ? (
                        <>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Subject'}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Subject line'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.subject ?? ''}
                                    onChange={(e) => {
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                subject: e.target.value,
                                            }
                                        })
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Template'}
                                </Form.Label>
                                <Form.Select
                                    type="text"
                                    placeholder='Select template'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.emailBuilderId ?? ''}
                                    onChange={(e) => {
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                emailBuilderId: e.target.value,
                                            }
                                        })
                                    }}
                                >
                                    <option
                                        style={{ width: '100%', marginBlock: 4, borderRadius: 8 }}
                                        key="blankchoice"
                                        hidden
                                        value
                                    >
                                        {'Select email template'}
                                    </option>
                                    {templateList && templateList.map((item, index) => {
                                        return (
                                            <option
                                                style={{ width: '100%', marginBlock: 4, borderRadius: 8 }}
                                                value={item?._id}
                                                key={`emailtemplate${index}`}
                                            >
                                                {item?.emailName}
                                            </option>
                                        )
                                    })}
                                </Form.Select>
                            </InputGroup>
                        </>
                    ) : editNodeData?.type === 'timeDelayNode' ? (
                        <>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Days'}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder='days'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.days ?? 0}
                                    onChange={(e) => {
                                        const clampedValue = Math.max(0, Math.min(15, Number(e.target.value)))
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                days: clampedValue,
                                            }
                                        })
                                    }}
                                    min={0}
                                    max={7}
                                />
                            </InputGroup>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Hours'}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder='hours'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.hours ?? 0}
                                    onChange={(e) => {
                                        const clampedValue = Math.max(0, Math.min(24, Number(e.target.value)))
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                hours: clampedValue,
                                            }
                                        })
                                    }}
                                    min={0}
                                    max={24}
                                />
                            </InputGroup>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Minutes'}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder='minutes'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.minutes ?? ''}
                                    onChange={(e) => {
                                        const intvalue = parseInt(e.target.value)
                                        const clampedValue = Math.max(0, Math.min(60, Number(intvalue)))
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                minutes: clampedValue,
                                            }
                                        })
                                    }}
                                    min={0}
                                    max={60}
                                />
                            </InputGroup>
                        </>
                    ) : editNodeData?.type === 'inboxMessageNode' ? (
                        <>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Message*'}
                                </Form.Label>
                                <Form.Control
                                    as={'textarea'}
                                    rows={10}
                                    type="text"
                                    placeholder='Message'
                                    className="w-100 btncustom"
                                    style={{
                                        borderRadius: 8,
                                        borderColor: validationError ? 'red' : undefined
                                    }}
                                    value={editNodeData?.data?.content ?? ''}
                                    onChange={(e) => {
                                        setValidationError(false);
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                content: e.target.value,
                                            }
                                        })
                                    }}
                                />
                                {validationError && (
                                    <div style={{
                                        color: 'red',
                                        fontSize: 12,
                                        marginTop: 4
                                    }}>
                                        Message is required
                                    </div>
                                )}
                                <div style={{
                                    fontSize: 12,
                                    color: colors.gray03,
                                    marginTop: 8,
                                    fontStyle: 'italic'
                                }}>
                                    For personalized messages, you can add the value of custom attributes using the following syntax %attribute_name%
                                </div>
                            </InputGroup>

                        </>
                    ) : editNodeData?.type === 'inMailMessageNode' ? (
                        <>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Subject (Optional)'}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Subject line'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.subject ?? ''}
                                    onChange={(e) => {
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                subject: e.target.value,
                                            }
                                        })
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Body'}
                                </Form.Label>
                                <Form.Control
                                    as={'textarea'}
                                    rows={10}
                                    type="text"
                                    placeholder='Message'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.content ?? ''}
                                    onChange={(e) => {
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                content: e.target.value,
                                            }
                                        })
                                    }}
                                // ref={editorRef}
                                />

                                {/* <div className="d-flex justify-content-end" style={{ width: '100%', marginTop: '10px' }}>
                                    <div
                                        className="d-flex justify-content-evenly"
                                        style={{ width: '22%', height: '20px', marginRight: '10px' }}
                                    >
                                        <img
                                            alt="bold"
                                            style={{ cursor: 'pointer' }}
                                            src={images.Bold}
                                            onClick={handleBoldButtonClick}
                                        />
                                        <img
                                            alt="italic"
                                            src={images.Itlaic}
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleItalicClick}
                                        />
                                        <img
                                            alt="strike"
                                            src={images.Strike}
                                            style={{ cursor: 'pointer' }}
                                            onClick={handleStrikeThroughClick}
                                        />
                                    </div>

                                    <CheerioButton
                                        className="button"
                                        borderStyle={{
                                            height: '23px',
                                            width: '27%',
                                            borderRadius: '6px',
                                        }}
                                        textStyle={{ color: 'black', fontSize: 11, fontWeight: 600 }}
                                        btnText={'Add variable'}
                                        backColor={'white'}
                                        onclick={handleAddVariable}
                                    />
                                </div> */}

                                {/* Add variable chips section */}
                                {/* <div>
                                    {editNodeData?.data?.content?.match(/\{\{(\d+)\}\}/g)?.map((tag, index) => (
                                        <div className="w-100" key={index} style={{ display: 'flex', width: '500px' }}>
                                            <Chip label={tag} style={{ margin: '5px' }} />
                                            <div key={index} className="w-100">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter text"
                                                    className="w-100 btncustom"
                                                    style={{ borderRadius: 8 }}
                                                    value={editNodeData?.data?.variables?.[index] ?? ''}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        const updatedVariables = [...(editNodeData?.data?.variables || [])];
                                                        updatedVariables[index] = value;
                                                        setEditNodeData({
                                                            ...editNodeData,
                                                            data: {
                                                                ...editNodeData.data,
                                                                variables: updatedVariables,
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div> */}
                                <div style={{
                                    fontSize: 12,
                                    color: colors.gray03,
                                    marginTop: 8,
                                    fontStyle: 'italic'
                                }}>
                                    For personalized messages, you can add the value of custom attributes using the following syntax %attribute_name%
                                </div>
                            </InputGroup>
                        </>
                    ) : editNodeData?.type === 'inviteEmailNode' && (
                        <>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Subject (Optional)'}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Subject line'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.subject ?? ''}
                                    onChange={(e) => {
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                subject: e.target.value,
                                            }
                                        })
                                    }}
                                />
                            </InputGroup>
                            <InputGroup className="my-2" style={{ width: '100%' }}>
                                <Form.Label style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                                    {'Body'}
                                </Form.Label>
                                <Form.Control
                                    as={'textarea'}
                                    rows={10}
                                    type="text"
                                    placeholder='Message'
                                    className="w-100 btncustom"
                                    style={{ borderRadius: 8 }}
                                    value={editNodeData?.data?.content ?? ''}
                                    onChange={(e) => {
                                        setEditNodeData({
                                            ...editNodeData,
                                            data: {
                                                ...editNodeData.data,
                                                content: e.target.value,
                                            }
                                        })
                                    }}
                                />
                            </InputGroup>
                        </>
                    )}
                    {editNodeData?.type !== 'addStepNode' &&
                        <CheerioButton
                            className="btncustom"
                            borderStyle={{
                                paddingInline: 24,
                                borderColor: colors.primary03,
                                marginInlineEnd: 'auto'
                            }}
                            textStyle={{ color: colors.white01, fontSize: 16, fontWeight: 600 }}
                            btnText={'Save'}
                            backColor={colors.primary03}
                            onclick={handleSave}
                        />
                    }

                </div>
            </div>
        </>
    );
};

export default SequenceSidebar;
