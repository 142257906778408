import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'
import CheerioButton from '../../../Components/CheerioButton'
import CampaignSettingsModal from './CampaignSettingsModal'
import SelectSenderIDModal from './SelectSenderIDModal'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepContent } from '@mui/material'

const SetupListItem = ({ completedState = false, headerText, bodyText, btnText, onclickAction }) => {
    return (
        <>
            <div className='Row justify-content-between w-100 align-items-start' style={{
                paddingBlock: 16,
            }}>
                <div className='Row justify-content-start align-items-start' style={{ width: '60%' }}>
                    {/* {completedState ? (
                    <>
                    <img 
                        src={images.CircleCheck} 
                        style={{height:18,width:18,objectFit:'contain',marginInlineEnd:16,marginBlock:4}}
                    ></img>
                    </>
                ):(
                    <>
                    <img 
                        src={images.CircleUncheck} 
                        style={{height:18,width:18,objectFit:'contain',marginInlineEnd:16,marginBlock:4}}
                    ></img>
                    </>
                )} */}

                    <div className='Column align-items-start'>
                        <p style={{ fontWeight: 600 }}>
                            {headerText}
                        </p>
                        <p style={{ fontWeight: 400 }}>
                            {bodyText}
                        </p>
                    </div>
                </div>
                <CheerioButton
                    btnText={btnText}
                    textStyle={{ color: colors.black }}
                    backColor={colors.white01}
                    borderStyle={{ borderColor: colors.black, width: '38%' }}
                    onclick={() => { onclickAction() }}
                />
            </div>
        </>
    )
}

const CampaignSetup = ({
    setActiveStep,
    campaignName,
    setCampaignName,
    receiveOn,
    setReceiveOn,
    selectedSenderID,
    setSelectedSenderID,
}) => {

    const [isCSModalOpen, setIsCSModalOpen] = useState(false);
    const [isSIModalOpen, setIsSIModalOpen] = useState(false);
    const [activeChildStep, setActiveChildStep] = useState(0);

    const steps = [
        'Campaign Settings',
        'Choose sender IDs'
    ];

    useEffect(() => {
        if (selectedSenderID?.length > 0 && campaignName) {
            setActiveChildStep(2);
        } else if (campaignName) {
            setActiveChildStep(1);
        } else {
            setActiveChildStep(0);
        }
    }, [campaignName, selectedSenderID])

    return (
        <>
            {isCSModalOpen &&
                <CampaignSettingsModal
                    setIsModalOpen={setIsCSModalOpen}
                    campaignName={campaignName}
                    setCampaignName={setCampaignName}
                    receiveOn={receiveOn}
                    setReceiveOn={setReceiveOn}
                />
            }
            {isSIModalOpen &&
                <SelectSenderIDModal
                    setIsModalOpen={setIsSIModalOpen}
                    selectedSenderID={selectedSenderID}
                    setSelectedSenderID={setSelectedSenderID}
                />
            }
            <div className='Column justify-content-start' style={{
                width: 620,
                backgroundColor: colors.white01,
                border: `1px solid ${colors.borderwhite}`,
                borderRadius: 16,
                paddingBlock: 20,
                paddingInline: 24,
            }}>
                <div className='w-100' style={{ flex: 1 }}>
                    <Stepper activeStep={activeChildStep} orientation='vertical'>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>
                                    {/* {label} */}
                                    {label === 'Campaign Settings' && (
                                        <>
                                            <SetupListItem
                                                completedState={campaignName && receiveOn ? true : false}
                                                headerText={'Campaign Settings'}
                                                bodyText={'Configure campaign settings'}
                                                btnText={'Modify'}
                                                onclickAction={() => { setIsCSModalOpen(true) }}
                                            />
                                        </>
                                    )}
                                    {label === 'Choose sender IDs' && (
                                        <>
                                            <SetupListItem
                                                completedState={selectedSenderID?.length > 0 ? true : false}
                                                headerText={'Choose sender IDs'}
                                                bodyText={'Select sender IDs to be used for campaign'}
                                                btnText={'Manage IDs'}
                                                onclickAction={() => { setIsSIModalOpen(true) }}
                                            />
                                        </>
                                    )}
                                </StepLabel>
                                {/* <StepContent TransitionProps={{ unmountOnExit: false }}>
                    {label === 'Campaign Settings' && (
                        <>
                        <SetupListItem 
                            completedState={campaignName && receiveOn ? true : false} 
                            headerText={'Campaign Settings'}
                            bodyText={'Configure campaign settings'}
                            btnText={'Modify'}
                            onclickAction={()=>{setIsCSModalOpen(true)}}
                        />
                        </>
                    )} 
                    {label === 'Choose sender IDs' && (
                        <>
                        <SetupListItem 
                            completedState={selectedSenderID?.length > 0 ? true : false} 
                            headerText={'Choose sender IDs'}
                            bodyText={'Select sender IDs to be used for campaign'}
                            btnText={'Manage IDs'}
                            onclickAction={()=>{setIsSIModalOpen(true)}}
                        />
                        </>
                    )}    
                    </StepContent> */}
                            </Step>
                        ))}
                    </Stepper>
                </div>

                {/* <SetupListItem 
            completedState={campaignName && receiveOn ? true : false} 
            headerText={'Campaign Settings'}
            bodyText={'Configure campaign settings'}
            btnText={'Modify'}
            onclickAction={()=>{setIsCSModalOpen(true)}}
        /> */}
                {/* <SetupListItem 
            completedState={selectedSenderID?.length > 0 ? true : false} 
            headerText={'Choose sender IDs'}
            bodyText={'Select sender IDs to be used for campaign'}
            btnText={'Manage IDs'}
            onclickAction={()=>{setIsSIModalOpen(true)}}
        /> */}
                {/* <SetupListItem 
            completedState={false} 
            headerText={'Schedule Campaign'}
            bodyText={'Select a time to trigger email campaign'}
            btnText={'Schedule'}
            onclickAction={()=>{console.log("12121212")}}
        /> */}
                <CheerioButton
                    btnText={'Save & Next'}
                    textStyle={{ color: colors.white01 }}
                    backColor={colors.primary03}
                    borderStyle={{ borderColor: colors.primary03, width: '100%', marginBlock: 16 }}
                    onclick={() => { setActiveStep(3) }}
                    disabled={(campaignName && selectedSenderID?.length > 0) ? false : true}
                />
            </div>
        </>
    )
}

export default CampaignSetup