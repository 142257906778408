// SequencesTable.jsx
import React, { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { BiCopy } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';
import { BsPause, BsPlay, BsEye } from 'react-icons/bs';
import { FaRegStopCircle } from 'react-icons/fa';
import { fetchAllLinkedinSequences, updateLinkedinSequenceAction, deleteLinkedinSequence, getLinkedinSequence } from '../../../Services';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import images from '../../../Utils/images';

const SequencesTable = ({ hasSequences, setHasSequences }) => {
    // Hardcoded data for now - this would come from API later
    const [sequences, setSequences] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAllLinkedinSequencesAPI();
    }, []);





    useEffect(() => {
        setHasSequences(sequences.length > 0);
    }, [sequences, setHasSequences]);


    const localdata = useSelector((state) => state.main.localdata);
    const authtoken = useSelector((state) => state.main.auth_token);

    const fetchAllLinkedinSequencesAPI = () => {
        let token = authtoken;
        fetchAllLinkedinSequences(token).then((res) => {
            if (res?.flag) {
                const formattedSequences = res.data.map(sequence => ({
                    id: sequence?._id,
                    name: sequence?.name,
                    // status: "Live",
                    createdAt: new Date(sequence.createdAt).toLocaleString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit"
                    }),
                    sequenceCount: sequence?.nodes?.length,
                    leads: sequence?.profileUrls?.length,
                    label: sequence?.label?.name || '-',
                    status: sequence?.status || '-',
                    triggered: 0,
                    completed: 0,
                    acceptanceRate: 0,
                    replyRate: 0
                }));
                setSequences(formattedSequences);
            } else {
                toast.error(res?.message);
            }
        });
    };


    const handleAction = (sequence, action) => {
        const data = {
            _id: sequence.id,
            action: action
        };
        let token = authtoken;
        updateLinkedinSequenceAction(data, token)
            .then((res) => {
                if (res?.flag) {
                    toast.success(`Successfully ${action}d sequence`); // Refresh the table
                } else {
                    toast.error(res?.message);
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || err?.message || 'Failed to perform action');
            });
        fetchAllLinkedinSequencesAPI();
    };

    const handleDelete = (sequence) => {
        deleteLinkedinSequence(authtoken, sequence.id)
            .then((res) => {
                if (res?.data?.flag) {
                    toast.success('Sequence deleted successfully');
                    fetchAllLinkedinSequencesAPI();
                } else {
                    toast.error(res?.data?.message || 'Failed to delete sequence');
                }
            })
            .catch((err) => {
                toast.error('Failed to delete sequence');
            });
    };

    // const handleView = (sequence) => {
    //     getLinkedinSequence(authtoken, sequence.id)
    //         .then((res) => {
    //             if (res?.data?.flag) {
    //                 navigate(`/creator/linkedinsequences/view/${sequence.id}`, {
    //                     state: res.data  // Pass the entire response
    //                 });
    //             } else {
    //                 toast.error(res?.data?.message || 'Failed to fetch sequence details');
    //             }
    //         })
    //         .catch((err) => {
    //             toast.error(err?.response?.data?.message || err?.message || 'Failed to fetch sequence details');
    //         });
    // };


    const actionIconStyle = {
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.7
        }
    };

    // useEffect(() => {
    //     setHasSequences(sequences.length > 0);
    // }, [sequences, setHasSequences]);

    // // If no sequences, don'
    // if (sequences.length === 0) {
    //     return null;
    // }




    // const sequences = [
    //     {
    //         id: 1,
    //         name: 'Untitled Campaign',
    //         status: 'Live',
    //         createdAt: '26 Nov 2024, 08:32PM',
    //         sequenceCount: 2,
    //         leads: 0,
    //         triggered: 0,
    //         completed: 0,
    //         acceptanceRate: 0,
    //         replyRate: 0
    //     },
    //     {
    //         id: 2,
    //         name: 'Untitled Campaign',
    //         status: 'Live',
    //         createdAt: '26 Nov 2024, 08:32PM',
    //         sequenceCount: 2,
    //         leads: 0,
    //         triggered: 0,
    //         completed: 0,
    //         acceptanceRate: 0,
    //         replyRate: 0
    //     },
    //     {
    //         id: 3,
    //         name: 'Untitled Campaign',
    //         status: 'Live',
    //         createdAt: '26 Nov 2024, 08:32PM',
    //         sequenceCount: 2,
    //         leads: 0,
    //         triggered: 0,
    //         completed: 0,
    //         acceptanceRate: 0,
    //         replyRate: 0
    //     }
    // ];

    if (sequences?.length > 0) {
        return (
            <div style={{ padding: '20px' }}>
                <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 1px' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#F9FAFB' }}>
                            <th style={{ padding: '16px', width: '30%', textAlign: 'left', fontWeight: '500' }}>Campaign Name</th>
                            <th style={{ padding: '16px', width: '15%', textAlign: 'left', fontWeight: '500' }}>Label</th>
                            <th style={{ padding: '16px', textAlign: 'left', fontWeight: '500' }}>Created At</th>
                            <th style={{ padding: '16px', textAlign: 'left', fontWeight: '500' }}>Nodes</th>
                            <th style={{ padding: '16px', textAlign: 'left', fontWeight: '500' }}>Leads</th>
                            <th style={{ padding: '16px', textAlign: 'left', fontWeight: '500' }}>Status</th>
                            <th style={{ padding: '16px', width: '10%', textAlign: 'center', fontWeight: '500' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sequences.map((sequence) => (
                            <tr key={sequence.id} style={{ backgroundColor: 'white' }}>
                                <td style={{ padding: '16px' }}>
                                    <div style={{ fontWeight: '500' }}>{sequence.name}</div>
                                </td>
                                <td style={{ padding: '16px' }}>{sequence.label}</td>
                                <td style={{ padding: '16px' }}>{sequence.createdAt}</td>
                                <td style={{ padding: '16px' }}>{sequence.sequenceCount}</td>
                                <td style={{ padding: '16px' }}>{sequence.leads}</td>
                                <td style={{ padding: '16px' }}>{sequence.status}</td>
                                <td style={{ padding: '16px', textAlign: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '12px' }}>
                                        {/* <BsEye
                                            size={18}
                                            style={{
                                                ...actionIconStyle,
                                                color: '#4B5563'
                                            }}
                                            onClick={() => handleView(sequence)}
                                            title="View Sequence"
                                        /> */}
                                        {sequence.status !== 'Stopped' && sequence.status !== 'Queued' && (
                                            sequence.status === 'Active' ? (
                                                <BsPause
                                                    size={18}
                                                    style={{
                                                        ...actionIconStyle,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handleAction(sequence, 'pause')}
                                                    title="Pause"
                                                />
                                            ) : (
                                                <BsPlay
                                                    size={18}
                                                    style={{
                                                        ...actionIconStyle,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handleAction(sequence, 'resume')}
                                                    title="Resume"
                                                />
                                            )
                                        )}
                                        {sequence.status !== 'Queued' && (<img src={images.Stop} width={18} height={18}
                                            style={{
                                                ...actionIconStyle,
                                                cursor: 'pointer',
                                                opacity: sequence.status === 'Stopped' ? 0.5 : 1,
                                                cursor: sequence.status === 'Stopped' ? 'not-allowed' : 'pointer'
                                            }}
                                            onClick={() => sequence.status !== 'Stopped' && handleAction(sequence, 'stop')}
                                            title="Stop"
                                        />)}
                                        <BsTrash
                                            size={18}
                                            style={{
                                                ...actionIconStyle,
                                            }}
                                            onClick={() => handleDelete(sequence)}
                                            title="Delete"
                                        />
                                        <img
                                            src={images.BarChart}
                                            height={18}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                navigate(`/creator/linkedinsequences/analytics/${sequence.id}`, {
                                                    state: sequence
                                                });
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>

                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
    return null;
};

export default SequencesTable;