import '../styles.css';
import React, { useRef, useState, useEffect } from 'react';
import { Dropdown, InputGroup } from 'react-bootstrap';
import { CardImg, Form } from 'react-bootstrap';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import Helper from '../../../Utils/Helper';
import { getImagePath } from '../../../Utils';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import BackgroundImage from '../../../Images/creatorNew/Background.png';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { MdBolt } from 'react-icons/md';
import { CiClock2 } from 'react-icons/ci';
import {
  V2WorkflowS3Link,
  blockConversation,
  downloadFormCsv,
  getAllRespones,
  getCampignCount,
  getIndAttributesSidebar,
  toggleAgent,
  toggleInterveneStatus,
  toggleStatus,
  getCustomAttrByNameforMapping
} from '../../../Services';
import { useSelector } from 'react-redux';
import { MatchResponses, SavedResponses } from './savedResponses';
import { Button, MenuItem, Select, Tooltip } from '@mui/material';
import '../styles.css';
import { ReInitiate } from './reInitiate';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  BsCheck2All,
  BsFileEarmark,
  BsFillCameraVideoOffFill,
  BsImage,
  BsSend,
} from 'react-icons/bs';
import { BsDownload } from 'react-icons/bs';
import { IoCheckmark, IoLockClosedOutline } from 'react-icons/io5';
import { RepliesSideBar } from './RepliesSideBar';

import { CiFaceSmile } from 'react-icons/ci';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import AttributesModal from './AttributesModal';
import SendTemplateModal from './SendTemplateModal';
import SendTemplateBar from './SendTemplateBar';
import InfiniteScroll from 'react-infinite-scroll-component';
import TextareaAutosize from 'react-textarea-autosize';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { InterveneChat } from './InterveneChat';
import { TriggerWorkflow } from './TriggerWorkflow';
import { postComment } from '../../../Services';
import CountdownRectangularTimer from './CountdownRectangularTimer';
import { hiddenName, hiddenPhone } from '../../ContactModule/ContactsAll/variableDependencies';
import { eventsend } from '../../../Config/analyticsFunctions';
import { LuReply } from 'react-icons/lu';
import { ReplyToOption } from './ReplyToOption';
import { PiArrowCircleDown, PiFileAudioThin } from 'react-icons/pi';
import { GoArrowDown } from 'react-icons/go';
import { ResolveChatBar, UnblockChatBar } from './ResolveChatBar';
import { formatDateForDisplay, formatTimeForDisplay, formatTimestampForDisplay } from '../../../Utils/formatConvertor';

let chatArr = [];
let temp = {};
const MobileWidth = window.innerWidth < 900 ? true : false;
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    id="DropdownToggle"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();

      onClick(e);
    }}
  >
    {children}
    <Tooltip title={'Menu'}>
      <CardImg
        className="filterToggleHover"
        src={images.ThreeDot}
        style={{
          cursor: 'pointer',
          height: 30,
          width: 30,
          borderRadius: '10px',
          padding: '6px',
        }}
      />
    </Tooltip>
  </a>
));

const CarouselMessage = ({ carouselItems }) => {
  console.log("carouselItems", carouselItems.length)
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          overflowX: 'auto',
          width: '100%',
          maxWidth: '350px',
          position: 'relative',
          marginLeft: '415px',
          borderRadius: '10px',
          scrollSnapType: 'x mandatory',
          marginRight: '22px'

        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '6px',
            width: '100%',

          }}
        >
          {carouselItems.map((item, index) => (
            <div
              key={index}
              style={{
                maxWidth: '385px',
                flexShrink: 0,
                padding: '6px',
                boxSizing: 'border-box',
                backgroundColor: '#5068F2',
                borderRadius: '10px',
                width: '100%',
                textAlign: 'center',
                scrollSnapAlign: 'start',
              }}
            >
              {item.header?.type === 'VIDEO' ? (
                <video
                  src={item.header.link}
                  controls
                  style={{ width: '90%', borderRadius: '8px' }}
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                item.header?.type === 'IMAGE' && (
                  <img
                    src={item.header.link}
                    alt="Carousel Media"
                    style={{ width: '90%', borderRadius: '8px' }}
                  />
                )
              )}
              <div>
                <p style={{
                  marginTop: '8px', color: '#fff',
                  padding: '6px',
                  marginLeft: '22px',
                  textAlign: 'left',
                }}>{item.carousel_text}</p>
              </div>
              {item.buttons && item.buttons.map((button, btnIndex) => (
                <button
                  key={btnIndex}
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#5068F2',
                    padding: '10px',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginTop: '10px',
                    width: '90%',
                    paddingRight: '0.5rem !important',
                    paddingLeft: '0.5rem !important',
                  }}
                >
                  {button.text}
                </button>
              ))}
            </div>
          ))}
        </div>
      </div>
      <style>
        {`
    /*  scrollbar styles */
    div::-webkit-scrollbar {
      height: 4px; 
    }
    div::-webkit-scrollbar-thumb {
      background-color: #5068F2;
      border-radius: 10px;
    }
    div::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.2); 
    }
  `}
      </style>
    </div>
  );
};


const WaChatModal = ({ show, setShow, heading, body, btnText, apiCall }) => {
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
          zIndex: 1000,
          top: 0,
          left: 0,
          position: 'fixed',
          backgroundColor: 'rgba(0,0,0,0.1)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <div
            className="d-flex flex-column justify-content-start align-items-center"
            style={{
              width: '100%',
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 20,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{
                paddingBlockEnd: 8,
                borderBottom: `1px solid ${colors.borderwhite}`,
                paddingInline: 16,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 18,
                    fontWeight: 700,
                    color: colors.black,
                  }}
                >
                  {heading}
                </p>
              </div>
              <img
                src={images.CMClose}
                style={{
                  height: 24,
                  width: 24,
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setShow(false);
                }}
              ></img>
            </div>
            <p
              style={{
                margin: 12,
                fontSize: 16,
                fontWeight: 500,
                color: colors.black,
                marginInline: 16,
              }}
            >
              {body}
            </p>
            <div
              className="d-flex flex-row justify-content-center align-items-center w-100"
              style={{ marginTop: 12, gap: '10px' }}
            >
              <CheerioButton
                borderStyle={{
                  width: '40%',
                  marginInlineStart: 8,
                  border: `1px solid ${colors.primary03}`,
                }}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: colors.primary03,
                }}
                btnText={'Cancel'}
                backColor={colors.white}
                onclick={() => {
                  console.log('cancel clicked . . .');
                  setShow(false);
                }}
              />
              <CheerioButton
                borderStyle={{
                  border: 'none',
                  width: '40%',
                  marginInlineEnd: 8,
                }}
                textStyle={{
                  fontSize: 14,
                  fontWeight: 600,
                  color: colors.white,
                }}
                btnText={btnText}
                backColor={'#EA2727'}
                onclick={() => {
                  apiCall();
                  setShow(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function WAChatView({
  excepted,
  chatData,
  isChatLoad,
  setMessagePage,
  agentData,
  currentUser,
  setInboxList,
  getInboxListing,
  mobileChangeScreen,
  loadingChatScreen,
  socket,
  loadMore,
  more,
  messages,
  typingUser,
  // setChatStatus,
  setAgent,
  activeWorkflow,
  setActiveWorkflow,
  resumeWorkflow,
  setResumeWorkflow,
  setChatData,
  agentDet
}) {
  console.log('chatData', isChatLoad);
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const scrollParentRef = useRef();

  const messagesEndRef = useRef();
  const imageinputref = useRef();
  const documentRef = useRef();
  const [text, setText] = useState('');
  const [mediaSelector, setMediaSelector] = useState(false);
  const [mediaContent, setMediaContent] = useState();
  const [imgBase64, setImgBase64] = useState('');
  const [mediaType, setMediaType] = useState('');
  const authtoken = useSelector((state) => state.main.auth_token);
  const [showSavedResponse, setShowSavedResponses] = useState(false);
  const [values, setValues] = useState([]);
  const [context, setContext] = useState({ messageId: '' });
  const [e, setE] = useState(null);
  // const [chatData.status, setResolvedValue] = useState(chatData?.status  ?? 'Unresolved');
  const [agentName, setAgentName] = useState('');

  const [showEmoji, setShowEmoji] = useState(false);
  const [showSendTemplateModal, setShowSendTemplateModal] = useState(false);
  const [upFile, setUpFile] = useState();
  const [upFile64, setUpFile64] = useState('');
  const [headerFileName, setHeaderFileName] = useState('');
  const [s3Link, setS3Link] = useState('');
  const [showSideBar, setShowSideBar] = useState(MobileWidth ? false : true);
  // const [mediaType, setMediaType] = useState('');
  const textareaRef = useRef(null);
  // const [selected,setSelected] = useState();
  const [comments, setComments] = useState(chatData?.comments);
  const [openAttributes, setOpenAttributes] = useState(false);
  const [individualAttributes, setIndividualAttributes] = useState();
  const [details, setDetails] = useState();
  const [IAFilterValue, setIAFilterValue] = useState('');
  // const [errorAttributes, setErrorAttributes] = useState([]);
  const [timeDifference, setTimeDifference] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const token = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const agentdetails = useSelector((state) => state.main.agentdata);
  const [highlightedMsgId, setHighlightedMsgId] = useState(null);
  const navigate = useNavigate();
  // const visible = localdata?._id === "636be67d42c9a5cb33f60d98" || localdata?._id === "6489675b4f598e055ed74e34";
  temp = chatData;
  const [showElement, setShowElement] = useState(true);
  const [keyPressed, setKeyPressed] = useState('none');
  const [showMatchResponse, setShowMatchResponse] = useState(true);
  const [buttonMsg, setButtonMsg] = useState([]);
  const [showTriggerFlowModal, setShowTriggerFlowModal] = useState(false);
  const [sidebarView, setSidebarView] = useState('details');

  const agentdata = useSelector((state) => state.main.agentdata);

  const [commentText, setCommentText] = useState('');
  const [showReplyToOption, setShowReplyToOption] = useState(false);
  const [replyToMsg, setReplyToMsg] = useState();
  const [blockConvModal, setShowBlockConvModal] = useState(false);
  // const commentBoxRef = useRef();
  const commenter = agentdata ?? localdata;
  // const contextMsg =
  //   'wamid.wamid.HBgMOTE4MTEyMjk1MjQ1FQIAEhggNDMyMDRGNjdENEYyOTdEMDQ4OUQwM0Q1OTdFRjczMEQA';
  const scrollableDivRef = useRef(null);
  const msgRefs = useRef({});
  const [allAttributesName, setAllAttributesName] = useState([]);
  const [fetchedAttributes, setFetchedAttributes] = useState([]);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const res = await getCustomAttrByNameforMapping(authtoken, allAttributesName);
        if (res?.flag) {
          setFetchedAttributes(res?.data);
        }
      } catch (err) {
        console.log('error', err);
      }
    }
    if (allAttributesName?.length) {
      fetchAttributes();
    }
  }, [allAttributesName]);

  console.log("agent data", agentdata, agentdetails)

  // Scroll to the message with _id matching contextMsg[0]?._id
  const scrollToMsg = (_id) => {
    if (msgRefs.current[_id]) {
      msgRefs.current[_id].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const scrollMSg = (_id) => {
    if (msgRefs.current[_id]) {
      msgRefs.current[_id].scrollIntoView({ behavior: 'smooth', block: 'center' });
      setHighlightedMsgId(_id);

      // Remove highlight after animation
      setTimeout(() => {
        setHighlightedMsgId(null);
      }, 2000);
    } else {
      loadMore();
    }
  };

  useEffect(() => {
    let timeoutId;

    if (chatData?.msg_id && !loadingChatScreen && messages?.length > 0) {
      timeoutId = setTimeout(() => {
        scrollMSg(chatData.msg_id);
      }, 500);
    }


    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [messages, chatData?.msg_id, loadingChatScreen]);
  // useEffect(() => {
  //   if (!MobileWidth) {
  //     setShowSideBar(true);
  //   }
  // }, []);

  const [showButton, setShowButton] = useState(false);
  const handleScroll = (event) => {
    const target = event.target; // This is the scrollable div

    const { scrollTop, clientHeight, scrollHeight } = target;
    console.log('herere', scrollTop, clientHeight, scrollHeight);
    if (scrollTop >= -100) {
      setShowButton(false);
    } else {
      const isScrolledAwayFromBottom = scrollHeight - scrollTop - clientHeight > 300;

      setShowButton(isScrolledAwayFromBottom);
    }
  };
  const scrollToBottom = () => {
    setShowButton(false);

    const scrollableDiv = document.getElementById('scrollableDiv');
    if (scrollableDiv) {
      scrollableDiv.scrollTo({
        top: scrollableDiv.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const [isTyping, setIsTyping] = useState(false);

  let typingTimeout;
  const handleTyping = () => {
    if (!isTyping) {
      const name = agentdata?.name ?? localdata?.name;
      const conversationId = chatData._id;
      socket.emit('startTyping', { name, conversationId });
      setIsTyping(true);
    }
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => handleStopTyping(), 3000);
  };

  const handleStopTyping = () => {
    setIsTyping(false);
    const name = agentdata?.name ?? localdata?.name;
    const conversationId = chatData?._id;
    socket.emit('stopTyping', { name, conversationId });
  };

  useEffect(() => {
    const typingTimeout = setTimeout(() => {
      if (isTyping) {
        handleStopTyping();
      }
    }, 3000);
    return () => clearTimeout(typingTimeout);
  }, [isTyping]);

  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const suffix =
      ['th', 'st', 'nd', 'rd'][day % 10 > 3 ? 0 : (((day % 100) - (day % 10) != 10) * day) % 10] ||
      'th';
    return `${day}${suffix} ${month}, ${year}`;
  }

  // logic for getContextMessage
  // function getContextMessage(msgId) {
  //   if (!msgId) {
  //     return null;
  //   }
  //   const filteredMsg = messages?.filter((msg) => msg.msg_id === msgId);
  //   return filteredMsg;
  // }

  useEffect(() => {
    if (chatData) {
      const convStartTime = new Date(chatData.time);
      const currentTime = new Date();
      const diffInMilliseconds = currentTime - convStartTime;
      const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
      setTimeDifference(diffInMinutes);
      setTimeLeft(1440 - diffInMinutes);

      const intervalId = setInterval(() => {
        setTimeDifference((prevDiff) => prevDiff + 1);
      }, 60000);
      return () => {
        clearInterval(intervalId);
      };
    }
    if (chatData?.user_details?.phone) {
      indAttributesAPIcall();
      getDetails();
    }
  }, [chatData?._id]);

  useEffect(() => {
    if (chatData?.user_details?.phone) {
      indAttributesAPIcall();
      getDetails();
    }
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  // useEffect(() => {
  //   if (textareaRef.current) {
  //     textareaRef.current.focus();
  //   }
  // });

  const getAllResponsesApiCall = () => {
    getAllRespones(token).then((res) => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
      if (res?.flag) {
        setValues(res?.data);
        //setSelected(res?.data[0]);
      } else {
        toast.error(res);
      }
    });
  };

  useEffect(() => {
    if (chatData?.agent) {
      setAgentName(chatData?.agent?.name);
    }
  }, [chatData?.agent]);

  const getDetails = () => {
    getCampignCount(authtoken, chatData?.user_details?.phone).then((res) => {
      if (res?.flag) {
        setDetails(res?.data);
      } else {
        // toast.error(res?.message);
      }
    });
  };

  const downloadFormCsvApiCall = (msgId) => {
    downloadFormCsv(msgId, authtoken).then((res) => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `form-data.csv`);
        document.body.appendChild(link);
        link.click();
        toast.success('File downloaded', { position: 'top-center' });
      } else {
        toast.error(res?.message, { position: 'top-center' });
      }
    });
  };

  const handleStatusChange = (value) => {
    const data = {
      convId: chatData?._id,
      status: value,
    };
    toggleStatus(authtoken, data)
      .then((res) => {
        if (res?.flag) {
          setChatData({
            ...chatData,
            status: value,
          });
          //  setChatStatus(value);
          if (value === 'Resolved') {
            setResumeWorkflow(false);
          }
          // setInboxList([]);
          // getInboxListing(1);
        } else {
          toast.error(res?.message, { position: 'top-center' });
        }
      })
      .catch((err) => {
        toast.error(err?.message, { position: 'top-center' });
      });
  };

  const blockConversationApiCall = () => {
    const convId = chatData?._id;
    blockConversation(authtoken, convId)
      .then((res) => {
        console.log('res', res);
        // setInboxList((prev) => {
        //   let newArr = prev.filter((item) => !item?._id !== convId);
        //   return newArr;
        // });
      })
      .catch((err) => {
        toast.error(err?.message, { position: 'top-center' });
      });
  };

  /* useEffect(() => {
    if (messages.length <= 50) {
      scrollToBottom();
    }
  }, []); */

  const toggleStatusApiCall = () => {
    const data = {
      phoneNumber: chatData?.user_details?.phone,
      convId: chatData?._id,
      value: false,
    };
    eventsend('Resume_workflow_Inbox_page_clicked');
    toggleInterveneStatus(data, authtoken).then((res) => {
      if (res?.data) {
        setActiveWorkflow(true);
        setResumeWorkflow(false);
      } else {
        // setResumeWorkflow(true);
      }
    });
  };

  const parseErrorLog = (errorLog) => {
    try {
      const parsed = JSON.parse(errorLog ?? '{}');
      return parsed?.message ?? errorLog ?? 'NA';
    } catch {
      return errorLog ?? 'NA';
    }
  };

  const handleAssignedAgentChange = (e) => {
    const value = Object.values(agentData).filter((item) => item?.name === e.target.value)[0];
    if (!value) {
      toast.error('No agent found', { position: 'top-center' });
    }
    const data = {
      agentId: value._id,
      convId: chatData?._id,
    };
    toggleAgent(authtoken, data)
      .then((res) => {
        if (res?.flag) {
          setAgentName(e.target.value);
          setAgent({
            id: value._id,
            name: value.name,
          });
        } else {
          toast.error(res?.message, { position: 'top-center' });
        }
      })
      .catch((err) => {
        toast.error(err?.message, { position: 'top-center' });
      });
  };

  const formatInput = () => {
    setText((prev) => {
      const temp = prev;
      temp.replace(/\*([^*]+)\*/g, `<span style={{color:blue}}>changeddddd</span>`);
      return temp;
    });
  };

  const setAttrTextAction = (text) => {
    if (/%\w+%/.test(text)) {
      const lines = text.split('\n'); // Splitting only on newline characters
      const modifiedLines = lines.map((line) => {
        const words = line.split(/\s+/); // Splitting each line into words
        return words
          .map((word) => {
            if (word.startsWith('%') && word.endsWith('%') && word.length > 2) {
              var TrimWord = word.substring(1, word.length - 1);
              if (TrimWord == 'name') {
                return chatData?.user_details?.name ?? 'NA';
              } else if (TrimWord == 'phone') {
                return chatData?.user_details?.phone ?? 'NA';
              } else if (TrimWord == 'email') {
                return details?.email ?? 'NA';
              } else if (TrimWord in individualAttributes) {
                if (individualAttributes[TrimWord]) {
                  return individualAttributes[TrimWord];
                } else {
                  // Attribute value is missing
                  toast.error(`Attribute value is missing for: ${TrimWord}`, {
                    position: 'top-right',
                  });
                  return word;
                }
              } else {
                // attribute doesn't exist
                toast.error(`Attribute doesn't exist: ${TrimWord}`, {
                  position: 'top-right',
                });
                return word;
              }
            } else {
              return word;
            }
          })
          .join(' '); // Joining words back with space separator
      });

      const modifiedText = modifiedLines.join('\n'); // Joining lines back with newline characters

      setTimeout(() => {
        setText(modifiedText);
        document.getElementById('textInputAttr').focus();
      }, 200);
    } else {
      setText(text);
    }
  };

  const AttributesCheck = (value) => {
    if (/%\w+%/.test(value)) {
      const lines = value.split('\n'); // Splitting only on newline characters
      const modifiedLines = lines.map((line) => {
        const words = line.split(/\s+/); // Splitting each line into words
        return words
          .map((word) => {
            if (word.startsWith('%') && word.endsWith('%') && word.length > 2) {
              var TrimWord = word.substring(1, word.length - 1);
              if (TrimWord == 'name') {
                return chatData?.user_details?.name ?? 'NA';
              } else if (TrimWord == 'phone') {
                return chatData?.user_details?.phone ?? 'NA';
              } else if (TrimWord == 'email') {
                return details?.email ?? 'NA';
              } else if (TrimWord in individualAttributes) {
                return individualAttributes[TrimWord] ?? word;
              } else {
                // attribute doesn't exist
                return word;
              }
            } else {
              return word;
            }
          })
          .join(' '); // Joining words back with space separator
      });

      const modifiedString = modifiedLines.join('\n'); // Joining lines back with newline characters
      setText(modifiedString);
    } else {
      setText(value);
    }
  };

  useEffect(() => {
    if (text) {
      let currentWord = getWordAtCursor();
      if (currentWord[0] === '%' && currentWord?.length > 1) {
        setIAFilterValue(currentWord?.substring(1));
      } else {
        setIAFilterValue('');
      }
    }
  }, [text]);

  const selectedAttributeAction = (value) => {
    let currentWord = getWordAtCursor();
    setText((prev) => {
      let editedText = prev.replace(currentWord, value);
      return editedText;
    });
    document.getElementById('textInputAttr').focus();
    setOpenAttributes(false);
  };

  function getWordAtCursor() {
    const input = document.getElementById('textInputAttr');
    const cursorPosition = input.selectionStart;
    // Get the text content of the input field
    const text = input.value;
    // Find the start and end indices of the current word
    let startIndex = cursorPosition;
    let endIndex = cursorPosition;
    // Move startIndex to the beginning of the word
    while (startIndex > 0 && !isWordBoundary(text[startIndex - 1])) {
      startIndex--;
    }
    // Move endIndex to the end of the word
    while (endIndex < text.length && !isWordBoundary(text[endIndex])) {
      endIndex++;
    }
    // Extract the current word
    const currentWord = text.substring(startIndex, endIndex);
    return currentWord;
  }

  function isWordBoundary(char) {
    // Check if the character is a space or newline
    return char === ' ' || char === '\n';
  }

  const styleText = (text) => {
    // Split the text into words
    const words = text.split(/\s+/);

    // Initialize an empty array to hold the styled elements
    const styledElements = [];

    // Loop through each word
    words.forEach((word, index) => {
      // Check if the word matches the criteria for styling
      if (word.startsWith('%') && word.endsWith('%') && word.length > 2) {
        // If yes, style the word and add it to the styled elements array
        styledElements.push(
          <span key={index} style={{ color: 'red' }}>
            {word}&nbsp;
          </span>
        );
      } else {
        // If no, add the word as it is to the styled elements array
        styledElements.push(word + ' ');
      }
    });

    // Return the array of styled elements
    return styledElements;
  };


  useEffect(() => {
    getAllResponsesApiCall();
    // if(text.length === 0){
    //   setShowMatchedResponse(false);
    // }
    // else if(text === "/" && text.length === 1){
    //   setShowMatchedResponse(true);
    // }else{

    //   const newArr= values.filter((value)=>{
    //     if(value?.response.match(text)){
    //       return value;
    //     }
    //   })
    //   if(newArr.length>0){
    //     setValues(newArr);
    //     setShowMatchedResponse(true);
    //   }else{
    //     setShowMatchedResponse(false);
    //   }
    // }
  }, [showSavedResponse]);

  useEffect(() => {
    if (mediaContent) {
      Helper.base64(mediaContent, function (data) {
        setImgBase64('data:image/png;base64,' + data.base64);
      });
      // const reader = new FileReader();
      // reader.onloadend = () => {
      //     setPreview(reader.result);
      // }
      // reader.readAsDataURL(mediaContent);
      // setLoading2(true);
      // s3UpVideoApiCall();
    } else {
      // setPreview(null);
    }
  }, [mediaContent]);

  const sendNewMessageAction = () => {
    //replace %variable% with actual value
    handleStopTyping();
    const updatedText = text.replace(/%\w+%/g, (match) => {
      const key = match.slice(1, -1);
      if (key === 'name') {
        return chatData?.user_details?.name ?? 'NA';
      } else if (key === 'phone') {
        return chatData?.user_details?.phone ?? 'NA';
      } else if (key === 'email') {
        return details?.email ?? 'NA';
      } else if (key in individualAttributes) {
        return individualAttributes[key] ?? 'NA';
      } else {
        return 'NA';
      }
    });
    console.log('sada', replyToMsg?.msg_id);

    if (buttonMsg?.length > 0) {
      const msg = {
        userId: chatData?.merchant_details?.sender_id,
        data: {
          mobile: chatData?.user_details?.phone,
          filename: headerFileName ? headerFileName : '',
          fileURL: s3Link ? s3Link : '',
          fileType: mediaType,
          body: updatedText,
          buttons: buttonMsg,
          repliedBy: agentdetails ? agentdetails?.name : localdata?.name,
          context: {
            messageId: replyToMsg?.msg_id,
            from: replyToMsg?.name,
          },
        },
      };

      socket.emit('interactiveMsg', msg);
    } else {
      const msg = {
        user_id: chatData?.merchant_details?.sender_id,
        phone_number: chatData?.user_details?.phone,
        filename: headerFileName ? headerFileName : '',
        fileURL: s3Link ? s3Link : '',
        type: mediaType,
        context: context,
        // image: imgBase64,
        body: updatedText,
        repliedBy: agentdetails ? agentdetails?.name : localdata?.name,
        status: 'pending',
        context: {
          messageId: replyToMsg?.msg_id,
          from: replyToMsg?.name,
        },
      };
      // setMessages([...messages, msg]);

      socket.emit('chatMessage', msg);
    }
    setReplyToMsg(null);
    setShowReplyToOption(false);
    setText('');
    setS3Link('');
    setHeaderFileName('');
    setUpFile(null);
    setUpFile64('');
    setButtonMsg([]);
  };
  /* 
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }; */

  const handlefileupload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (
      file.type === 'image/jpg' ||
      'image/jpeg' ||
      'image/png' ||
      'video/*' ||
      'application/msword' ||
      'text/csv' ||
      'application/pdf' ||
      'application/vnd.ms-powerpoint' ||
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      'application/vnd.ms-excel' ||
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      ''
    ) {
      setUpFile(file);
      setHeaderFileName(file.name);
      const base64 = await convertToBase64(file);
      setUpFile64(base64);
    } else {
      toast.error('wrong file format uploaded, please try again later', {
        position: 'top-center',
      });
    }
  };

  useEffect(() => {
    if (upFile?.type?.split('/')[0] === 'image') {
      setMediaType('image');
    } else if (upFile?.type?.split('/')[0] === 'video') {
      setMediaType('video');
    } else if (upFile?.type?.split('/')[0]) {
      setMediaType('document');
    } else {
      setMediaType('');
    }
  }, [upFile]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (upFile64) {
      getS3Link();
    }
  }, [upFile64]);

  const getS3Link = () => {
    const id = toast.loading('Uploading file. Please wait.');
    let data = {
      image: upFile64,
      type: upFile?.type?.split('/')[0],
      format: upFile?.type?.split('/')[1],
    };
    let token = authtoken;
    V2WorkflowS3Link(data, token).then((res) => {
      if (res) {
        setS3Link(res?.data?.url);
        toast.update(id, {
          render: 'file uploaded successfully',
          type: 'success',
          autoClose: true,
          isLoading: false,
        });
        // toast.success('file uploaded successfully',{position:'top-center'});
      } else {
        toast.update(id, {
          render: 'file uploaded failed, please try again later',
          type: 'error',
          autoClose: true,
          isLoading: false,
        });
        // toast.error('file uploaded failed, please try again later',{position:'top-center'});
      }
    });
  };

  const indAttributesAPIcall = () => {
    let token = authtoken;
    let data = {
      id: chatData?.user_details?.phone,
    };
    getIndAttributesSidebar(token, data).then((res) => {
      if (res?.flag) {
        const keys = Object.keys(res?.data || {});
        setAllAttributesName(keys);
        setIndividualAttributes(res?.data);
      } else {
      }
    });
  };
  // useEffect(()=>{
  //   if(messages){
  //     const element = document.getElementById('messageEnd');
  //         if(element){
  //             element.scrollIntoView({behavior: 'smooth'});
  //         }
  //   }
  // },[messages])

  // const handleKeyDown = (event) => {
  //   const newVal = filteredVal.indexOf(selected);
  //   if(filteredVal){
  //   if(filteredVal[newVal+1] && filteredVal[newVal-1]){
  //     setSelected(filteredVal[0]);
  //   }

  //   if(event.code === 'Tab'){
  //     event.preventDefault();
  //     setText(selected?.response);
  //   }else if(event.code === 'Enter'){

  //   }
  //   else if(event.code === 'ArrowDown'){

  //     if(filteredVal[newVal+1]){
  //       setSelected(filteredVal[newVal+1]);
  //       document.getElementById("cr"+filteredVal[newVal+1]?._id)?.scrollIntoView({behavior:"smooth"});
  //     }
  //   }else if(event.code === 'ArrowUp'){

  //     if(filteredVal[newVal-1]){
  //       setSelected(filteredVal[newVal-1]);
  //       document.getElementById("cr"+filteredVal[newVal-1]?._id)?.scrollIntoView({behavior:"smooth"});

  //     }
  //   }
  // if (event.key === 'Enter' && event.shiftKey) {
  // } else if (event.key === 'Enter') {
  //   sendNewMessageAction();
  // }
  // }
  // };

  const handleEmojiSelect = (emoji) => {
    const currentText = text;
    const cursorPosition = textareaRef.current.selectionStart;
    const newText =
      currentText.substring(0, cursorPosition) +
      emoji.native +
      currentText.substring(cursorPosition);

    setText(newText);

    // Move the cursor position after the inserted emoji
    const newCursorPosition = cursorPosition + emoji.native.length;
    // textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    setTimeout(() => {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const messagesFromUserExist = Boolean(
    messages.some((msg) => msg?.receiver_id == chatData?.merchant_details?.sender_id)
  );

  const customMenuProps = {
    PaperProps: {
      sx: {
        borderRadius: '10px',
      },
    },
  };

  const postCommentApiCall = () => {
    const postData = {
      name: commenter?.name,
      phoneNumber: chatData?.user_details?.phone,
      text: commentText,
    };

    postComment(authtoken, postData).then((res) => {
      if (res?.flag) {
        setComments([...res?.data?.comments]);
        setCommentText('');
      } else {
        toast.error(res?.message);
      }
    });
  };

  const is24 = !chatData?.merchant_reply;
  const blocked = chatData?.blocked?.status;
  const isResolved = chatData?.status?.toLowerCase() === 'resolved';

  if (!chatData) {
    return (
      <div style={{ ...styles.noChatData.container, display: MobileWidth ? 'none' : 'flex' }}>
        <div style={styles.noChatData.centerSection}>
          <img src={images.WAInboxDeskPeople} width={280}></img>
          <h4 style={{ fontSize: '16px', fontWeight: 600, marginTop: '6px' }}>
            WhatsApp Team Inbox
          </h4>
          <p style={styles.noChatData.p}>
            Send & receive messages from your customers directly from the dashboard.
          </p>
        </div>
        <div style={styles.noChatData.bottomSection}>
          <IoLockClosedOutline style={styles.noChatData.tinyIcon} />
          End-to-end-encrypted
        </div>
      </div>
    );
  }

  return (
    <>
      {!isChatLoad ? (
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                fontSize: 15,
                fontWeight: 'bolder',
                color: colors.black,
                margin: 0,
              }}
            >
              Either you have no replies yet or you haven't set up webhooks for WhatsApp yet
            </p>
            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0, fontSize: 14, color: colors.darkgrey }}>
                To check the steps for setting webhooks
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: 14,
                  color: colors.linkblue,
                  textDecoration: 'underline',
                  marginLeft: 5,
                }}
                onClick={() => {}}
              >
                click here
              </p>
            </div> */}
            <p
              style={{
                margin: 0,
                fontSize: 14,
                color: colors.darkgrey,
                marginTop: 30,
              }}
            >
              If you've already set up webhooks, sit back and relax, while you wait for your
              customers to reply.
            </p>
            <p
              style={{
                margin: 0,
                fontSize: 16,
                color: colors.darkgrey,
                marginTop: 5,
              }}
            >
              OR
            </p>
            <p
              style={{
                margin: 0,
                fontSize: 14,
                color: colors.linkblue,
                textDecoration: 'underline',
                marginLeft: 5,
              }}
              onClick={() => {
                navigate('/creator/announcements');
              }}
            >
              Start a new campaign
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              height: 50,
              width: `100%`,
              backgroundColor: colors.white,
              marginTop: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, fontSize: 14, color: colors.darkgrey }}>
              Once you have configured the webhook on meta developer console you'll be able to see
              your replies here
            </p>
          </div>
        </div>
      ) : loadingChatScreen ? (
        <div className="spinner_loader" style={{ marginTop: '30%' }}>
          <SpinnerLoader />
        </div>
      ) : (
        <div style={styles.chatScreen.container}>
          <div
            style={{
              ...styles.chatScreen.header,
              flexDirection: MobileWidth ? 'column' : 'row',
              height: MobileWidth ? 'auto' : 60,
              paddingInline: MobileWidth ? 8 : 16,
              paddingBlock: MobileWidth ? 4 : 10,
              // padding: '16px 10px',
              // gap: '6px',
            }}
          >
            <div
              className="d-flex flex-row justify-content-start align-items-center"
              style={{
                width: MobileWidth ? '100%' : 'auto',
                marginBlock: MobileWidth ? 8 : 0,
              }}
            >
              {MobileWidth && (
                <img
                  src={images.BackArrowBlack}
                  style={{
                    height: 20,
                    width: 20,
                    objectFit: 'contain',
                    cursor: 'pointer',
                    marginInlineEnd: 8,
                  }}
                  onClick={() => {
                    mobileChangeScreen('inbox');
                  }}
                ></img>
              )}
              <h3
                style={{
                  ...styles.chatScreen.headerTitle,
                  width: MobileWidth ? '100%' : 'auto',
                }}
              >
                {chatData?.user_details?.name
                  ? `${excepted
                    ? hiddenName(chatData?.user_details?.name)
                    : chatData?.user_details?.name
                  } (${excepted
                    ? hiddenPhone(chatData?.user_details?.phone)
                    : chatData?.user_details?.phone
                  })`
                  : excepted
                    ? hiddenPhone(chatData?.user_details?.phone)
                    : chatData?.user_details?.phone}
              </h3>
            </div>
            {!is24 && timeLeft > 0 && (
              <div
                className="habuild-timer"
                style={{ display: MobileWidth ? 'none' : '', marginInlineStart: '15px' }}
              >
                <CountdownRectangularTimer
                  duration={1440 * 60}
                  initialRemainingTime={timeLeft * 60}
                  colors={['#04B100', '#ffc000', '#ffc000', '#ffc000']}
                  colorsTime={[1440 * 60, 960 * 60, 480 * 60, 0]}
                />
              </div>
            )}
            <div
              style={{
                ...styles.chatScreen.headerActionItems,
                // padding: MobileWidth ? 0 : '10px',
                justifyContent: MobileWidth ? 'space-around' : 'end',
                width: MobileWidth ? '100%' : 'auto',
              }}
            >
              <Select
                value={agentName ?? 'Unassigned'}
                onChange={(e) => {
                  eventsend('Assign_agent_Inbox_page_clicked');
                  handleAssignedAgentChange(e);
                }}
                MenuProps={customMenuProps}
                sx={{
                  height: '32px',
                  color: 'black',
                  width: 'auto',
                  borderRadius: '8px',
                  border: 0,
                  backgroundColor: colors.grey,
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                    // ,'1px solid #000000',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                    // '1px solid #000000',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'black',
                  },
                }}
                renderValue={(selected) => (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    <div
                      style={{
                        height: 18,
                        width: 18,
                        borderRadius: '50%',
                        backgroundColor: '#5E7C80',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <p style={{ fontSize: '12px', fontWeight: '600' }}>
                        {selected.substring(0, 1).toUpperCase()}
                      </p>
                    </div>
                    <p style={{ fontSize: '12px', fontWeight: '600' }}>{selected}</p>
                  </div>
                )}
              >
                <p style={{ fontSize: '12px', fontWeight: '600', paddingLeft: '10px' }}>
                  Assign chat
                </p>

                {agentData &&
                  Object.values(agentData)
                    .filter((option) => {
                      if (agentDet?.role === 'agent') return true;
                      if (agentDet?.role === 'sales') return option?.role === 'sales';
                      return true;
                    })
                    .map((option) => (
                      // <MenuItem value={option?.name}>{option?.name}</MenuItem>
                      <MenuItem
                        value={option?.name}
                        sx={{
                          height: '32px',
                          color: 'black',
                          width: 'auto',
                          borderRadius: '8px',
                          border: 0,
                          margin: '10px',
                          backgroundColor: colors.grey,
                          '& .MuiSvgIcon-root': {
                            color: 'black',
                          },
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 0',
                            gap: '5px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px',
                            }}
                          >
                            <div
                              style={{
                                height: 18,
                                width: 18,
                                borderRadius: '50%',
                                backgroundColor: '#5E7C80',
                                color: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <p style={{ fontSize: '12px', fontWeight: '600', margin: 0 }}>
                                {option?.name.substring(0, 1).toUpperCase()}
                              </p>
                            </div>
                            <p style={{ fontSize: '12px', fontWeight: '600', margin: 0 }}>
                              {option?.name}
                            </p>
                          </div>
                          <span
                            style={{
                              height: '8px',
                              width: '8px',
                              backgroundColor:
                                option?.activity == 'online'
                                  ? '#04B100'
                                  : option?.activity == 'away'
                                    ? '#FEAF52'
                                    : '#FF0000',
                              borderRadius: '50%',
                              display: 'inline-block',
                            }}
                          ></span>
                        </div>
                      </MenuItem>
                    ))}
              </Select>
              <Select
                className="resolveDiv d-flex"
                value={chatData?.status ?? 'Unresolved'}
                onChange={(e) => {
                  e.stopPropagation();
                  eventsend('Chat_status_Inbox_page_clicked');
                  handleStatusChange(e.target.value);
                }}
                MenuProps={customMenuProps}
                sx={{
                  height: '32px',
                  width: 'auto',
                  borderRadius: '8px',
                  fontSize: '12px',
                  fontWeight: '600',
                  backgroundColor:
                    chatData.status.toLowerCase() === 'resolved'
                      ? '#D6FFD6'
                      : chatData.status.toLowerCase() === 'unresolved'
                        ? '#D2F5FF'
                        : chatData.status.toLowerCase() === 'pending'
                          ? '#FFDEB7'
                          : 'grey',
                  color:
                    chatData.status.toLowerCase() === 'resolved'
                      ? '#5CB85C'
                      : chatData.status.toLowerCase() === 'unresolved'
                        ? '#5BC0DE'
                        : chatData.status.toLowerCase() === 'pending'
                          ? '#D77400'
                          : 'grey',
                  '& .MuiSvgIcon-root': {
                    color:
                      chatData.status.toLowerCase() === 'resolved'
                        ? '#5CB85C'
                        : chatData.status.toLowerCase() === 'unresolved'
                          ? '#5BC0DE'
                          : chatData.status.toLowerCase() === 'pending'
                            ? '#D77400'
                            : 'grey',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                }}
                renderValue={(selected) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: '12px', fontWeight: '600' }}>
                      {selected === 'Unresolved' ? 'Open' : selected}
                    </p>
                  </div>
                )}
              >
                <p style={{ fontSize: '12px', fontWeight: '600', paddingLeft: '10px' }}>
                  Set chat status
                </p>

                <MenuItem
                  value={'Unresolved'}
                  sx={{
                    height: '32px',
                    color: 'black',
                    width: 'auto',
                    borderRadius: '8px',
                    border: 0,
                    margin: '10px',
                    backgroundColor: '#D2F5FF !important',
                    '& .MuiSvgIcon-root': {
                      color: '#5BC0DE',
                    },
                    '&:hover': { backgroundColor: '#D2F5FF' },
                    display: 'flex',
                    alignItems: 'center',
                    '& .statusText': {
                      fontSize: '12px',
                      fontWeight: '600',
                    },
                    '& .statusDot': {
                      height: '8px',
                      width: '8px',
                      backgroundColor: '#5BC0DE',
                      borderRadius: '50%',
                      display: 'inline-block',
                    },
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      gap: '20px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p className="statusText">{'Open'}</p>
                    <span className="statusDot"></span>
                  </div>
                </MenuItem>

                <MenuItem
                  value={'Resolved'}
                  sx={{
                    height: '32px',
                    color: 'black',
                    width: 'auto',
                    borderRadius: '8px',
                    border: 0,
                    margin: '10px',
                    backgroundColor: '#D6FFD6 !important',
                    '&:hover': { backgroundColor: '#D6FFD6' },
                    '& .MuiSvgIcon-root': {
                      color: '#5CB85C',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    '& .statusText': {
                      fontSize: '12px',
                      fontWeight: '600',
                    },
                    '& .statusDot': {
                      height: '8px',
                      width: '8px',
                      backgroundColor: '#5CB85C',
                      borderRadius: '50%',
                      display: 'inline-block',
                      marginLeft: .80,
                    },
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p className="statusText">{'Resolved'}</p>
                    <span className="statusDot"></span>
                  </div>
                </MenuItem>

                <MenuItem
                  value={'Pending'}
                  sx={{
                    height: '32px',
                    color: 'black',
                    width: 'auto',
                    borderRadius: '8px',
                    border: 0,
                    margin: '10px',
                    backgroundColor: '#FFDEB8 !important',
                    '&:hover': { backgroundColor: '#FFDEB8' },
                    '& .MuiSvgIcon-root': {
                      color: '#D77400',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    '& .statusText': {
                      fontSize: '12px',
                      fontWeight: '600',
                    },
                    '& .statusDot': {
                      height: '8px',
                      width: '8px',
                      backgroundColor: '#D77400',
                      borderRadius: '50%',
                      display: 'inline-block',
                    },
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p className="statusText">{'Pending'}</p>
                    <span className="statusDot"></span>
                  </div>
                </MenuItem>
                {/* <MenuItem hidden style={{ fontSize: '12px', fontWeight: '400' }} value="Unresolved">
                  Unresolved
                </MenuItem>
                <MenuItem hidden style={{ fontSize: '12px', fontWeight: '400' }} value="Resolved">
                  Resolved
                </MenuItem>
                <MenuItem hidden style={{ fontSize: '12px', fontWeight: '400' }} value="Pending">
                  Pending
                </MenuItem> */}
              </Select>
              {!blocked && (
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    style={{ display: 'none', background: 'none' }}
                  ></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        setShowBlockConvModal(true);
                      }}
                    >
                      Spam & Block
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {/* {!MobileWidth &&  */}
              {/* {!showSideBar &&  */}
              <CheerioButton
                btnText={'Contact details'}
                textStyle={{ color: colors.primary03 }}
                icon={images.PurpleInfoIcon}
                iconStyle={{
                  height: MobileWidth ? 14 : 18,
                  width: MobileWidth ? 14 : 18,
                  marginInlineEnd: MobileWidth ? 4 : 8,
                }}
                backColor={colors.white01}
                borderStyle={{
                  border: `1px solid ${colors.darkPurple}`,
                  borderRadius: 8,
                  marginInline: MobileWidth ? 2 : 8,
                  paddingInline: MobileWidth ? 8 : 16,
                  paddingBlock: MobileWidth ? 4 : 4,
                  display: showSideBar ? 'none' : 'flex',
                }}
                id="contactDetails"
                onclick={() => setShowSideBar(true)}
              />
              {/* } */}

              {/* <Button
                id="contactDetails"
                onClick={() => setShowSideBar(true)}
                style={{
                  border: '1px solid ' + colors.darkPurple,
                  padding: '4px 8px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  borderRadius: '8px',
                  display: showSideBar ? 'none' : 'flex',
                }}
              >
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: '500',
                    textWrap: 'nowrap',
                    color: colors.primary03,
                  }}
                >
                  Contact details
                </p>
                <img src={images.PurpleInfoIcon} style={{ height: 18, width: 18 }}></img>
              </Button> */}
              {/* } */}
              <div
                style={{
                  width: '360px',
                  display: showSideBar ? 'flex' : 'none',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingLeft: '4%',
                }}
              >
                <div style={styles.chatScreen.sidebarHeaderActionItems}>
                  <Tooltip title="Campaigns">
                    <CardImg
                      id="Campaigns"
                      src={images.AnnouncementIcon}
                      onClick={(e) => {
                        setSidebarView('campaigns');
                      }}
                      style={{
                        cursor: 'pointer',
                        height: 30,
                        width: 30,
                        borderRadius: '10px',
                        padding: '6px',
                        backgroundColor: colors.grey,
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Media">
                    <CardImg
                      src={images.GalleryIcon}
                      onClick={() => {
                        setSidebarView('media');
                      }}
                      style={{
                        cursor: 'pointer',
                        height: 30,
                        width: 30,
                        borderRadius: '10px',
                        padding: '6px',
                        backgroundColor: colors.grey,
                      }}
                    />
                  </Tooltip>
                </div>
                <Button
                  onClick={() => setShowSideBar(false)}
                  style={{
                    border: '1px solid ' + colors.darkPurple,
                    padding: '4px 8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    borderRadius: '8px',
                  }}
                >
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '500',
                      textWrap: 'nowrap',
                      color: colors.primary03,
                    }}
                  >
                    Close details
                  </p>
                  <img src={images.PurpleCloseIcon} style={{ height: 18, width: 18 }}></img>
                </Button>
              </div>
            </div>
          </div>
          {resumeWorkflow && (
            <div
              className="d-flex justify-content-between"
              style={{
                alignItems: 'center',
                flexDirection: MobileWidth ? 'column' : 'row',
                backgroundColor: colors.white,
                borderBottom: '1px solid #E6E6E6',
                paddingInline: MobileWidth ? 8 : 16,
                paddingBlock: MobileWidth ? 4 : 12,
                boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.2)',
                zIndex: '2',
              }}
            >
              <p style={{ fontSize: '12px', fontWeight: '600', width: '70%' }}>
                Workflows for this chat have been stopped due to you intervention. Please make sure
                to reactivate the workflow once the chat is completed else no workflow will trigger
                for this chat.
              </p>
              {!MobileWidth && (
                <CheerioButton
                  btnText={'Reactivate Workflow'}
                  textStyle={{ color: colors.black, fontSize: '12px' }}
                  backColor={colors.white}
                  borderStyle={{
                    border: '1px solid black',
                    marginInlineEnd: MobileWidth ? 0 : 8,
                    paddingInline: MobileWidth ? 8 : 20,
                  }}
                  onclick={toggleStatusApiCall}
                />
              )}
            </div>
          )}
          <div
            style={{
              ...styles.chatScreen.body,
              display: 'flex',
              overflowY: 'auto',
              flex: 1,
              position: 'relative',
            }}
          >
            <div
              id="scrollableDiv"
              className="verythinscrollnew"
              style={{
                ...styles.chatScreen.messagesArea,
                overflowY: 'auto',
                flex: 1,
                // border: '1px solid red',
                backgroundImage: `url(${BackgroundImage})`,
                display: MobileWidth && showSideBar ? 'none' : 'flex',
                position: 'relative',
              }}
            >
              <InfiniteScroll
                dataLength={messages?.length}
                next={loadMore}
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  overflowY: 'hidden',
                  position: 'relative',
                }}
                inverse={true}
                onScroll={handleScroll}
                hasMore={more}
                loader={<h4 className="align-self-center">Loading...</h4>}
                scrollableTarget="scrollableDiv"
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>No more messages</b>
                  </p>
                }
              >
                {messages?.length > 0 &&
                  messages?.map((_msg) => {
                    const isSend = _msg?.sender_id == chatData?.merchant_details?.sender_id;
                    const contextMsg = [_msg?.context?.message];
                    let total = 0;
                    let currency = '';
                    let date = new Date(_msg?.timestamp);
                    return (
                      <div
                        onClick={() => {
                          setShowEmoji(false);
                        }}
                      >
                        {_msg?.type !== 'event' && (
                          <div
                            key={_msg?._id}
                            ref={(el) => (msgRefs.current[_msg._id] = el)}
                            className={`d-flex flex-row  ${isSend ? `justify-content-end` : 'justify-content-start'
                              }  px-2 py-2 mx-auto message-container`}
                            style={{
                              width: _msg?.carousel?.length > 0 ? '70%' : '95%',
                              padding: '10px',
                              borderRadius: '11px',
                              animation: highlightedMsgId === _msg._id ? 'highlightFade 2s ease' : 'none',
                              ...getHighlightStyle(highlightedMsgId === _msg._id)
                            }}
                          >
                            {isSend && (
                              <div
                                className="d-flex flex-row justify-content-end align-items-center"
                                style={{
                                  alignItems: 'center',
                                  marginRight: '20px',
                                  cursor: 'pointer',
                                }}
                              >
                                <div
                                  className="reply-icon"
                                  onClick={() => {
                                    setShowReplyToOption(true);
                                    setReplyToMsg({ ..._msg, isSend: isSend });
                                  }}
                                  style={{
                                    paddingBlock: '3px',
                                    paddingInline: '10px',
                                    border: '1px solid #666666',
                                    borderRadius: '17px',
                                    display: 'flex', // Make the div a flex container
                                    justifyContent: 'center', // Center horizontally
                                    alignItems: 'center', // Center vertically
                                  }}
                                >
                                  <LuReply size={20} color="#666666" />
                                </div>
                              </div>
                            )}
                            <div
                              style={{
                                width: _msg?.carousel?.length > 0
                                  ? '65%'
                                  : (_msg?.buttons && _msg?.buttons.length !== 0)
                                    ? '60%'
                                    : '',
                                maxWidth: '70%',
                                backgroundColor: isSend ? '#5068F2' : colors?.white,
                                borderRadius: 8,
                                padding: 10,
                                alignItems: 'center',
                                marginRight: _msg?.carousel?.length > 0 ? '-100px' : ''
                              }}
                            >
                              {/* {_msg?.type === 'image'} */}
                              {(_msg?.type === 'image' || _msg?.type === 'Image') &&
                                (_msg?.image || _msg?.fileURL) ? (
                                // <HEImages
                                //   userGeneratedSrc={getImagePath(_msg.image)}
                                //   style={{
                                //     maxHeight: 200,
                                //     objectFit: 'cover',
                                //     borderRadius: 8,
                                //     marginBottom: 8,
                                //   }}
                                // />
                                <CardImg
                                  // src={he.encode(getImagePath(_msg.image))}
                                  src={
                                    _msg?.image
                                      ? getImagePath(_msg.image)
                                      : getImagePath(_msg.fileURL)
                                  }
                                  style={{
                                    maxHeight: 200,
                                    width: '100%',
                                    objectFit: 'cover',
                                    borderRadius: 8,
                                    marginBottom: 8,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    let imageSrc = _msg?.image
                                      ? getImagePath(_msg.image)
                                      : getImagePath(_msg.fileURL);
                                    window.open(imageSrc, '_blank');
                                  }}
                                />
                              ) : (_msg?.type === 'video' || _msg?.type === 'Video') &&
                                _msg?.fileURL ? (
                                <>
                                  <video
                                    src={getImagePath(_msg.fileURL)}
                                    controls={true}
                                    style={{
                                      maxHeight: 200,
                                      width: '100%',
                                      objectFit: 'cover',
                                      marginBottom: 8,
                                    }}
                                  ></video>
                                </>
                              ) : (_msg?.type === 'audio' || _msg?.type === 'Audio') &&
                                _msg?.fileURL ? (
                                <>
                                  <audio
                                    src={getImagePath(_msg.fileURL)}
                                    controls={true}
                                    style={{
                                      borderRadius: '5px',
                                      border: '1px solid rgb(241, 243, 244)',
                                      background: 'rgb(241, 243, 244)',
                                    }}
                                  ></audio>
                                </>
                              ) : (_msg?.type === 'document' || _msg?.type === 'Document') &&
                                _msg?.fileURL ? (
                                <>
                                  <div
                                    className="d-flex flex-row justify-content-between align-items-center w-100 py-2 px-2 mb-2"
                                    style={{ backgroundColor: colors.white02, borderRadius: 4 }}
                                  >
                                    <BsFileEarmark size={14} />
                                    <div style={{ flex: 1, marginInline: 10 }}>
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          color: colors.black,
                                          width: '100%',
                                        }}
                                      >
                                        {_msg?.filename}
                                      </p>
                                    </div>
                                    <BsDownload
                                      size={14}
                                      onClick={() => {
                                        window.open(_msg?.fileURL, '_blank');
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                _msg?.image && (
                                  <CardImg
                                    src={getImagePath(_msg.image)}
                                    style={{
                                      maxHeight: 200,
                                      objectFit: 'cover',
                                      borderRadius: 8,
                                      marginBottom: 8,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      let imageSrc = _msg?.image
                                        ? getImagePath(_msg.image)
                                        : getImagePath(_msg.fileURL);
                                      window.open(imageSrc, '_blank');
                                    }}
                                  />
                                )
                              )}
                              {_msg?.formData?.response_json && (
                                <>
                                  <div
                                    className="d-flex flex-row justify-content-between align-items-center w-100 py-2 px-2 mb-2"
                                    style={{ backgroundColor: colors.white02, borderRadius: 4 }}
                                  >
                                    <BsFileEarmark size={14} />
                                    <div style={{ flex: 1, marginInline: 10 }}>
                                      <p
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          color: colors.black,
                                          width: '100%',
                                        }}
                                      >
                                        {'Form Response.csv'}
                                      </p>
                                    </div>
                                    <BsDownload
                                      size={14}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        downloadFormCsvApiCall(_msg?.msg_id);
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                              {_msg?.order?.catalog_id ? (
                                <div
                                  style={{
                                    width: '30vw',
                                    backgroundColor: '#FAF9F6',
                                    color: 'black',
                                    borderRadius: '8px',
                                    padding: '10px',
                                    flex: 'row',
                                    alignSelf: 'center',
                                  }}
                                >
                                  <div style={{ textAlign: 'center' }}>Product Items</div>
                                  <hr></hr>

                                  <p style={{ fontSize: '12px' }}>
                                    Catalog Id: {_msg?.order?.catalog_id}
                                  </p>
                                  {_msg?.order?.product_items.map((product, index) => {
                                    total =
                                      total +
                                      parseFloat(
                                        parseFloat(Number(product.item_price)) *
                                        Number(product.quantity)
                                      );
                                    currency = product.currency;
                                    return (
                                      <div key={index}>
                                        <p>{index + 1} item</p>
                                        <div
                                          style={{ fontSize: '13px', marginTop: '' }}
                                          className="d-flex justify-content-between"
                                        >
                                          <p>Product Id: {product.product_retailer_id}</p>
                                          <div style={{ marginRight: '10px' }}>
                                            <p>{product.currency + ' ' + product.item_price}</p>
                                            <p style={{ marginTop: '' }}>
                                              Quantity: {product.quantity}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <hr></hr>
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <p>Subtotal</p>
                                      <p style={{ fontSize: '12px', marginTop: '', color: 'grey' }}>
                                        {date.toLocaleString()}
                                      </p>
                                    </div>
                                    <p>{currency + ' ' + total} (estimated)</p>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div
                                style={{
                                  display: 'flex',
                                  alignSelf: 'end',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  // justifyContent: "flex-end",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 14,
                                    color: isSend ? colors.white01 : colors.black,
                                    fontWeight: 'normal',
                                    margin: 0,
                                    padding: 0,
                                    alignSelf: 'flex-start',
                                    marginRight: 20,
                                    whiteSpace: 'pre-line',
                                    flexWrap: 'wrap',
                                    wordBreak: 'break-all',
                                    width: '100%',
                                  }}
                                >
                                  {contextMsg && contextMsg?.length > 0 && contextMsg[0] && (
                                    <div
                                      onClick={() => scrollToMsg(contextMsg[0]?._id)}
                                      style={{
                                        background: isSend
                                          ? '#89A3FF78' // Blue background if condition is true
                                          : '#FAFAFA', // Blue background for the alternate case
                                        width: '100%',
                                        marginBlockEnd: '10px',
                                        padding: '8px',
                                        borderRadius: '8px',
                                        position: 'relative', // So we can position the white stripe
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <div
                                        style={{
                                          backgroundColor:
                                            contextMsg[0]?.name !== chatData?.user_details?.name
                                              ? '#89A3FF78' // Blue background if condition is true
                                              : '#FAFAFA', // White highlight
                                          width: '5px', // Adjust the width for the highlight
                                          position: 'absolute',
                                          top: 0,
                                          bottom: 0,
                                          left: 0,
                                          borderTopLeftRadius: '8px',
                                          borderBottomLeftRadius: '8px',
                                        }}
                                      />

                                      <p
                                        style={{
                                          fontSize: '12px',
                                          paddingInline: '8px',
                                          color: isSend ? '#FAFAFA' : '#666666',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {(contextMsg[0]?.type === 'video' ||
                                          contextMsg[0]?.type === 'Video') &&
                                          contextMsg[0]?.fileURL ? (
                                          <div>
                                            <video
                                              src={getImagePath(contextMsg[0]?.fileURL)}
                                              controls={false}
                                              style={{
                                                maxHeight: 200,
                                                width: '100%',
                                                objectFit: 'cover',
                                                marginBottom: 8,
                                              }}
                                            ></video>
                                            {contextMsg[0]?.msg ?? ''}
                                          </div>
                                        ) : (contextMsg[0]?.type === 'image' ||
                                          contextMsg[0]?.type === 'Image') &&
                                          (contextMsg[0]?.image || contextMsg[0]?.fileURL) ? (
                                          <div>
                                            <CardImg
                                              src={getImagePath(contextMsg[0]?.image)}
                                              style={{
                                                objectFit: 'cover',
                                                borderRadius: 8,
                                                marginBottom: 8,
                                              }}
                                            />
                                            {contextMsg[0]?.msg ?? ''}
                                          </div>
                                        ) : (contextMsg[0]?.type === 'audio' ||
                                          contextMsg[0]?.type === 'Audio') &&
                                          contextMsg[0]?.fileURL ? (
                                          <div>
                                            <PiFileAudioThin
                                              size={14}
                                              style={{ marginRight: '4px' }}
                                            />
                                            {contextMsg[0]?.msg ?? ''}
                                          </div>
                                        ) : (contextMsg[0]?.type === 'document' ||
                                          contextMsg[0]?.type === 'Document') &&
                                          contextMsg[0]?.fileURL ? (
                                          <div>
                                            <BsFileEarmark
                                              size={14}
                                              style={{ marginRight: '4px' }}
                                            />
                                            {contextMsg[0]?.msg ?? ''}
                                          </div>
                                        ) : (
                                          contextMsg[0]?.msg
                                        )}
                                      </p>
                                    </div>
                                  )}

                                  {_msg?.msg}
                                </p>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignSelf: 'flex-end',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  marginLeft: 'auto',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <p
                                  style={{
                                    margin: 0,
                                    textAlign: 'end',
                                    fontSize: 10,
                                    color: isSend ? colors.white : colors.black,
                                  }}
                                >
                                  {`${formatDate(date)} ${date.toLocaleTimeString(undefined, {
                                    hour: 'numeric',
                                    minute: '2-digit',
                                    hour12: true,
                                  })}`}
                                </p>
                                {isSend ? (
                                  // <CardImg
                                  //   src={images?.tickSent}
                                  //   style={{
                                  //     height: 8,
                                  //     width: 10,
                                  //     margin: 0,
                                  //     marginLeft: 4,
                                  //     marginBottom: 2,
                                  //   }}
                                  // />
                                  _msg?.status === 'pending' ? (
                                    <CiClock2 style={{ color: 'white' }} />
                                  ) : _msg?.status === 'sent' || _msg?.status === 'Q' ? (
                                    <IoCheckmark style={{ color: 'white' }} />
                                  ) : _msg?.status === 'delivered' ? (
                                    <BsCheck2All style={{ color: 'white' }} />
                                  ) : (
                                    _msg?.status === 'read' && (
                                      <BsCheck2All style={{ color: '#00FFB2' }} />
                                    )
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            {!isSend && (
                              <div
                                className="d-flex flex-row justify-content-end align-items-center "
                                style={{
                                  alignItems: 'center',
                                  marginLeft: '20px',
                                  cursor: 'pointer',
                                }}
                              >
                                <div
                                  className="reply-icon"
                                  style={{
                                    paddingBlock: '3px',
                                    paddingInline: '10px',
                                    border: '1px solid #666666',
                                    borderRadius: '17px',
                                    display: 'flex', // Make the div a flex container
                                    justifyContent: 'center', // Center horizontally
                                    alignItems: 'center', // Center vertically
                                  }}
                                >
                                  <LuReply
                                    size={20}
                                    color="#666666"
                                    onClick={() => {
                                      setShowReplyToOption(true);
                                      setReplyToMsg({ ..._msg, isSend: isSend });
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        <div>
                          {_msg?.carousel && _msg?.carousel?.length > 0 && (
                            <CarouselMessage carouselItems={_msg.carousel} />
                          )}
                        </div>
                        {_msg.buttons.map((button) => (
                          <div
                            className={`d-flex flex-row  ${isSend ? `justify-content-end` : 'justify-content-start'
                              }  px-1 py-1 mx-auto`}
                            style={{
                              width: '95%',
                              paddingRight: '0.75rem !important',
                              paddingLeft: '0.5rem !important',
                            }}
                          >
                            <div
                              style={{
                                width: '60%',
                                backgroundColor: isSend ? '#5068F2' : colors?.white,
                                borderRadius: 8,
                                padding: 10,
                                alignSelf: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignSelf: 'end',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 14,
                                    width: '100%',
                                    color: isSend ? colors.white01 : colors.black,
                                    fontWeight: 'normal',
                                    textAlign: 'center',
                                    whiteSpace: 'pre-line',
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  {button}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                        {_msg?.type === 'event' && (
                          <div
                            className="d-flex justify-content-center"
                            style={
                              {
                                // justifyContent: "flex-end",
                              }
                            }
                          >
                            <p
                              style={{
                                fontSize: 12,
                                color: colors.black,
                                borderRadius: '5px',
                                backgroundColor: '#F0F0F0',
                                fontWeight: 'normal',
                                border: 'none',
                                alignSelf: 'center',
                                fontWeight: '400',
                                paddingInline: '2%',
                                paddingBlock: '4px',
                                whiteSpace: 'pre-line',
                                flexWrap: 'wrap',
                                margin: '4px',
                              }}
                            >
                              {_msg.msg}
                            </p>
                          </div>
                        )}
                        {_msg?.errorLog && (
                          <div
                            className="d-flex justify-content-end"
                            style={{
                              fontStyle: 'italic',
                              fontSize: 16,
                              width: '97%',
                              marginBottom: '2px',
                              color: '#FF0000',
                              alignItems: 'center',
                            }}
                          >
                            <Tooltip placement="top" title={parseErrorLog(_msg?.errorLog)}>
                              <div>
                                <AiOutlineInfoCircle style={{ marginRight: '5px' }} />
                              </div>
                            </Tooltip>
                            <u>{'Failed to send'}</u>
                          </div>
                        )}
                        {_msg?.type !== 'event' &&
                          !_msg?.errorLog &&
                          isSend &&
                          _msg?.repliedBy &&
                          _msg?.repliedBy !== '' && (
                            <p
                              style={{
                                textAlign: 'end',
                                fontStyle: 'italic',
                                fontSize: 12,
                                width: '97%',
                                marginTop: '-2px',
                                color: 'black',
                              }}
                            >
                              {_msg?.repliedBy.toLowerCase() === 'workflows' ? `Sent via ` : `Sent by `}
                              {_msg?.repliedBy === 'user' ? localdata?.name : _msg?.repliedBy}
                            </p>
                          )}
                      </div>
                    );
                  })}
                {showButton && (
                  <button
                    onClick={scrollToBottom}
                    className="scroll-to-bottom-button"
                    style={{
                      position: 'fixed', // Fixed positioning so the button stays visible
                      backgroundColor: '#ECE1FF', // White background
                      borderRadius: '50%', // Circular button
                      padding: '10px', // Padding inside the button
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Light shadow for visibility
                      cursor: 'pointer', // Pointer cursor on hover
                      zIndex: 1000, // Ensure it stays on top of other elements
                      border: 'none',
                    }}
                  >
                    <GoArrowDown size={24} color="#9357FF" />
                  </button>
                )}
              </InfiniteScroll>
            </div>
            <div
              className="verythinscrollnew"
              style={{
                overflowY: 'auto',
                display: showSideBar ? 'block' : 'none',
                width: MobileWidth ? '100%' : 'auto',
                borderLeft: '1px solid #E6E6E6',
              }}
            >
              <RepliesSideBar
                data={chatData}
                setShow={setShowSideBar}
                excepted={excepted}
                resolvedValue={chatData.status}
                handleStatusChange={handleStatusChange}
                comments={comments}
                setComments={setComments}
                attributes={individualAttributes}
                updateattributes={() => indAttributesAPIcall()}
                selected={sidebarView}
                setSelected={setSidebarView}
                currentUser={currentUser}
              />
            </div>
          </div>
          <div style={styles.chatScreen.footer}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: showSideBar && MobileWidth ? 'none' : 'flex',
                  alignItems: 'center',
                }}
              >
                {messagesFromUserExist ? (
                  blocked ? (
                    <UnblockChatBar
                      unblock={blockConversationApiCall}
                      chatData={chatData}
                    ></UnblockChatBar>
                  ) : isResolved ? (
                    <ResolveChatBar handleChatStatus={handleStatusChange}></ResolveChatBar>
                  ) : activeWorkflow ? (
                    <InterveneChat
                      setActiveWorkflow={setActiveWorkflow}
                      chatData={chatData}
                      setResumeWorkflow={setResumeWorkflow}
                    ></InterveneChat>
                  ) : is24 ? (
                    <ReInitiate
                      chatData={chatData}
                      attributesList={{
                        name: chatData?.user_details?.name,
                        phone: chatData?.user_details?.phone,
                        email: details?.email,
                        ...individualAttributes,
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      {s3Link ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '5%',
                            paddingTop: '1%',
                          }}
                        >
                          {mediaType === 'document' ? (
                            <>
                              <BsFileEarmark size={24} />
                            </>
                          ) : mediaType === 'image' ? (
                            <>
                              <img
                                src={getImagePath(s3Link)}
                                style={{ height: 40, width: 'auto', objectFit: 'contain' }}
                              />
                            </>
                          ) : (
                            mediaType === 'video' && (
                              <video
                                src={getImagePath(s3Link)}
                                style={{ height: 40, width: 'auto', objectFit: 'contain' }}
                              />
                            )
                          )}

                          {/* <p style={{ margin: 0, marginLeft: 16 }}>{mediaContent?.name}</p> */}
                          <p style={{ margin: 0, marginLeft: 16 }}>{headerFileName}</p>

                          <img
                            onClick={() => {
                              setUpFile(null);
                              setUpFile64('');
                              setS3Link('');
                              setHeaderFileName('');
                              // setImgBase64('');
                              // setMediaContent('');
                            }}
                            src={images.CMClose}
                            style={{
                              height: 24,
                              width: 24,
                              marginLeft: 'auto',
                              marginRight: '15%',
                              // padding: 10,
                              objectFit: 'contain',
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      {showReplyToOption && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '2%',
                            paddingTop: '1%',
                            width: '100%',
                          }}
                        >
                          <ReplyToOption
                            setShowReplyToOption={setShowReplyToOption}
                            _msg={replyToMsg}
                            setReplyToMsg={setReplyToMsg}
                          ></ReplyToOption>
                        </div>
                      )}

                      <div
                        className="d-inline-flex flex-row justify-content-around align-self-center align-items-center px-1 px-lg-2 py-2"
                        style={{
                          width: '100%',
                          marginBottom: '-2px',
                        }}
                      >
                        {blocked ? (
                          <UnblockChatBar unblock={blockConversationApiCall}></UnblockChatBar>
                        ) : isResolved ? (
                          <ResolveChatBar handleChatStatus={handleStatusChange}></ResolveChatBar>
                        ) : activeWorkflow ? (
                          <InterveneChat
                            setActiveWorkflow={setActiveWorkflow}
                            chatData={chatData}
                            setResumeWorkflow={setResumeWorkflow}
                          ></InterveneChat>
                        ) : is24 ? (
                          <SendTemplateBar setShowSendTemplateModal={setShowSendTemplateModal} />
                        ) : (
                          <div
                            style={{
                              flex: 1,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              gap: '8px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                borderBottomWidth: 1,
                                borderColor: colors.black,
                                alignItems: 'center',
                                backgroundColor: colors.white,
                                borderBottom: '1px solid #666666',
                              }}
                            >
                              <Tooltip title="Save your responses here">
                                <CardImg
                                  id="SavedResponses"
                                  onClick={() => setShowSavedResponses(true)}
                                  src={images.IconResponse}
                                  style={{
                                    height: MobileWidth ? 16 : 20,
                                    width: MobileWidth ? 16 : 20,
                                    cursor: 'pointer',
                                  }}
                                />
                              </Tooltip>
                              {showSavedResponse && (
                                <SavedResponses
                                  // setText={setText}
                                  setButtonMsg={setButtonMsg}
                                  setText={setAttrTextAction}
                                  setShow={setShowSavedResponses}
                                  setMedia={(fileURL, fileType, fileName) => {
                                    setS3Link(fileURL);
                                    if (fileType === 'image' || fileType === 'video') {
                                      setMediaType(fileType);
                                    } else {
                                      setMediaType('document');
                                    }
                                    setHeaderFileName(fileName);
                                  }}
                                  attributesList={{
                                    name: chatData?.user_details?.name,
                                    phone: chatData?.user_details?.phone,
                                    email: details?.email,
                                    ...individualAttributes,
                                  }}
                                />
                              )}
                              <CiFaceSmile
                                size={MobileWidth ? 18 : 28}
                                style={{
                                  cursor: 'pointer',
                                  marginInline: '8px',
                                }}
                                onClick={() => {
                                  setShowEmoji(!showEmoji);
                                  eventsend('Emoji_Inbox_page_clicked');
                                }}
                              />

                              {/* {text !== '' && !showSavedResponse && e && ( */}
                              {text !== '' &&
                                !showSavedResponse &&
                                !openAttributes &&
                                showMatchResponse &&
                                e && (
                                  <MatchResponses
                                    text={text}
                                    values={values}
                                    // setText={setText}
                                    setText={setAttrTextAction}
                                    setMedia={(fileURL, fileType, fileName) => {
                                      setS3Link(fileURL);
                                      if (fileType === 'image' || fileType === 'video') {
                                        setMediaType(fileType);
                                      } else {
                                        setMediaType('document');
                                      }
                                      setHeaderFileName(fileName);
                                    }}
                                    sendMessage={sendNewMessageAction}
                                    event={e}
                                    setShow={setShowMatchResponse}
                                    attributesList={{
                                      name: chatData?.user_details?.name,
                                      phone: chatData?.user_details?.phone,
                                      email: details?.email,
                                      ...individualAttributes,
                                    }}
                                    setButtonMsg={setButtonMsg}
                                  ></MatchResponses>
                                )}
                              {showEmoji && (
                                <div className="emojiMenu">
                                  <Picker
                                    data={data}
                                    emojiSize={20}
                                    theme={'light'}
                                    previewPosition={'none'}
                                    onEmojiSelect={handleEmojiSelect}
                                  ></Picker>
                                </div>
                              )}

                              {openAttributes && (
                                <AttributesModal
                                  data={chatData}
                                  details={details}
                                  setOpenAttributes={setOpenAttributes}
                                  attributeObject={individualAttributes}
                                  selectedAttributeAction={selectedAttributeAction}
                                  filterValue={IAFilterValue}
                                  keyPressed={keyPressed}
                                  setKeyPressed={setKeyPressed}
                                  fetchedAttributes={fetchedAttributes || []}
                                />
                              )}
                              {typingUser && <div className="typing-indicator">{typingUser}</div>}
                              <TextareaAutosize
                                id="textInputAttr"
                                className="mainInput"
                                as="textarea"
                                placeholder="Type a message (Tip: Use / to quickly access saved replies.)"
                                aria-label="Type message"
                                aria-describedby="input text area"
                                required
                                value={text}
                                ref={textareaRef}
                                onChange={(e) => {
                                  // setText(e.target.value);
                                  eventsend('Type a message_Inbox_page_clicked');
                                  AttributesCheck(e.target.value);
                                  setShowMatchResponse(true);
                                }}
                                // onInput={() => AttributesCheck()}
                                // onKeyUp={handleStopTyping}
                                onKeyDown={(event) => {
                                  handleTyping();
                                  if (
                                    event.key === 'Backspace' &&
                                    text?.charAt(event.target.selectionStart - 1) === '%'
                                  ) {
                                    setOpenAttributes(false);
                                  } else if (event.key === 'ArrowUp' && openAttributes) {
                                    event.preventDefault();
                                    setKeyPressed('Up');
                                  } else if (event.key === 'ArrowDown' && openAttributes) {
                                    event.preventDefault();
                                    setKeyPressed('Down');
                                  } else if (event.key === 'Tab' && openAttributes) {
                                    event.preventDefault();
                                    setKeyPressed('Tab');
                                  } else if (event.key === '%' && event.shiftKey) {
                                    setOpenAttributes(true);
                                    // AttributesCheck();
                                    // indAttributesAPIcall();
                                  } else if (event.code === 'Space') {
                                    setOpenAttributes(false);
                                  } else if (event.key === 'Enter' && event.shiftKey) {
                                  } else if (event.code === 'Enter') {
                                    event.preventDefault();
                                    eventsend('Send__Inbox_page_clicked');
                                    sendNewMessageAction();
                                  }
                                  setE(event);
                                }}
                                style={{
                                  // resize: 'vertical',
                                  border: 0,
                                  color: colors.black,
                                  // minHeight: 40,
                                  height: MobileWidth ? 'auto' : 40,
                                  width: '100%',
                                  borderRadius: '0px',
                                }}
                                minRows={2}
                                maxRows={8}
                              />
                              <input
                                id="MediaUpload"
                                type="file"
                                style={{ display: 'none' }}
                                ref={imageinputref}
                                accept="image/png, image/gif, image/jpeg, video/*"
                                onChange={(e) => {
                                  handlefileupload(e);
                                  eventsend('Image__Inbox_page_clicked');
                                }}
                              />
                              <input
                                type="file"
                                id="DocUpload"
                                style={{ display: 'none' }}
                                ref={documentRef}
                                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.pdfx,.csv"
                                onChange={(e) => {
                                  handlefileupload(e);
                                }}
                              />

                              <CardImg
                                onClick={() => documentRef.current.click()}
                                src={images.CRdocumentSelect}
                                style={{
                                  cursor: 'pointer',

                                  height: MobileWidth ? 18 : 24,
                                  width: MobileWidth ? 18 : 24,
                                  marginRight: MobileWidth ? 4 : 5,
                                  marginLeft: MobileWidth ? 4 : 5,
                                }}
                              />
                              <CardImg
                                onClick={() => imageinputref.current.click()}
                                src={images.CRphotoSelect}
                                style={{
                                  cursor: 'pointer',
                                  height: MobileWidth ? 18 : 24,
                                  width: MobileWidth ? 18 : 24,
                                  marginLeft: MobileWidth ? 4 : 5,
                                  marginRight: MobileWidth ? 4 : 5,
                                }}
                              />
                              <img
                                src={images.BoltButton}
                                onClick={() => setShowSendTemplateModal(true)}
                                id="Template-sender"
                                style={{
                                  cursor: 'pointer',

                                  height: MobileWidth ? 18 : 26,
                                  width: MobileWidth ? 18 : 26,
                                  marginRight: MobileWidth ? 4 : 5,
                                }}
                              />
                              <CardImg
                                onClick={() => {
                                  setShowTriggerFlowModal(true);
                                }}
                                src={images.IconAutomation}
                                style={{
                                  cursor: 'pointer',
                                  height: MobileWidth ? 18 : 24,
                                  width: MobileWidth ? 18 : 24,
                                  marginLeft: MobileWidth ? 4 : 4,
                                  marginRight: MobileWidth ? 4 : 20,
                                }}
                              />
                              {showTriggerFlowModal && (
                                <TriggerWorkflow
                                  setShowTriggerWorkflow={setShowTriggerFlowModal}
                                  chatData={chatData}
                                  resumeWorkflow={toggleStatusApiCall}
                                  positionStyling={{
                                    right: MobileWidth ? '0%' : showSideBar ? '30%' : '4%',
                                    width: MobileWidth ? '100%' : '25%',
                                  }}
                                />
                              )}
                            </div>
                            <CheerioButton
                              btnText={MobileWidth ? '' : 'Send'}
                              textStyle={{ color: colors.white }}
                              icon={images.msgSent}
                              iconStyle={{
                                height: MobileWidth ? 14 : 14,
                                width: MobileWidth ? 14 : 14,
                                marginInlineEnd: MobileWidth ? 4 : 8,
                              }}
                              backColor={colors.darkPurple}
                              borderStyle={{
                                borderWidth: 0,
                                marginInlineEnd: MobileWidth ? 0 : 8,
                                paddingInline: MobileWidth ? 8 : 20,
                              }}
                              id="SendWhatsAppMsgButton"
                              onclick={sendNewMessageAction}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                ) : (
                  <SendTemplateBar setShowSendTemplateModal={setShowSendTemplateModal} />
                )}
              </div>

              <div
                style={{
                  width: MobileWidth ? '100%' : '360px',
                  height: '100%',
                  display: showSideBar ? 'flex' : 'none',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '4px',
                    width: '100%',
                    gap: '8px',
                    paddingRight: '16px',
                  }}
                >
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      placeholder="Add a comment"
                      style={{
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        borderColor: '#B3B3B3',
                        paddingInlineStart: 0,
                        zIndex: 1,
                        outline: 'none',
                        boxShadow: 'none',
                        padding: '10px',
                      }}
                      onFocus={(e) => {
                        e.target.style.outline = 'none'; // Remove outline when focused
                        e.target.style.borderColor = '#B3B3B3';
                      }}
                      value={commentText}
                      onChange={(e) => {
                        setCommentText(e.target.value);
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && event.shiftKey) {
                        } else if (event.code === 'Enter') {
                          event.preventDefault();
                          if (commentText) {
                            eventsend('Add a comment_Side_bar_Inbox_page_clicked');
                            postCommentApiCall();
                          }
                        }
                        setE(event);
                      }}
                    />
                  </InputGroup>
                  <div
                    style={{
                      height: 32,
                      width: 32,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: colors.grey,
                      borderRadius: '8px',
                      padding: '6px',
                      cursor: 'pointer',
                    }}
                  >
                    <BsSend
                      style={{
                        height: 20,
                        width: 'auto',
                      }}
                      onClick={() => {
                        if (commentText) {
                          postCommentApiCall();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showSendTemplateModal && (
            <SendTemplateModal
              show={showSendTemplateModal}
              setShow={setShowSendTemplateModal}
              attributesList={{
                name: chatData?.user_details?.name,
                phone: chatData?.user_details?.phone,
                email: details?.email,
                ...individualAttributes,
              }}
            />
          )}
          {blockConvModal && (
            <WaChatModal
              show={blockConvModal}
              setShow={setShowBlockConvModal}
              heading={'Block Conversation'}
              body={'Are you sure you want to block conversation?'}
              btnText={'Yes, Block'}
              apiCall={blockConversationApiCall}
            ></WaChatModal>
          )}
        </div>
      )}
    </>
  );
}


const highlightKeyframes = `
  @keyframes highlightFade {
    0% { background-color: #ECE1FF; }
    100% { background-color: transparent; }
  }
`;

// Add this style tag to your document head
const styleSheet = document.createElement('style');
styleSheet.innerText = highlightKeyframes;
document.head.appendChild(styleSheet);

const getHighlightStyle = (isHighlighted) => {
  if (isHighlighted) {
    return {
      backgroundColor: '#ECE1FF',
      transition: 'background-color 0.3s ease',
    };
  }
  return {
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
  };
};

const styles = {
  body: {},
  noChatData: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    centerSection: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
    },
    bottomSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 10,
      gap: 4,
    },
    p: {
      fontWeight: '400',
      fontSize: '16px',
      color: '#666666',
    },
    tinyIcon: {
      height: '18px',
      width: '20px',
    },
  },
  chatScreen: {
    container: {
      overflowY: 'hidden',
      display: 'flex',
      // flex: 1,
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colors.white,
      borderBottom: '1px solid #E6E6E6',
      justifyContent: 'space-between',
    },
    headerTitle: {
      margin: 0,
      fontWeight: 700,
      color: colors.black,
      fontSize: 16,
    },
    headerActionItems: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      gap: '8px',
    },
    body: {},
    messagesArea: {
      display: 'flex',
      flexDirection: 'column-reverse',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#F5F5F5',
    },
    sidebarHeaderActionItems: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      gap: '8px',
    },
    footer: {
      width: '100%',
      borderTop: '1px solid #E6E6E6',
    },
  },
};
