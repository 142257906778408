import { LinkedinIcon } from 'react-share';

/* eslint-disable import/no-anonymous-default-export */
export default {
  Carousel1: require('../Images/carouselcard/Home0001.png'),
  Carousel2: require('../Images/carouselcard/Home0002.png'),
  Carousel3: require('../Images/carouselcard/Home0003.png'),
  Carousel4: require('../Images/carouselcard/Home0004.png'),
  HeaderLogo: require('../Images/header/Cheerio_header.png'),
  FNExplore: require('../Images/footernav/Explore_icon_not.png'),
  FNExploreSel: require('../Images/footernav/Explore_icon_sel.png'),
  FNClub: require('../Images/footernav/Club_icon_not.png'),
  FNClubSel: require('../Images/footernav/Club_icon_sel.png'),
  FNProfile: require('../Images/footernav/Profile_icon_not.png'),
  FNProfileSel: require('../Images/footernav/Profile_icon_sel.png'),
  HomeLogo: require('../Images/home/Cheerio_app_logo.png'),
  DownArrow: require('../Images/home/down.png'),
  HomeButton: require('../Images/home/Googleplay_button.png'),
  ProfilePic: require('../Images/profile/ProfilePicPlaceholder.png'),
  Edit: require('../Images/profile/edit.png'),
  EditBlack: require('../Images/profile/edit_black.png'),
  Male: require('../Images/profile/male.png'),
  Female: require('../Images/profile/female.png'),
  Others: require('../Images/profile/others.png'),
  AddImage: require('../Images/profile/addImage.png'),
  LogOut: require('../Images/profile/logout.png'),
  Members: require('../Images/explore/members.png'),
  Messages: require('../Images/explore/messages.png'),
  Featured: require('../Images/explore/star.png'),
  Location: require('../Images/explore/location.png'),
  Upvote: require('../Images/explore/upvote.png'),
  UpvoteWhite: require('../Images/explore/upvotewhite.png'),
  Downvote: require('../Images/explore/downvote.png'),
  DownvoteWhite: require('../Images/explore/downvotewhite.png'),
  Back: require('../Images/clubchat/back.png'),
  BackgroundDoodle: require('../Images/clubchat/background.png'),
  General: require('../Images/clubchat/general.png'),
  Create: require('../Images/clubchat/create.png'),
  GeneralSel: require('../Images/clubchat/generalsel.png'),
  Events: require('../Images/clubchat/events.png'),
  Polls: require('../Images/clubchat/polls.png'),
  Paidlink: require('../Images/clubchat/paidlink.png'),
  Locked: require('../Images/clubchat/Locked.png'),
  Thumbsup: require('../Images/clubchat/handupGreen.png'),
  Thumbsdown: require('../Images/clubchat/handDownRed.png'),
  Gallery: require('../Images/clubchat/gallery.png'),
  Send: require('../Images/clubchat/send.png'),
  Share: require('../Images/clubchat/share.png'),
  NoData: require('../Images/myclubs/nodata.png'),
  CreatorLogo: require('../Images/creator/creatorLogo.png'),
  CreatorHome: require('../Images/creator/creatorHome.png'),
  CreatorWA: require('../Images/creator/creatorWA.png'),
  CreatorSMS: require('../Images/creator/creatorSMS.png'),
  CreatorImport: require('../Images/creator/creatorImport.png'),
  CreatorAnnouncement: require('../Images/creator/creatorAnnouncement.png'),
  CreatorNotification: require('../Images/creator/creatorNotification.png'),
  CreatorClose: require('../Images/creator/creatorClose.png'),
  CHWA: require('../Images/creator/CHWA.png'),
  CHSMS: require('../Images/creator/CHSMS.png'),
  CHImport: require('../Images/creator/CHImport.png'),
  CHAnnouncement: require('../Images/creator/CHAnnouncement.png'),
  CACourse: require('../Images/creator/CACourse.png'),
  CAProduct: require('../Images/creator/CAProduct.png'),
  CAOffer: require('../Images/creator/CAOffer.png'),
  CATemplate: require('../Images/creator/CATemplate.png'),
  CAHTML: require('../Images/creator/CAHTML.png'),
  CASampleImg: require('../Images/creator/CASampleImg.png'),
  BackArrowBlack: require('../Images/creator/backArrowBlack.png'),
  CAOEmail: require('../Images/creator/CAOEmail.png'),
  CTAEmail: require('../Images/creator/CTAEmail.png'),
  CTAWA: require('../Images/creator/CTAWA.png'),
  CTASMS: require('../Images/creator/CTASMS.png'),
  CTProfilePic: require('../Images/creator/creatorProfilePic.png'),
  CADefault: require('../Images/creator/CADefault.png'),
  CAEmailBack: require('../Images/creator/EmailBack.png'),
  CAEmailBottom: require('../Images/creator/EmailBottom.png'),
  CAWABack: require('../Images/creator/WABack.png'),
  CATickFilled: require('../Images/creator/tickfilled.png'),
  CATRAN: require('../Images/creator/Announce.png'),
  CATREmail: require('../Images/creator/Announceloading.png'),
  CATRSMS: require('../Images/creator/Announceloadingsms.png'),
  CATRWA: require('../Images/creator/AnnounceloadingWA.png'),
  CATSuccess: require('../Images/creator/CATSuccess.png'),
  CATFail: require('../Images/creator/CATFail.png'),
  CATProcess: require('../Images/creator/CATProcess.png'),
  CreatorSMSS: require('../Images/creator/creatorSMSS.png'),
  CreatorANA: require('../Images/creator/creatorAna.png'),

  CLLogo: require('../Images/creatorlogin/CLLogo.png'),
  CLImg1: require('../Images/creatorlogin/CLImg1.png'),
  CLImg2: require('../Images/creatorlogin/CLImg2.png'),
  CLCheck: require('../Images/creatorlogin/CLCheck.png'),
  CLBtnImg: require('../Images/creatorlogin/CLBtnImg.png'),
  CLfacebook: require('../Images/creatorlogin/CLfacebook.png'),
  CLgoogle: require('../Images/creatorlogin/CLgoogle.png'),
  CLMobile: require('../Images/creatorlogin/CLMobile.png'),
  CLAppStore: require('../Images/creatorlogin/CLAppStore.png'),
  CLPlayStore: require('../Images/creatorlogin/CLPlayStore.png'),

  CANdr: require('../Images/creator/CANdr.png'),
  CANor: require('../Images/creator/CANor.png'),
  CANlctr: require('../Images/creator/CANlctr.png'),
  CANlc: require('../Images/creator/CANlc.png'),
  CANsms: require('../Images/creator/CANsms.png'),
  CANemail: require('../Images/creator/CANemail.png'),
  CANwhatsapp: require('../Images/creator/CANwhatsapp.png'),
  WhatsAppSel: require('../Images/creator/WhatsAppSel.png'),
  CANunsub: require('../Images/creator/CANunsub.png'),
  CAWAlink: require('../Images/creator/CAWAlink.png'),
  DownArrowTip: require('../Images/creator/DownArrowTip.png'),
  ImgUpIcon: require('../Images/creator/ImgUpIcon.png'),
  InstagramImagePreview: require('../Images/workFlows/InstagramImagePreview.png'),
  VideoPreview: require('../Images/workFlows/VideoPreview.png'),
  AudioPreview: require('../Images/workFlows/AudioPreview.png'),
  ImageUploadNew: require('../Images/workFlows/ImageUploadNew.png'),
  CANnotenoughdata: require('../Images/creator/CANnotenoughdata.png'),
  NewSuccess: require('../Images/creator/NewSuccess.png'),
  NewSuccessGIF: require('../Images/creator/NewSuccessGIF.gif'),

  NHeaderIcon: require('../Images/creatorNew/headerIcon.png'),
  SNHome: require('../Images/creatorNew/SNHome.png'),
  SNHomeSel: require('../Images/creatorNew/SNHomeSel.png'),
  SNContacts: require('../Images/creatorNew/SNContacts.png'),
  SNContactsSel: require('../Images/creatorNew/SNContactsSel.png'),
  SNCampaigns: require('../Images/creatorNew/SNCampaigns.png'),
  SNCampaignsSel: require('../Images/creatorNew/SNCampaignsSel.png'),
  SNAnalytics: require('../Images/creatorNew/SNAnalytics.png'),
  SNAnalyticsSel: require('../Images/creatorNew/SNAnalyticsSel.png'),
  SNPLinks: require('../Images/creatorNew/SNPLinks.png'),
  SNPLinksSel: require('../Images/creatorNew/SNPLinksSel.png'),
  SNChatReply: require('../Images/creatorNew/SNChatReply.png'),
  SNChatReplySel: require('../Images/creatorNew/SNChatReplySel.png'),
  SNDesign: require('../Images/creatorNew/SNDesign.png'),
  SNDesignSel: require('../Images/creatorNew/SNDesignSel.png'),
  SNPAnalytics: require('../Images/creatorNew/SNPAnalytics.png'),
  SNPAnalyticsSel: require('../Images/creatorNew/SNPAnalyticsSel.png'),
  SNExploreT: require('../Images/creatorNew/SNExploreT.png'),
  SNExploreTSel: require('../Images/creatorNew/SNExploreTSel.png'),
  SNShopify: require('../Images/creatorNew/SNShopify.png'),
  SNShopifySel: require('../Images/creatorNew/SNShopifySel.png'),
  SNHelp: require('../Images/creatorNew/SNHelp.png'),
  Copy: require('../Images/creatorNew/Copy.png'),
  WARefreshToken: require('../Images/creatorNew/WARefreshToken.png'),
  CircleUncheckBlack: require('../Images/creatorNew/CircleUncheckBlack.png'),
  CircleCheckGreen: require('../Images/creatorNew/CircleCheckGreen.png'),
  CirclePending: require('../Images/creatorNew/CirclePending.png'),
  WhatsAppWhite: require('../Images/creatorNew/WhatsAppWhite.png'),
  FacebookWhite: require('../Images/creatorNew/FacebookWhite.png'),
  CalendarPrimary: require('../Images/creatorNew/CalendarPrimary.png'),

  HNWhatsApp: require('../Images/creatorNew/HNWhatsApp.png'),
  HNWRead: require('../Images/creatorNew/HNWRead.png'),
  HNSMS: require('../Images/creatorNew/HNSMS.png'),
  HNSRead: require('../Images/creatorNew/HNSRead.png'),
  HNCredits: require('../Images/creatorNew/HNCredits.png'),
  HNProfileSetup: require('../Images/creatorNew/HNProfileSetup.png'),
  PNPicture: require('../Images/creatorNew/PNPicture.png'),
  IPlusWhite: require('../Images/creatorNew/IPlusWhite.png'),
  ReqTemplate: require('../Images/creatorNew/ReqTemplate.png'),
  Edemologo: require('../Images/creatorNew/Edemologo.png'),
  Edemothumbnail: require('../Images/creatorNew/Edemothumbnail.png'),
  EThumbnail: require('../Images/creatorNew/EThumbnail.png'),
  ANExport: require('../Images/creatorNew/ANExport.png'),
  ANCalendar: require('../Images/creatorNew/ANCalendar.png'),
  CampaignWhite: require('../Images/creatorNew/CampaignWhite.png'),
  UploadWhite: require('../Images/creatorNew/UploadWhite.png'),
  HNUploadContacts: require('../Images/creatorNew/HNUploadContacts.png'),
  download: require('../Images/creatorNew/download.png'),
  searchBox: require('../Images/creatorNew/searchBox.png'),
  msgSent: require('../Images/creatorNew/msgSent.png'),
  whitetick: require('../Images/creatorNew/whitetick.png'),
  photoSelect: require('../Images/creatorNew/photoSelect.png'),
  youtubePlay: require('../Images/creatorNew/youtubePlay.png'),

  planIncluded1: require('../Images/planDetails/planIncluded1.png'),
  planIncluded2: require('../Images/planDetails/planIncluded2.png'),
  planIncluded3: require('../Images/planDetails/planIncluded3.png'),
  planNotIncluded: require('../Images/planDetails/planNotIncluded.png'),

  addonsLogo: require('../Images/addons/addonsLogo.png'),
  planLogo: require('../Images/addons/plan2.png'),
  emailLogo: require('../Images/addons/emailLogo.png'),
  whatsappLogo: require('../Images/addons/whatsappLogo.png'),
  walletLogo: require('../Images/addons/wallet.png'),
  smsLogo: require('../Images/addons/smsLogo.png'),
  tickLogo: require('../Images/planDetails/tickMark.png'),
  crossLogo: require('../Images/planDetails/crossMark.png'),
  alertIcon: require('../Images/addons/alert.png'),
  AIBall: require('../Images/templates/CheerioAIBall.png'),

  HNCampaign: require('../Images/creatorNew/HNCampaign.png'),
  DeleteBlue: require('../Images/creatorNew/DeleteBlue.png'),
  tickSent: require('../Images/creatorNew/tickSent.png'),
  user1: require('../Images/avtars/user1.png'),
  user2: require('../Images/avtars/user2.png'),
  user3: require('../Images/avtars/user3.png'),
  user4: require('../Images/avtars/user4.png'),
  ProfilePopup: require('../Images/creatorNew/ProfilePopup.png'),
  LogoutWhite: require('../Images/creatorNew/LogoutWhite.png'),
  CircleCheck: require('../Images/creatorNew/CircleCheck.png'),
  CircleUncheck: require('../Images/creatorNew/CircleUncheck.png'),
  CAFollowUp: require('../Images/creatorNew/CAFollowUp.png'),
  CARemainder: require('../Images/creatorNew/CARemainder.png'),
  CAThankYou: require('../Images/creatorNew/CAThankYou.png'),
  CAShare: require('../Images/creatorNew/CAShare.png'),
  CAAddDiscount: require('../Images/creatorNew/CAAddDiscount.png'),
  CARCOffer: require('../Images/creatorNew/CARCOffer.png'),
  CAWinback: require('../Images/creatorNew/CAWinback.png'),
  CABINews: require('../Images/creatorNew/CABINews.png'),
  CAWelcomeInsta: require('../Images/creatorNew/CAWelcomeInsta.png'),
  CALoyalCustomer: require('../Images/creatorNew/CALoyalCustomer.png'),
  CAThankYou2: require('../Images/creatorNew/CAThankYou2.png'),
  CADoYouKnow: require('../Images/creatorNew/CADoYouKnow.png'),
  CAMultiple: require('../Images/creatorNew/CAMultiple.png'),
  CADesignEmail: require('../Images/creatorNew/CADesignEmail.png'),
  FollowUpDemo: require('../Images/creatorNew/FollowUpDemo.png'),
  Webhook1: require('../Images/creatorNew/Webhook1.png'),
  Webhook2: require('../Images/creatorNew/Webhook2.png'),
  Webhook3: require('../Images/creatorNew/Webhook3.png'),
  WAOnlyTemplate: require('../Images/creatorNew/WAOnlyTemplate.png'),
  CheerioWALogo: require('../Images/creatorNew/CheerioWALogo.png'),
  DYKWA: require('../Images/creatorNew/DYKWA.png'),
  CAMaintenance: require('../Images/creatorNew/CAMaintenance.png'),
  CAServiceMarathi: require('../Images/creatorNew/CAServiceMarathi.png'),
  CAWAService: require('../Images/creatorNew/CAWAService.png'),
  Scheduler: require('../Images/creatorNew/Scheduler.png'),
  Refresh: require('../Images/creatorlogin/refresh.png'),

  CMClose: require('../Images/contactsNew/CMClose.png'),
  CMCloseWhite: require('../Images/contactsNew/CMCloseWhite.png'),
  CImage0: require('../Images/contactsNew/CImage0.png'),
  CImage1: require('../Images/contactsNew/CImage1.png'),
  CImage2: require('../Images/contactsNew/CImage2.png'),
  CImage3: require('../Images/contactsNew/CImage3.png'),
  CFile: require('../Images/contactsNew/CFile.png'),
  CDelete: require('../Images/contactsNew/CDelete.png'),
  CSampleDownload: require('../Images/contactsNew/CSampleDownload.png'),
  Privatelock: require('../Images/contactsNew/Privatelock.png'),

  SCog: require('../Images/shopifystore/Cog.png'),
  SCustomers: require('../Images/shopifystore/Customers.png'),
  SCustomersSel: require('../Images/shopifystore/CustomersSel.png'),
  SAnalytics: require('../Images/shopifystore/Analytics.png'),
  SAnalyticsSel: require('../Images/shopifystore/AnalyticsSel.png'),
  SFlows: require('../Images/shopifystore/Flows.png'),
  ShopifyLogo: require('../Images/shopifystore/ShopifyLogo.png'),
  HelpIcon: require('../Images/shopifystore/HelpIcon.png'),

  STOrderFulfilled: require('../Images/shopifystore/OrderFulfilled.png'),
  STCustomerFeedback: require('../Images/shopifystore/CustomerFeedback.png'),
  STAbandonedCart1: require('../Images/shopifystore/AbandonedCart1.png'),
  STOrderCancelled: require('../Images/shopifystore/OrderCancelled.png'),
  STOrderConfirmed: require('../Images/shopifystore/OrderConfirmed.png'),
  STAbandonedCart2: require('../Images/shopifystore/AbandonedCart2.png'),
  STReorder: require('../Images/shopifystore/Reorder.png'),
  STAbandonedCart3: require('../Images/shopifystore/AbandonedCart3.png'),
  STCODConfirmation: require('../Images/shopifystore/CODConfirmation.png'),
  STCODConfirmStatus: require('../Images/shopifystore/CODConfirmStatus.png'),
  STCODConfirmFollowup: require('../Images/shopifystore/CODConfirmFollowup.png'),
  InfoCircle: require('../Images/shopifystore/Info.png'),
  GreenCheck: require('../Images/shopifystore/greencheck.png'),
  ShopifyAnalytics: require('../Images/shopifystore/ShopifyAnalytics.png'),

  ChatBot: require('../Images/chatbot/primechatbot.png'),
  ChatBotSel: require('../Images/chatbot/primechatbotsel.png'),
  Upload: require('../Images/chatbot/upload.png'),
  Search: require('../Images/chatbot/Vectorsearch.png'),
  Pencil: require('../Images/chatbot/Vectorpencil.png'),
  ChatCP: require('../Images/chatbot/Vectorchatcopy.png'),
  Bin: require('../Images/chatbot/Vectorbin.png'),
  Mail: require('../Images/chatbot/Vectormail.png'),
  Call: require('../Images/chatbot/Vectorcall.png'),
  Link: require('../Images/chatbot/Vectorlink.png'),
  Trash: require('../Images/chatbot/trashtrash.png'),
  Loading: require('../Images/chatbot/eos-icons_loadingloading.png'),
  Check: require('../Images/chatbot/check_circlecheck.png'),
  Cross: require('../Images/chatbot/No- CrssCross.png'),

  Announce: require('../Images/creatorNew/megaphoneannounce.png'),
  ThreeDot: require('../Images/creatorNew/threedot.png'),

  disableTick: require('../Images/creatorNew/disableTick.png'),
  activeTick: require('../Images/creatorNew/activeTick.png'),
  Download_CSV: require('../Images/creatorNew/download.png'),
  InfoCircle: require('../Images/creatorNew/info-circle.png'),
  WalletIcon: require('../Images/creatorNew/walletIcon.png'),
  History: require('../Images/creatorNew/history.png'),
  UNSUCESS: require('../Images/creatorNew/unsucess.png'),
  SUCCESSFULL: require('../Images/creatorNew/successful.png'),
  FlowAnalytics: require('../Images/creatorNew/FlowAnalytics.png'),
  FilledPin: require('../Images/creatorNew/filled_pin.png'),

  Templates: require('../Images/templates/Vectortemplates.png'),
  TemplatesSel: require('../Images/templates/VectorselTemplate.png'),
  Heart: require('../Images/templates/heartheartt.png'),
  HeartSel: require('../Images/templates/heart-fillheart.png'),
  Explore: require('../Images/templates/searchsearch.png'),
  ExploreSel: require('../Images/templates/searchSelSearch.png'),
  Down: require('../Images/templates/angle-downDown.png'),
  Up: require('../Images/templates/angle-downup.png'),
  Envelope: require('../Images/templates/envelopewhatever.png'),
  Comments: require('../Images/templates/commentswhatever.png'),
  WhatsApp: require('../Images/templates/whatsappwhatever.png'),
  Feedback: require('../Images/templates/image 5feedback.png'),
  Ofullfilled: require('../Images/templates/image 8.png'),
  Ocancelled: require('../Images/templates/image 7.png'),
  Cart1: require('../Images/templates/image 9.png'),
  Cart2: require('../Images/templates/image 10.png'),
  Oconfirmed: require('../Images/templates/image 4.png'),
  Default: require('../Images/templates/Rectangle 1439default.png'),
  DeleteGrey: require('../Images/contactsNew/trashDeleteGery.png'),
  WhiteEnvelope: require('../Images/creatorNew/envelope.png'),
  Calendar: require('../Images/creatorNew/CalendarBlack.png'),
  SenderImage: require('../Images/creatorNew/senderImage.png'),

  SelManageSender: require('../Images/manageSender/sendManageSender.png'),
  ManageSender: require('../Images/manageSender/sendmanage.png'),
  EditSender: require('../Images/manageSender/pencilEdit.png'),
  Flag: require('../Images/manageSender/flag-fillflag.png'),
  Verified: require('../Images/manageSender/verifiedverfied.png'),
  NewLoader: require('../Images/creatorNew/Loading.gif'),

  CreateDesign: require('../Images/design/CreateDesign.png'),
  DesignDemo: require('../Images/design/demo.png'),
  DeleteBlack: require('../Images/design/Delete.png'),
  DesignDuplicate: require('../Images/design/Duplicate.png'),
  GoogleIcon: require('../Images/design/GoogleSheetIcon.png'),
  GoogleIcon: require('../Images/creator/GoogleSheetIcon.png'),

  RightArrow: require('../Images/creatorNew/Iconrightarrow.png'),
  IconTalk: require('../Images/creatorNew/Icontalk.png'),
  SelIconTalk: require('../Images/creatorNew/commentsselectedTalk.png'),

  IconMail: require('../Images/creatorNew/Iconmail.png'),
  IconIntegration: require('../Images/creatorNew/SNIntegration.png'),
  IconChatBot: require('../Images/creatorNew/Iconchatbot.png'),
  IconChatBotSel: require('../Images/creatorNew/IconChatBotSel.png'),
  IconIntegrationSel: require('../Images/creatorNew/SNIntegrationSel.png'),
  IconMailSel: require('../Images/creatorNew/envelopesel.png'),
  Video: require('../Images/creatorNew/play_3024584.png'),
  Pdf: require('../Images/creatorNew/pdf_4726010.png'),

  WFFlag: require('../Images/workFlows/WFFlag.png'),
  WFFlagSel: require('../Images/workFlows/WFFlagSel.png'),
  WFWhatsApp: require('../Images/workFlows/WFWhatsApp.png'),
  WFAnnounce: require('../Images/workFlows/WFAnnounce.png'),
  WFCursor: require('../Images/workFlows/WFCursor.png'),
  WFHand: require('../Images/workFlows/WFHand.png'),
  WFDefault: require('../Images/workFlows/WFDefault.png'),
  WFResponse: require('../Images/workFlows/WFResponse.png'),
  WFAT: require('../Images/workFlows/WFAT.png'),
  WFTime: require('../Images/workFlows/WFTime.png'),
  WFChatBot: require('../Images/workFlows/WFChatBot.png'),
  WFEmail: require('../Images/workFlows/WFEmail.png'),
  WFSMS: require('../Images/workFlows/WFSMS.png'),
  WFNewFlow: require('../Images/workFlows/WFNewFlow.png'),
  WFUnsubscribe: require('../Images/workFlows/WFUnsubscribe.png'),
  WFEditRed: require('../Images/workFlows/WFEditRed.png'),
  WFThreeDots: require('../Images/workFlows/WFThreeDots.png'),
  WFEnd: require('../Images/workFlows/WFEnd.png'),
  WFAgent: require('../Images/workFlows/WFAgent.png'),
  WFInstagram: require('../Images/workFlows/WFInstagram.png'),
  WFChatStatus: require('../Images/workFlows/WFChatStatus.png'),
  WFPabbly: require('../Images/workFlows/WFPabbly.png'),
  WFQRCode: require('../Images/workFlows/QRCode.png'),
  WFCheerio: require('../Images/workFlows/CheerioPowered.png'),
  WFGpt: require('../Images/workFlows/WFGpt.png'),
  WFLlama: require('../Images/workFlows/WFLlama.png'),
  PaymentReceipt: require('../Images/workFlows/payment_receipt.png'),
  Book: require('../Images/workFlows/book.png'),
  Bold: require('../Images/creatorNew/ic_twotone-format-boldbold.png'),
  Itlaic: require('../Images/creatorNew/material-symbols_format-italic-roundeditalic.png'),
  Strike: require('../Images/creatorNew/mdi_format-underlinestrike.png'),
  Mono: require('../Images/creatorNew/Frame 1475mono.png'),
  IconResponse: require('../Images/cannResponse/savedResponse.png'),
  IconAdd: require('../Images/cannResponse/add.png'),
  IconReply: require('../Images/cannResponse/reply.png'),
  IconCheck: require('../Images/cannResponse/checkcheck.png'),
  Left: require('../Images/templates/left.png'),
  Right: require('../Images/templates/right.png'),
  Dummy: require('../Images/creatorNew/dummyImage.png'),

  ProfilePicPlaceholder: require('../Images/manageProfile/ProfilePicPlaceholder.png'),
  MPPlus: require('../Images/manageProfile/MPPlus.png'),
  InviteModalPic: require('../Images/manageProfile/InviteModalPic.png'),
  UploadBlack: require('../Images/creatorNew/UploadBlack.png'),

  downloadWhite: require('../Images/creatorNew/downloadWhite.png'),
  paymentSetup: require('../Images/creatorNew/paymentSetup.png'),
  copyBlue: require('../Images/creatorNew/copyBlue.png'),
  Premium: require('../Images/creatorNew/Premium.png'),
  CampaignPlan: require('../Images/creatorNew/Campaign Plan.png'),
  BlackCircle: require('../Images/creatorNew/BlackCircle.png'),
  ShopifyPlan: require('../Images/creatorNew/shopify.png'),
  ChatBotPlan: require('../Images/creatorNew/chatbotplans.png'),
  WaInboxPlan: require('../Images/creatorNew/waplans.png'),
  WaTemplatePlan: require('../Images/creatorNew/templatePlan.png'),

  WooCommercePlan: require('../Images/creatorNew/wooComm.PNG'),

  woocommerceLogo: require('../Images/woocommerce/WooCommerce.png'),
  wooComShopLogo: require('../Images/woocommerce/woocommerceShop.png'),
  downArrow: require('../Images/creatorNew/ArrowWhite.png'),
  distribute: require('../Images/creatorNew/distribute.png'),
  filter: require('../Images/creatorNew/filter.png'),
  fillFilter: require('../Images/creatorNew/fillFilter.png'),
  ContactsPlans: require('../Images/creatorNew/ContactsPlans.png'),
  WooCommerceSel: require('../Images/creatorNew/WoocommerceSel.png'),
  EmailBuilderPlan: require('../Images/creatorNew/BuilderPlan.png'),
  SenderIdPlan: require('../Images/creatorNew/SenderPlan.png'),

  Filter: require('../Images/creatorNew/filter.png'),
  FilledFilter: require('../Images/creatorNew/fillFilter.png'),
  ArrowRight: require('../Images/creatorNew/RightArrow.png'),

  IconIntegration: require('../Images/creatorNew/IntegrationIcon.png'),
  IconIntegrationSel: require('../Images/creatorNew/IconIntegrationSel.png'),
  IconChatBot: require('../Images/creatorNew/Iconchatbot.png'),
  IconChatBotSel: require('../Images/creatorNew/IconChatBotSel.png'),
  IconAutomation: require('../Images/creatorNew/IconAutomation.png'),
  IconAutomationSel: require('../Images/creatorNew/IconAutomationSel.png'),
  SNForms: require('../Images/creatorNew/SNForms.png'),
  SNFormsSel: require('../Images/creatorNew/SNFormsSel.png'),
  Integration: require('../Images/creatorNew/Integration.png'),
  IntegrationSel: require('../Images/creatorNew/IntegrationSel.png'),
  IconWorkFlow: require('../Images/creatorNew/IconWorkflow.png'),
  IconWorkFlowSel: require('../Images/creatorNew/IconWorkflowSel.png'),
  CRdocumentSelect: require('../Images/creatorNew/CRdocumentSelect.png'),
  CRphotoSelect: require('../Images/creatorNew/CRphotoSelect.png'),
  SendButton: require('../Images/creatorNew/send.png'),
  BoltButton: require('../Images/creatorNew/bolt.png'),

  ErrorImg: require('../Images/creatorNew/Error.png'),
  SNLogo: require('../Images/creatorNew/SNLogo.png'),
  SNFacebook: require('../Images/creatorNew/SNFacebook.png'),
  SNFacebookSel: require('../Images/creatorNew/SNFacebookSel.png'),
  SNInbox: require('../Images/creatorNew/SNInbox.png'),
  SNInboxSel: require('../Images/creatorNew/SNInboxSel.png'),
  SNWoo: require('../Images/creatorNew/SNWoo.png'),
  SNWooSel: require('../Images/creatorNew/SNWooSel.png'),
  SNContactsAll: require('../Images/creatorNew/SNContactsAll.png'),
  SNContactsAllSel: require('../Images/creatorNew/SNContactsAllSel.png'),
  SNLabels: require('../Images/creatorNew/SNLabels.png'),
  SNLabelsSel: require('../Images/creatorNew/SNLabelsSel.png'),

  WChats: require('../Images/creatorNew/WChats.png'),
  ShopifyWhite: require('../Images/creatorNew/ShopifyWhite.png'),
  DNWhatsApp: require('../Images/creatorNew/DNWhatsApp.png'),
  DNEmail: require('../Images/creatorNew/DNEmail.png'),
  DNSMS: require('../Images/creatorNew/DNSMS.png'),
  DNCSV: require('../Images/creatorNew/DNCSV.png'),
  DNShopify: require('../Images/creatorNew/DNShopify.png'),
  DNFacebook: require('../Images/creatorNew/DNFacebook.png'),
  DNFacebookNew: require('../Images/creatorNew/DNFacebookNew.png'),
  Facebook: require('../Images/creatorNew/facebook.png'),
  DNWoo: require('../Images/creatorNew/DNWoo.png'),
  DNCampaign: require('../Images/creatorNew/DNCampaign.png'),
  DNCampaign2: require('../Images/creatorNew/DNCampaign2.png'),
  DNContacts: require('../Images/creatorNew/DNContacts.png'),
  DNContacts2: require('../Images/creatorNew/DNContacts2.png'),
  DNAgentPerm: require('../Images/creatorNew/DNAgentPerm.png'),
  DNScheduleCampaign: require('../Images/creatorNew/DNScheduleCampaign.png'),
  DNCreateWorkflow: require('../Images/creatorNew/DNCreateWorkflow.png'),
  DNSenderID: require('../Images/creatorNew/DNSenderID.png'),
  DNEmailDesign: require('../Images/creatorNew/DNEmailDesign.png'),
  DNUploadHTML: require('../Images/creatorNew/DNUploadHTML.png'),
  DNEmailTemplate: require('../Images/creatorNew/DNEmailTemplate.png'),
  WAFrame: require('../Images/creatorNew/WAFrame.png'),
  EmailFrame: require('../Images/creatorNew/EmailFrame.png'),
  SMSFrame: require('../Images/creatorNew/SMSFrame.png'),
  WAFilter: require('../Images/chatReplies/WAFilter.png'),
  SBClose: require('../Images/chatReplies/SBClose.png'),
  SBMedia: require('../Images/chatReplies/SBMedia.png'),
  SBDocument: require('../Images/chatReplies/SBDocument.png'),
  SBVideo: require('../Images/chatReplies/SBVideo.png'),
  WFIKT: require('../Images/workFlows/WFIKT.png'),
  GPTBG: require('../Images/home/gptbg.png'),
  GPTNEW: require('../Images/home/gptNew.png'),
  GPTGIF: require('../Images/home/gptGif.gif'),
  AvinashSign: require('../Images/manageProfile/AvinashSign.png'),
  LogoNewLogin: require('../Images/creatorNew/LogoNewLogin.png'),
  RecieptLogo: require('../Images/creatorNew/Reciept.png'),
  Catalog: require('../Images/creatorNew/Catalog.png'),
  CatalogProducts: require('../Images/creatorNew/CatalogProducts.png'),

  EmptyCampaign: require('../Images/creator/EmptyCampaign.png'),
  EmptyContacts: require('../Images/contactsNew/EmptyContacts.png'),
  InstagramInbox: require('../Images/creatorNew/InstaIcon.png'),
  InstagramInboxSel: require('../Images/creatorNew/instagramSelected.png'),
  InstaContinueButton: require('../Images/creatorNew/InstaContinue.png'),
  ContactsIcon: require('../Images/contactsNew/ContactsIcon.png'),
  EmptyInbox: require('../Images/chatReplies/EmptyInbox.png'),
  WAPreviewMessage: require('../Images/creatorNew/WAPreviewMessage.png'),
  EmailPreview: require('../Images/creatorNew/EmailPreview.png'),
  SMSPreview: require('../Images/creatorNew/SMSPreview.png'),
  CatalogIcon: require('../Images/creatorNew/CatalogIcon.png'),
  CatalogIconSelected: require('../Images/creatorNew/CatalogIconSelected.png'),
  Sequences: require('../Images/creatorNew/Sequences.png'),
  SendanInviteIcon: require('../Images/creatorNew/SendanInviteIcon.png'),
  InboxMessageIcon: require('../Images/creatorNew/InboxMessageIcon.png'),
  InMailIcon: require('../Images/creatorNew/InMailIcon.png'),
  ViewProfileIcon: require('../Images/creatorNew/ViewProfileIcon.png'),
  EndorseSkillsIcon: require('../Images/creatorNew/EndorseSkillsIcon.png'),
  FollowIcon: require('../Images/creatorNew/FollowIcon.png'),
  LikeaPostIcon: require('../Images/creatorNew/LikeaPostIcon.png'),
  SendEmailIcon: require('../Images/creatorNew/SendEmailIcon.png'),
  EndFlowIcon: require('../Images/creatorNew/EndFlowIcon.png'),
  IfConnectedIcon: require('../Images/creatorNew/IfConnectedIcon.png'),
  IfMessageRepliedIcon: require('../Images/linkedin/IfMessageRepliedNode.png'),

  InstaPreview: require('../Images/creatorNew/Insta.PNG'),
  MessengerInbox: require('../Images/creatorNew/MessengerIcon.png'),
  Temp1: require('../Images/templates/temp1.png'),
  Temp2: require('../Images/templates/temp2.png'),
  Temp3: require('../Images/templates/temp3.png'),
  Temp4: require('../Images/templates/temp4.png'),
  Temp5: require('../Images/templates/temp5.png'),
  Temp6: require('../Images/templates/temp6.png'),
  Temp7: require('../Images/templates/temp7.png'),
  Temp8: require('../Images/templates/temp8.png'),
  Temp9: require('../Images/templates/temp9.png'),
  Temp10: require('../Images/templates/temp10.png'),

  Instagram: require('../Images/creatorNew/Instagram.png'),

  BroadcastEnabled: require('../Images/wainbox/whatsapp.png'),
  BroadcastDisabled: require('../Images/wainbox/broadcastDisabled.png'),

  CampaignAnalyticsIcon: require('../Images/creatorNew/Iconcampaign.png'),
  CampaignAnalyticsIconSel: require('../Images/creatorNew/IconcampaignSel.png'),

  GlobalAnalyticsIcon: require('../Images/creatorNew/Iconglobal.png'),
  GlobalAnalyticsIconSel: require('../Images/creatorNew/IconGlobalSel.png'),

  KylasWorkflow: require('../Images/creatorNew/KylasWorkflow.png'),
  DiscoverIcon: require('../Images/creatorNew/IconDiscover.png'),
  DiscoverIconSel: require('../Images/creatorNew/IconDiscoverSel.png'),
  ManageIcon: require('../Images/creatorNew/IconManageIcon.png'),
  ManageIconSel: require('../Images/creatorNew/IconManageSelIcon.png'),

  OpenAiLogo: require('../Images/creatorNew/openAiLogo.png'),

  KylasLogo: require('../Images/creatorNew/KylasLogo.png'),
  FacebookLogo: require('../Images/creatorNew/facebookLogo.png'),
  AmazonSESLogo: require('../Images/creatorNew/AmazonSESLogo.png'),
  LinkedinLogo: require('../Images/creatorNew/linkedinLogo.png'),
  PremiumIcon: require('../Images/creatorNew/premiumIcon.png'),

  GPTService: require('../Images/design/gptService.png'),
  AmazonSES: require('../Images/design/amazonSES.png'),
  Facebook: require('../Images/design/facebook.png'),
  Linkedin: require('../Images/design/linkedin.png'),
  KylasService: require('../Images/design/KylasService.png'),
  ShopifyService: require('../Images/design/ShopifyService.png'),
  WooCommService: require('../Images/design/WooCommService.png'),

  Stars: require('../Images/creatorNew/stars.png'),
  InfoCircleGrey: require('../Images/creatorNew/InfoCircleGrey.png'),
  AppSumo: require('../Images/creatorNew/AppSumo.jpeg'),

  Tag: require('../Images/workFlows/Tag.png'),

  IconKeywords: require('../Images/creatorNew/IconKeywords.png'),
  IconKeywordsSel: require('../Images/creatorNew/IconKeywordsSel.png'),
  AddIcon: require('../Images/creatorNew/AddIcon.png'),

  KWCanned: require('../Images/keywords/KWCanned.png'),
  KWImage: require('../Images/keywords/KWImage.png'),
  KWVideo: require('../Images/keywords/KWVideo.png'),
  KWDocument: require('../Images/keywords/KWDocument.png'),
  KWTemplate: require('../Images/keywords/KWTemplate.png'),
  KWWorkflow: require('../Images/keywords/KWWorkflow.png'),
  KWAgent: require('../Images/keywords/KWAgent.png'),
  KWCannedSel: require('../Images/keywords/KWCannedSel.png'),
  KWImageSel: require('../Images/keywords/KWImageSel.png'),
  KWVideoSel: require('../Images/keywords/KWVideoSel.png'),
  KWDocumentSel: require('../Images/keywords/KWDocumentSel.png'),
  KWTemplateSel: require('../Images/keywords/KWTemplateSel.png'),
  KWWorkflowSel: require('../Images/keywords/KWWorkflowSel.png'),
  KWAgentSel: require('../Images/keywords/KWAgentSel.png'),
  KWDoc: require('../Images/keywords/KWDoc.png'),

  ReplyButtonIcon: require('../Images/creatorNew/replyButtonIcon.png'),
  VideoIcon: require('../Images/creatorNew/VideoIcon.png'),
  DocIcon: require('../Images/creatorNew/DocIcon.png'),
  CheerioAILogo: require('../Images/creatorNew/CheerioAILogo.png'),
  Dashboard: require('../Images/creatorNew/Dashboard.png'),
  DashboardSel: require('../Images/creatorNew/DashboardSel.png'),
  EditBlue: require('../Images/creatorNew/EditBlue.png'),
  LoginBlue: require('../Images/creatorNew/LoginBlue.png'),
  ReferEarn: require('../Images/creatorNew/ReferEarn.png'),
  QAhelp: require('../Images/creatorNew/QAhelp.png'),
  ExternalWhite: require('../Images/creatorNew/ExternalWhite.png'),
  FlagIndia: require('../Images/creatorNew/FlagIndia.png'),
  FlagUSA: require('../Images/creatorNew/FlagUSA.png'),
  Success: require('../Images/creatorNew/Success.png'),
  Stop: require('../Images/creatorNew/IconStop.png'),
  BarChart: require('../Images/creatorNew/IconBarChart.png'),

  ReviewImage: require('../Images/creatorNew/ReviewImage.png'),

  WFForm: require('../Images/workFlows/WFForm.png'),
  Label: require('../Images/workFlows/label.png'),
  WFTime2: require('../Images/workFlows/WFTime2.png'),
  WFTime2Sel: require('../Images/workFlows/WFTime2Sel.png'),
  CheckBoxIcon: require('../Images/workFlows/CheckBoxIcon.png'),
  CheckBoxIconSel: require('../Images/workFlows/CheckBoxIconSel.png'),
  Maintenance: require('../Images/creatorNew/Maintenance.png'),
  ShopifyDisplayImage: require('../Images/shopifystore/DisplayImage.png'),
  WoocommerceDisplayImage: require('../Images/woocommerce/woo.png'),
  AssuredBlack: require('../Images/design/assuredBlack.png'),
  Exclusive: require('../Images/design/exclusive.png'),
  EGuideline01: require('../Images/email/EGuideline01.png'),
  EGuideline02: require('../Images/email/EGuideline02.png'),
  EGuideline03: require('../Images/email/EGuideline03.png'),
  EGuideline04: require('../Images/email/EGuideline04.png'),
  EGuideline05: require('../Images/email/EGuideline05.png'),
  WAInboxDeskPeople: require('../Images/wainbox/desk-people.png'),
  PlusIcon: require('../Images/wainbox/plus-icon.png'),
  PurpleInfoIcon: require('../Images/wainbox/purple-info-icon.png'),
  PurpleCloseIcon: require('../Images/wainbox/purple-close-icon.png'),
  AnnouncementIcon: require('../Images/wainbox/announce-icon.png'),
  DownloadIcon: require('../Images/wainbox/download-icon.png'),
  EditIcon: require('../Images/wainbox/edit-icon.png'),
  GalleryIcon: require('../Images/wainbox/gallery-icon.png'),
  TrashIcon: require('../Images/wainbox/trash-icon.png'),
  WASlider: require('../Images/wainbox/WASlider.png'),
  TrashSelIcon: require('../Images/wainbox/trash-sel-icon.png'),
  LikeSelIcon: require('../Images/wainbox/like-sel-icon.png'),
  TickSelIcon: require('../Images/wainbox/tick-sel-icon.png'),
  NOINTEGRATIONLOGO: require('../Images/shopifystore/NoIntegrationLogo.png'),
  NOTIFICATIONLOGO: require('../Images/creatorNew/NotificationLogo.png'),
  NOTIFICATIONALERT: require('../Images/creatorNew/NotiAlert.png'),
  NOTIFICATIONUNREAD: require('../Images/creatorNew/NotificationUnread.png'),
  WEBHOOK_TRIGGER: require('../Images/workFlows/WebhookTrigger.png'),
  PIXEL_EVENT: require('../Images/workFlows/PixelEvent.png'),
  CONTACT_ICON: require('../Images/creatorNew/IconContact.png'),
  BROADCAST_ICON: require('../Images/workFlows/BroadCastImage.png'),
  JSON_ICON : require('../Images/workFlows/Json.png'),

  EXPORT_ICON :require('../Images/workFlows/Export.png'),
  IMPORT_ICON :require('../Images/workFlows/Import.png'),
  SYNC_ICON :require('../Images/workFlows/SyncIcon.png'),
  NEWICON: require('../Images/workFlows/New.png'),

  UPDATE_BROADCAST: require('../Images/workFlows/UpdateBroadcast.png'),

  WIDoc: require('../Images/wainbox/WIDoc.png'),
  WIImage: require('../Images/wainbox/WIImage.png'),
  WIVideo: require('../Images/wainbox/WIVideo.png'),
  WITick: require('../Images/wainbox/WITick.png'),

  DISTICON: require('../Images/wainbox/distIcon.png'),
  ADDICONBLACK: require('../Images/workFlows/AddIconBlack.png'),
  WOOIMG: require('../Images/creatorNew/WooImg.png'),
  LOGONEW: require('../Images/creatorNew/LogoNew.png'),
  CROSS_BLACK: require('../Images/manageProfile/CrossBlack.png'),
  EditWhite: require('../Images/manageProfile/EditWhite.png'),

  Danger: require('../Images/creatorNew/Danger.png'),
  SuccessDone: require('../Images/creatorNew/SuccessDone.png'),
  AddContactImage: require('../Images/creatorNew/AddContactImage.png'),
  InfoCirclePurple: require('../Images/creatorNew/InfoCirclePurple.png'),
  CrossPurple: require('../Images/creatorNew/CrossPurple.png'),

  NoDataForms: require('../Images/embeddableForms/NoDataForms.png'),
  ImagePlaceholder: require('../Images/embeddableForms/ImagePlaceholder.png'),
  BoltYellow: require('../Images/embeddableForms/BoltYellow.png'),
  FFEmail: require('../Images/embeddableForms/FFEmail.png'),
  FFPhone: require('../Images/embeddableForms/FFPhone.png'),
  FFText: require('../Images/embeddableForms/FFText.png'),
  EFSuccessGIF: require('../Images/embeddableForms/EFSuccessGIF.gif'),

  LinkUnsel: require('../Images/embeddableForms/LinkUnsel.png'),
  LinkSel: require('../Images/embeddableForms/LinkSel.png'),
  CodeSel: require('../Images/embeddableForms/CodeSel.png'),
  CodeUnsel: require('../Images/embeddableForms/CodeUnsel.png'),
  PopupUnsel: require('../Images/embeddableForms/PopupUnsel.png'),
  PopupSel: require('../Images/embeddableForms/PopupSel.png'),
  FILEEXPORT: require('../Images/creatorNew/file-export.png'),
  IMPORTICON: require('../Images/creatorNew/file-import.png'),
  PremiumDiamond: require('../Images/creatorNew/PremiumDiamond.png'),

  SNExcelSel: require('../Images/excelUpload/SNExcelSel.png'),
  SNExcelUnsel: require('../Images/excelUpload/SNExcelUnsel.png'),
  EUViewBlue: require('../Images/excelUpload/EUViewBlue.png'),
  EUDeleteBlue: require('../Images/excelUpload/EUDeleteBlue.png'),
  EUAnnounceBlue: require('../Images/excelUpload/EUAnnounceBlue.png'),
  EUDownloadBlue: require('../Images/excelUpload/EUDownloadBlue.png'),
  Unsub01: require('../Images/email/Unsub01.png'),
  Unsub02: require('../Images/email/Unsub02.png'),
  Unsub03: require('../Images/email/Unsub03.png'),
  Unsub04: require('../Images/email/Unsub04.png'),
  Unsub05: require('../Images/email/Unsub05.png'),

  NoVendorsPlaceholder: require('../Images/vendorsList/NoVendorsPlaceholder.png'),
  HSCard1: require('../Images/creatorNew/HSCard1.png'),
  HSCard2: require('../Images/creatorNew/HSCard2.png'),
  HSCard3: require('../Images/creatorNew/HSCard3.png'),
  HSCard4: require('../Images/creatorNew/HSCard4.png'),
  SARevenue: require('../Images/shopifystore/SARevenue.png'),
  SAMessaging: require('../Images/shopifystore/SAMessaging.png'),
  SAROI: require('../Images/shopifystore/SAROI.png'),
  SACartAbandoned: require('../Images/shopifystore/SACartAbandoned.png'),
  SACartRecovered: require('../Images/shopifystore/SACartRecovered.png'),
  SACOD: require('../Images/shopifystore/SACOD.png'),


  LinkedinIcon: require('../Images/creatorNew/linkedin-1.png'),
  LinkedinIconSel: require('../Images/creatorNew/linkedin.png'),
  SendAnInvite: require('../Images/linkedin/SendAnInvite.png'),
  TimeDelay: require('../Images/linkedin/TimeDelayNode.png'),
  TimeDelaySel: require('../Images/linkedin/TimeDelay.png'),
  InboxMessage: require('../Images/linkedin/InboxMessage.png'),
  InMailMessage: require('../Images/linkedin/InMailMessage.png'),
  CheckMail: require('../Images/linkedin/CheckMail.png'),
  InviteEmail: require('../Images/linkedin/InviteEmail.png'),
  ViewProfile: require('../Images/linkedin/ViewProfile.png'),
  EndorseSkills: require('../Images/linkedin/EndorseSkills.png'),
  Follow: require('../Images/linkedin/Follow.png'),
  LikeAPost: require('../Images/linkedin/LikeAPost.png'),
  EndFlow: require('../Images/linkedin/EndFlow.png'),

  VideoPreview: require('../Images/creatorNew/Video.png'),
  ImagePreview: require('../Images/creatorNew/ImagePreview.png'),
  PdfReview:require('../Images/creatorNew/Document.png'),

  SNSequences: require('../Images/sequences/SNSequences.png'),
  SequnecesIcon: require('../Images/sequences/Sequences.png'),
  SNSequencesSel: require('../Images/sequences/SNSequencesSel.png'),
  SequencePlaceholder: require('../Images/sequences/SequencePlaceholder.png'),
  SeqWriteEmail: require('../Images/sequences/SeqWriteEmail.png'),
  SeqSendEmail: require('../Images/sequences/SeqSendEmail.png'),
  SeqTimeDelay: require('../Images/sequences/SeqTimeDelay.png'),
  SeqWENode: require('../Images/sequences/SeqWENode.png'),
  SeqThreeDots: require('../Images/sequences/SeqThreeDots.png'),
  SeqTDNode: require('../Images/sequences/SeqTDNode.png'),
  FilledFilter: require('../Images/header/filterFilled.png'),
  CalanderIcon : require('../Images/creatorNew/Calander.png'),
  ResetFilterIcon :require('../Images/creatorNew/ResetFilter.png'),
  AngleDown : require('../Images/creatorNew/AngleDown.png'),
  SeqSidebarEmail: require('../Images/sequences/SeqSidebarEmail.png'),
  SeqSidebarEnd: require('../Images/sequences/SeqSidebarEnd.png'),
  SeqSidebarCondition: require('../Images/sequences/SeqSidebarCondition.png'),
  SeqSidebarTime: require('../Images/sequences/SeqSidebarTime.png'),
};
