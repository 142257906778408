/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import colors from '../Utils/colors';

interface Option {
    optionName: string;
    color: string;
    position: string;
    id?: string;
}

interface StatusDropdownProps {
    name: string;
    id?: string;
    options: Option[];
    initialValue: string;
    isSelectable?: boolean;
    setValue?: (value: string) => void;
    handleUpdate?: (attribute: string, value: string, id?: string) => void;
    isIdRequired?: boolean;
    isBulk?: boolean;
    isAgent?: boolean;
    setAgentId?: (value: string) => void;
}

const CheerioDropDown: React.FC<StatusDropdownProps> = ({
    name,
    id,
    options,
    initialValue,
    isSelectable = true,
    handleUpdate = () => { },
    setValue = () => "",
    isIdRequired = false,
    isBulk = false,
    isAgent = false,
    setAgentId = () => "",
}) => {
    const [selectedValue, setSelectedValue] = useState<string>(initialValue);
    const updatedOptions = isAgent
        ? [{ optionName: "Unassigned", color: "#FEF3B1", position: "Unassigned" }, ...options]
        : options;

    useEffect(() => {
        if (isAgent && (!initialValue || initialValue.trim() === "")) {
            setSelectedValue("Unassigned");
        } else {
            setSelectedValue(initialValue);
        }
        const initialOption = options?.find((opt) => opt?.optionName === initialValue);

        if (initialOption) {
            document.documentElement.style.setProperty('--dropdown-bg', initialOption.color);
        }
    }, [initialValue, options]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        if (isSelectable) {
            const newValue = event.target.value as string;
            setSelectedValue(newValue);
            const selectedOption = options?.find((opt) => opt?.optionName === newValue);
            let optionId = options?.find((opt) => opt?.optionName === newValue)?.id ?? '';
            if (selectedOption) {
                document.documentElement.style.setProperty('--dropdown-bg', selectedOption?.color);
            }
            setValue(newValue);
            if (isAgent && newValue !== "Unassigned") {
                setAgentId(optionId);
            } else {
                optionId = 'unassigned';
            }
            isIdRequired ? handleUpdate(name, newValue, id) : isAgent ? handleUpdate(name, optionId) : handleUpdate(name, newValue);
        }
    };

    const selectedOption = options?.find((opt) => opt?.optionName === selectedValue);

    return (
        <>
            {(!initialValue || (initialValue !== "--" && initialValue !== "NA"))
                ? <FormControl
                    sx={{
                        minWidth: isBulk ? "100%" : 180,
                        borderRadius: '16px',
                        backgroundColor: isAgent ? "#FEF3B1" : selectedOption?.color ? selectedOption?.color : 'white',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '70px',
                            '& fieldset': {
                                border: 'none',
                            },
                            '&:hover fieldset': {
                                border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                // disabled={!isSelectable}
                >
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            disablePortal: isBulk && true,
                        }}
                        defaultValue='Unassigned'
                        value={isAgent && (!selectedValue || selectedValue.trim() === "") ? "Unassigned" : selectedValue}
                        onChange={handleChange}
                        displayEmpty
                        sx={{
                            height: '30px',
                            color: 'black',
                            borderRadius: '16px',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: isAgent ? "#FEF3B1" : selectedOption?.color ? selectedOption?.color : 'white',
                            '&:hover': {
                                border: 'none',
                                outline: 'none',
                            },
                        }}
                    >
                        {updatedOptions?.map((option) => (
                            <MenuItem
                                key={option.optionName}
                                value={option.optionName}
                                sx={{
                                    height: '30px',
                                    color: 'black',
                                    borderRadius: '70px',
                                    margin: '10px',
                                    backgroundColor: option.color,
                                    '&.Mui-selected': {
                                        backgroundColor: `${option.color} !important`,
                                        color: isAgent ? "black" : 'white',
                                    },
                                    '&:hover': {
                                        backgroundColor: `${option.color}AA`,
                                        color: isAgent ? colors.greys01 : 'white',
                                        transition: '0.2s',
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: isAgent ? 'start' : 'center',
                                    textAlign: 'center',
                                }}
                            >

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: isAgent ? 'start' : 'center', gap: 20 }}>
                                    {isAgent && <div
                                        style={{
                                            height: 18,
                                            width: 18,
                                            borderRadius: '50%',
                                            backgroundColor: '#000000',
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <p style={{ fontSize: '12px', fontWeight: '600', margin: 0 }}>
                                            {option?.optionName.substring(0, 1).toUpperCase()}
                                        </p>
                                    </div>}
                                    <p
                                        className="statusText"
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: isAgent ? "" : '600',
                                            textAlign: 'center',
                                            margin: 0,
                                        }}
                                    >
                                        {option.optionName}
                                    </p>
                                </div>
                            </MenuItem>
                        ))}

                    </Select>
                </FormControl> : <p>{initialValue}</p>}
        </>
    );
};

export default CheerioDropDown;