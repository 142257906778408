import React, { useEffect, useState } from 'react';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getLinkedinConfiguration, editLinkedinConfiguration } from '../../../Services';
import { Modal, ModalBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import colors from '../../../Utils/colors';
import { saveLinkedinSetup } from '../../../Services';

export const Linkedin = ({ setSelectedMenuItem }) => {
    const [apiKey, setApiKey] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [agentNumber, setAgentNumber] = useState('0');
    const [userExist, setUserExist] = useState(false);
    const authtoken = useSelector((state) => state.main.auth_token);
    const [isLoading, setIsLoading] = useState(false);
    const [isCredentials, setIsCredentials] = useState(false);
    const [isEditMode, setIsEditMode] = useState(true);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [credentials, setCredentials] = useState({
        linkedinEmail: '',
        linkedinPassword: '',
    });
    const handleChange = (e) => {
        const { id, value } = e.target;
        setCredentials(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleTermsChange = (e) => {
        setAgreedToTerms(e.target.checked);
    };


    const handleSubmitOrEdit = () => {
        if (isEditMode) {
            let token = authtoken;
            const data = {
                email: credentials.linkedinEmail,
                password: credentials.linkedinPassword
            };

            // If credentials already exist, use editLinkedinConfiguration
            if (isCredentials) {
                editLinkedinConfiguration(data, token).then((res) => {
                    if (res?.flag) {
                        toast.success(res?.message);
                        setIsEditMode(false);
                        setCredentials(prev => ({
                            linkedinEmail: '********',
                            linkedinPassword: '********',
                        }));
                    } else {
                        toast.error(res?.message);
                    }
                });
            } else {
                // For new credentials, use saveLinkedinSetup
                saveLinkedinSetup(token, data).then((res) => {
                    if (res?.flag) {
                        toast.success(res?.message);
                        setIsEditMode(false);
                        setIsCredentials(true);
                        setCredentials(prev => ({
                            linkedinEmail: '********',
                            linkedinPassword: '********',
                        }));
                    } else {
                        toast.error(res?.message);
                    }
                });
            }
        } else {
            // Enter edit mode
            setIsEditMode(true);
            setCredentials(prev => ({
                linkedinEmail: '',
                linkedinPassword: '',
            }));
        }
    };

    useEffect(() => {
        let token = authtoken;
        getLinkedinConfiguration(token).then((res) => {
            if (res?.flag) {
                if (res?.data) {
                    // Data exists
                    setIsCredentials(true);
                    setCredentials({
                        linkedinEmail: res?.data?.email,
                        linkedinPassword: '********',
                    });
                    setIsEditMode(false);
                } else {
                    // No data, but not an error
                    setIsCredentials(false);
                    setIsEditMode(true);
                    setCredentials({
                        linkedinEmail: '',
                        linkedinPassword: '',
                    });
                }
            } else if (res?.message && res?.message !== "No linkedin user exists") {
                // Actual error occurred
                toast.error(res.message);
                setIsCredentials(false);
                setIsEditMode(true);
            } else {
                // No data found, not treated as an error
                setIsCredentials(false);
                setIsEditMode(true);
                setCredentials({
                    linkedinEmail: '',
                    linkedinPassword: '',
                });
            }
        }).catch(error => {
            console.error("Error fetching SES configuration:", error);
            toast.error("An unexpected error occurred. Please try again.");
            setIsCredentials(false);
            setIsEditMode(true);
        });
    }, [authtoken]);

    const formStyle = {
        width: '100%',
        padding: '20px',
        borderRadius: '8px',
        background: '#FFFFFF',
    };

    const formGroupStyle = {
        paddingTop: 10,
        marginBottom: '15px',
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
        fontSize: '14px',
    };

    const inputStyle = {
        width: 'calc(100% - 16px)', // Full width minus padding
        maxWidth: '400px', // Maximum width
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
        fontSize: '14px',
    };

    const checkboxLabelStyle = {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '14px',
        fontSize: '16px',
    };

    const checkboxStyle = {
        width: 16,
        height: 16,
        cursor: 'pointer',
        marginRight: '10px',
    };
    return (
        <div style={{ width: '100%', padding: '2%' }}>
            {
                <>
                    <div className="d-flex" style={{ alignItems: 'center', paddingBottom: '2%' }}>
                        <img height={47} width={47} src={images.LinkedinLogo} style={{ marginRight: '1%' }}></img>
                        <div>
                            <p style={{ fontWeight: '700', fontSize: '20px' }}>LinkedIn</p>
                            <p style={{ color: '#666666', fontSize: '16px' }}>
                                Connect and automate follow-ups and connections with ease
                            </p>
                        </div>
                    </div>

                    <form onSubmit={(e) => e.preventDefault()} style={formStyle}>
                        <div>
                            <p style={{ fontWeight: '700', fontSize: '16px' }}>Login to LinkedIn</p>
                            <p style={{ color: '#666666', fontSize: '16px' }}>Please log in to your LinkedIn account using your <span style={{ fontWeight: 700 }}>registered email address and password</span>. Rest assured, <span style={{ fontWeight: 700 }}>your LinkedIn credentials are securely encrypted</span> and are necessary for executing automated actions in the background.<span style={{ fontWeight: 700 }}> We do not have direct access or control over your LinkedIn account</span>, and you have the option to disconnect this connection at any time.</p>
                        </div>
                        <div style={formGroupStyle}>
                            <label htmlFor="linkedinEmail" style={labelStyle}>Linkedin email*</label>
                            <input
                                type="email"
                                id="linkedinEmail"
                                placeholder="john@sample.in"
                                style={inputStyle}
                                value={credentials.linkedinEmail}
                                onChange={handleChange}
                                disabled={!isEditMode}
                            />
                        </div>
                        <div style={formGroupStyle}>
                            <label htmlFor="linkedinPassword" style={labelStyle}>Password</label>
                            <input
                                type="password"
                                id="linkedinPassword"
                                placeholder="********"
                                style={inputStyle}
                                value={credentials.linkedinPassword}
                                onChange={handleChange}
                                disabled={!isEditMode}
                            />
                        </div>

                        {!isCredentials && (
                            <div style={checkboxLabelStyle}>
                                <input
                                    type="checkbox"
                                    id="termsCheckbox"
                                    checked={agreedToTerms}
                                    onChange={handleTermsChange}
                                    style={checkboxStyle}
                                />
                                <label htmlFor="termsCheckbox">
                                    I agree to the <span style={{ color: colors.primary03 }}>terms of service</span>
                                </label>
                            </div>
                        )}
                        <CheerioButton
                            borderStyle={{
                                borderColor: colors.primary03,
                                width: '15%',
                                marginInlineEnd: 16,
                                opacity: (agreedToTerms || isCredentials) ? 1 : 0.5,
                            }}
                            textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white }}
                            btnText={isCredentials && !isEditMode ? 'Edit' : 'Login to Linkedin'}
                            backColor={colors.primary03}
                            disabled={!(agreedToTerms || isCredentials)}
                            onclick={handleSubmitOrEdit}
                        />
                    </form>
                </>
            }
        </div>
    );
};
