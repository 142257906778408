import React from 'react'
import colors from '../../../Utils/colors'
import images from '../../../Utils/images'

const ToastModal = ({ status, refreshStatusAction, openVSModalAction }) => {
  return (
    <>
    <div className='Column align-items-start' style={{
        position: 'absolute',
        top:20,
        right:24,
        width:260,
        paddingBlock:16,
        paddingInline:16,
        backgroundColor:colors.white01,
        border:`1px solid ${colors.borderwhite}`,
        borderRadius:16,
        zIndex:99, 
        boxShadow:'0px 2px 4px 0px rgba(0, 0, 0, 0.24)',
    }}>
        {status !== 'Complete' ? (
            <>
            <div className='Row w-100 justify-content-start'>
                <img 
                    src={images.CirclePending} 
                    style={{height:16,width:16,objectFit:'contain',marginInlineEnd:6}}
                />
                <p style={{fontWeight:600}}>
                    {'Verifying... '}
                </p>
            </div>
            <p style={{color:colors.primary03,textDecorationLine:'underline',cursor:'pointer',fontSize:14}} onClick={()=>{
                refreshStatusAction();
            }}>
                {'Check Status'}
            </p>
            </>
        ): status === 'Complete' && (
            <>
            <div className='Row w-100 justify-content-start'>
                <img 
                    src={images.CircleCheckGreen} 
                    style={{height:16,width:16,objectFit:'contain',marginInlineEnd:6}}
                />
                <p style={{fontWeight:600}}>
                    {'Verification Completed'}
                </p>
            </div>
            <p style={{color:colors.primary03,textDecorationLine:'underline',cursor:'pointer',fontSize:14}} onClick={()=>{
                openVSModalAction();
            }}>
                {'Click for more details...'}
            </p>
            </>
        )}
    </div>
    </>
  )
}

export default ToastModal